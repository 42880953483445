<div class="loginInner form-signin">
  <div class="wrapper card">
    <div class="wrapper-inner">
      <div id="formContent">
        <!-- Login Form -->
        <div class="row">
          <div class="col-12">
            <div class="text-left">
              <h2 class="login-title">Welcome to</h2>
              <div class="logo"></div>
            </div>
            <form #loginForm="ngForm" (ngSubmit)="login()">
              <h3 class="signin-title">Sign in</h3>
              <div class="form-group fadeIn second">
                <div *ngIf="invalidLogin" class="alert alert-danger" i18n="@@UsernameOrPasswordInvalid">Username or password invalid</div>
                <label for="username" class="sr-only" i18n="@@Username">Username</label>
                <input type="email" id="username" name="username" #userName="ngModel" [(ngModel)]="user.email"
                  class="form-control" placeholder="Username" i18n-placeholder="@@Username" required autofocus>
                <div *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="alert alert-danger">
                  <div *ngIf="userName.hasError('required')" i18n="@@TypeYourUsername">Type your username</div>
                </div>
              </div>
              <div class="form-group fadeIn third">
                <label for="password" class="sr-only" i18n="@@Password">Password</label>
                <input type="password" id="password" name="password" #password="ngModel" [(ngModel)]="user.password"
                  class="form-control" placeholder="Password" i18n-placeholder="@@Password" required>
                <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
                  <div *ngIf="password.hasError('required')" i18n="@@TypeYourPassword">Type your password</div>
                </div>
                <!-- Remind Passowrd -->
                <div class="forgottenPasswordContainer">
                  <a class="underlineHover" href="#" i18n="Text for a forgotten password on the login screen@@ForgottenPassword">Forgotten password ?</a>
                </div>
              </div>
              <div class="form-group fadeIn fourth">
                <button type="submit" class="btn btn-light btn-lg btn-block " [disabled]="loginForm.invalid" i18n="@@LogIn">Log in</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
