import { FormationPresentation, FormationStatistics } from '../models/formation/formation.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { Formation } from '@models/formation/formation.model';
import { ChangeFormationChapterLecture } from '@models/worker/change-formation-chapter-lecture.model';
import { FormationChapter } from '@models/formation/formation-chapter.model';

@Injectable({
  providedIn: 'root'
})
export class FormationService {
  private entityUrl = 'workers/';

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public getWorkerFormations(workerId: string): Observable<Formation[]> {
    return this.sintenoHttpService.get<Formation[]>(
      `${this.entityUrl}${workerId}/formations`
    );
  }

  public getWorkerFormationsStatistics(workerId: string): Observable<FormationStatistics[]> {
    return this.sintenoHttpService.get<FormationStatistics[]>(
      `${this.entityUrl}${workerId}/formations/statistics`
    );
  }

  public getWorkerFormationPresentation(
    workerId: string,
    formationId: string,
    languageCode: string
  ): Observable<FormationPresentation> {
    return this.sintenoHttpService.get<FormationPresentation>(
      `${this.entityUrl}${workerId}/formations/${formationId}/${languageCode}/presentation`
    );
  }


  public addWorkerFormation(workerId: string, cubeId: string): Observable<any> {
    return this.sintenoHttpService.post(
      `${this.entityUrl}${workerId}/formations/${cubeId}`,
      {}
    );
  }

  public changeFormationChapterLecture(workerId: string, formationId: string, previousChapterId: string,
    currentChapterId: string, isFinished: boolean): Observable<FormationChapter> {
    const url = `${this.entityUrl
      }${workerId}/formations/${formationId}/changelecture`;
    const model: ChangeFormationChapterLecture = {
      previousChapterId: previousChapterId,
      currentChapterId: currentChapterId, isFinished: isFinished
    };
    return this.sintenoHttpService.put<FormationChapter>(url, model);
  }

  public deleteWorkerFormation(workerId: string, formationId: string): Observable<any> {
    return this.sintenoHttpService.delete(`${this.entityUrl}${workerId}/formations/${formationId}`);
  }

  public setFormationLanguage(workerId: string, formationId: string, languageId: string): Observable<any> {
    const url = `${this.entityUrl
      }${workerId}/formations/${formationId}/language/${languageId}`;
    return this.sintenoHttpService.put(url, null);
  }

  public getFormationStatistics(workerId: string, formationId: string): Observable<FormationStatistics> {
    return this.sintenoHttpService.get<FormationStatistics>(
      `${this.entityUrl}${workerId}/formations/${formationId}/statistics`
    );
  }
}
