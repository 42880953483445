import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { Constants } from '@shared/utils/constants-file';
import { isDate } from '@shared/utils/utils';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html'
})
export class DatePickerComponent implements OnInit {
  @Input() public date: Date;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Input() public isDisabled: boolean;
  @Output() public dateChange = new EventEmitter<Date>();
  public ngbDate: NgbDate;
  public ngbMinDate: NgbDate;
  public ngbMaxDate: NgbDate;

  constructor(private toastr: ToastrService) {}
  ngOnInit(): void {
    if (!isDate(this.date)) {
      this.date = new Date(this.date);
    }
    if (this.minDate) {
      if (!isDate(this.minDate)) {
        this.minDate = new Date(this.minDate);
      }
      this.ngbMinDate = this.computeNgbDate(this.minDate);
    }
    if (this.maxDate) {
      if (!isDate(this.maxDate)) {
        this.maxDate = new Date(this.maxDate);
      }
      this.ngbMaxDate = this.computeNgbDate(this.maxDate);
    }

    this.ngbDate = this.computeNgbDate(this.date);
  }

  public changeDate(date: NgbDate): void {
    if (date && date.year && date.month && date.day) {
      const newDate = new Date(`${date.year}-${date.month}-${date.day}`);
      if (this.minDate && newDate < this.minDate) {
        this.toastr.error(
          `La date doit être postérieure au
          ${formatDate(
            this.minDate,
            Constants.DATE_FORMAT,
            Constants.LOCALE_STRING
          )} `,
          'Format de date invalide'
        );
        return;
      }
      if (this.maxDate && newDate > this.maxDate) {
        this.toastr.error(
          `La date doit être antérieure au
          ${formatDate(
            this.maxDate,
            Constants.DATE_FORMAT,
            Constants.LOCALE_STRING
          )} `,
          'Format de date invalide'
        );
        return;
      }
      this.date = newDate;
      this.dateChange.emit(newDate);
    } else {
      this.toastr.error(
        'Veuillez utiliser le format dd/mm/yyyy (ex: 31/01/2019)',
        'Format de date invalide'
      );
    }
  }

  private computeNgbDate(date: Date) {
    return new NgbDate(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
  }
}
