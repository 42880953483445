<div class="card criteriaContainer">
  <div class="card-header">
    <div class="row">
      <div class="col-12">
        <h2>
          <i class="fa fa-book"></i> {{behaviour.name}}
        </h2>
      </div>
    </div>
  </div>
  <div class="card-body">
    <table class="table">
      <tbody>
        <tr class="d-flex" [ngClass]="{'criteriaOneSelected':  score == 1}">
          <th class="col-1 number oneContainer" scope="row" ngbTooltip="La règle n'est pas du tout appliquée. Transgression de la règle.
          Risques sécurité, qualité,... potentiellement graves pour la sécurité, la qualité, le client,etc.">1</th>
          <td class="col-11" scope="col">
            <span>{{ behaviour.criteria.criteria1 }}</span>
          </td>
        </tr>
        <tr class="d-flex" [ngClass]="{'criteriaTwoSelected':  score == 2}">
          <th class="col-1 number twoContainer" scope="row" ngbTooltip="Une partie de la règle est transgressée.
          Un  élément présente un risque sécurité, qualité, client … potentiellement important">2</th>
          <td class="col-11" scope="col">
            <span>{{ behaviour.criteria.criteria2 }}</span>
          </td>
        </tr>
        <tr class="d-flex" [ngClass]="{'criteriaThreeSelected':  score == 3}">
          <th class="col-1 number threeContainer" scope="row"
            ngbTooltip="globalement application de la règle mais plusieurs  éléments ne sont pas respectés sans que cela ne génère un risque grave">
            3</th>
          <td class="col-11" scope="col">
            <span>{{ behaviour.criteria.criteria3 }}</span>
          </td>
        </tr>
        <tr class="d-flex" [ngClass]="{'criteriaFourSelected':  score == 4}">
          <th class="col-1 number fourContainer" scope="row" ngbTooltip="globalement application de la règle mais un élément n'est pas respecté sans que cela ne génère un risque grave
          ">4</th>
          <td class="col-11" scope="col">
            <span>{{ behaviour.criteria.criteria4 }}</span>
          </td>
        </tr>
        <tr class="d-flex" [ngClass]="{'criteriaFiveSelected':  score == 5}">
          <th class="col-1 number fiveContainer" scope="row" ngbTooltip="application correcte de la règle">5</th>
          <td class="col-11" scope="col">
            <span>{{ behaviour.criteria.criteria5 }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
