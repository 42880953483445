export class GetCurrentSession {
    public static readonly type = '[Session] Get Current Session';
}

export class GetCurrentSessionAndGoToHome {
  public static readonly type = '[Session] Get Current Session And go to home';
}

export class AcceptTermsAction {
  public static readonly type = '[Session] Accept terms';
  public constructor() {}
}

export class GetAreTermsAcceptedAction {
  public static readonly type = '[Session] Are terms accepted';
}

