import { Language } from '@shared/store/language/language-view-model';

export class TranslatedBehaviour {
  public id: string;
  public name: string;
  public content: string;
  public criteria1: string;
  public criteria2: string;
  public criteria3: string;
  public criteria4: string;
  public criteria5: string;
  public language: Language;
}
