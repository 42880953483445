import { environment } from '@environments/environment';

/**
 * Promise returning the content of config.json in JSON format.
 * If failing it will return the environment.
 *
 * It's better than using fetch() since we can handle HTTP error codes.
 */
export const configLoader = new Promise<any>((resolve, reject) => {
  const xmlhttp = new XMLHttpRequest();
  const method = 'GET';
  const fileUrl = environment.configFilePath;
  xmlhttp.open(method, fileUrl, true);
  xmlhttp.onload = () => {
    if (xmlhttp.status === 200) {
      resolve(JSON.parse(xmlhttp.responseText));
    } else {
      resolve(environment);
    }
  };
  xmlhttp.send();
});
