import { State, Action, StateContext, Store, Selector } from '@ngxs/store';
import { AddPublishingNoteAction, UpdatePublishingNoteAction, GetBehaviourPublishingNotesAction, GetChapterPublishingNotesAction,
  GetPublishingNoteTranslationAction, GetPublishingNoteAction, UpdatePublishingNoteTranslationAction, ResetPublishingNotes, GetAllPublishingNotesAction } from './publishing-note.action';
import * as _ from 'lodash';
import { PublishingNoteService } from '@shared/services/publishing-note.service';
import { ToastrService } from 'ngx-toastr';
import { TableOfContentService } from '@shared/services/table-of-content.service';
import { PublishingNote } from '@models/publishing-note/publishing-note.model';
import { map } from 'rxjs/operators';
import { TranslatedPublishingNote } from '@models/publishing-note/translated-publishing-note.model';
import { TranslationService } from '@shared/services/translation.service';
import { PublishingNoteDetailed } from '@models/publishing-note/publishing-note-detailed.model';


export interface PublishingNotesStateModel {
  publishingNotes: PublishingNote[];
  openedPublishingNoteTranslation: TranslatedPublishingNote;
  publishingNote: PublishingNote;
}

@State<PublishingNotesStateModel>({
  name: 'publishingnote',
  defaults: {
  publishingNotes: null,
  openedPublishingNoteTranslation: null,
  publishingNote: null
}
})
export class PublishingNoteState {
  public constructor(
    private publishingNoteService: PublishingNoteService,
    private tableOfContentService: TableOfContentService,
    private store: Store,
    private toastr: ToastrService,
    private translationService: TranslationService
  ) { }

  @Selector()
  public static publishingNotes(stateModel: PublishingNotesStateModel) {
    return stateModel.publishingNotes;
  }


  @Selector()
  public static openedPublishingNoteTranslation(stateModel: PublishingNotesStateModel) {
    return stateModel.openedPublishingNoteTranslation;
  }

  @Selector()
  public static publishingNote(stateModel: PublishingNotesStateModel) {
    return stateModel.publishingNote;
  }

  @Action(AddPublishingNoteAction)
  public addPublishingNoteaddCubeAction(
    ctx: StateContext<PublishingNotesStateModel>,
    action: AddPublishingNoteAction
  ): void {
    this.publishingNoteService
      .addPublishingNote(action.publishingNote)
      .pipe(map(publishingNote => {
        let notes: PublishingNote[] = _.cloneDeep(ctx.getState()).publishingNotes;
        notes.push(publishingNote);
        notes = this.sortByDateDesc(notes);
        ctx.patchState({ publishingNotes: notes });
      }))
      .subscribe(() => this.toastr.success('Note de publication envoyée!'));
  }

  @Action(UpdatePublishingNoteAction)
  public updatePublishingNoteaddCubeAction(
    ctx: StateContext<PublishingNotesStateModel>,
    action: AddPublishingNoteAction
  ): void {
    this.publishingNoteService
      .updatePublishingNote(action.publishingNote)
      .pipe(map(publishingNote => {
        let notes: PublishingNote[] = _.cloneDeep(ctx.getState()).publishingNotes;
        notes = notes.filter(x => x.id !== publishingNote.id);
        notes.push(publishingNote);
        notes = this.sortByDateDesc(notes);
        ctx.patchState({ publishingNotes: notes });
      }))
      .subscribe(() => this.toastr.success('Note de publication modifiée!'));
  }

  @Action(GetChapterPublishingNotesAction)
  public getChapterPublishingNotesAction(
    ctx: StateContext<PublishingNotesStateModel>,
    action: GetChapterPublishingNotesAction
  ): void {
    this.tableOfContentService.getChapterPublishingNotes(action.cubeId, action.chapterId)
      .subscribe(publishingNotes => {
        ctx.patchState({ publishingNotes: publishingNotes });
      });
  }

  @Action(GetBehaviourPublishingNotesAction)
  public getBehaviourPublishingNotesAction(
    ctx: StateContext<PublishingNotesStateModel>,
    action: GetBehaviourPublishingNotesAction
  ): void {
    this.tableOfContentService.getBehaviourPublishingNotes(action.cubeId, action.chapterId, action.behaviourId)
      .subscribe(publishingNotes => {
        ctx.patchState({ publishingNotes: publishingNotes });
      });
  }

  @Action(GetAllPublishingNotesAction)
  public getAllPublishingNotesAction(
    ctx: StateContext<PublishingNotesStateModel>,
    action: GetAllPublishingNotesAction
  ): void {
    this.tableOfContentService.getPublishingNotes(action.cubeId)
      .subscribe(publishingNotes => {
        ctx.patchState({ publishingNotes: publishingNotes });
      });
  }

  @Action(ResetPublishingNotes)
  public resetPublishingNotesAction(
    ctx: StateContext<PublishingNotesStateModel>,
    action: ResetPublishingNotes
  ): void {
    ctx.patchState({ publishingNotes: null });
  }

  @Action(GetPublishingNoteTranslationAction)
  public getPublishingNoteTranslationAction(
    ctx: StateContext<PublishingNotesStateModel>,
    action: GetPublishingNoteTranslationAction
  ): void {

    this.publishingNoteService
      .getPublishingNoteTranslation(action.publishingNoteId, action.languageId)
      .subscribe(publishingNoteTranslation =>
        ctx.patchState({ openedPublishingNoteTranslation: publishingNoteTranslation })
      );
  }

  @Action(GetPublishingNoteAction)
  public getPublishingNoteAction(
    ctx: StateContext<PublishingNotesStateModel>,
    action: GetPublishingNoteAction
  ): void {

    this.publishingNoteService
      .getPublishingNote(action.publishingNoteId)
      .subscribe(publishingNote =>
        ctx.patchState({ publishingNote: publishingNote })
      );
  }

  @Action(UpdatePublishingNoteTranslationAction)
  public updatePublishingNoteTranslationAction(
    ctx: StateContext<PublishingNotesStateModel>,
    action: UpdatePublishingNoteTranslationAction
  ): void {

    this.publishingNoteService
      .updatePublishingNoteTranslation(action.publishingNoteId, action.translatedPublishingNote)
      .subscribe(() => this.toastr.success(this.translationService.translate('PublishingNoteTranslationUpdated')));
  }

  private getTime(date?: Date) {
    return date != null ? date.getTime() : 0;
  }


  private sortByDateDesc(publishingNotes: PublishingNote[]): PublishingNote[] {
    publishingNotes.sort((a: PublishingNote, b: PublishingNote) => {
        return this.getTime(new Date(b.creationTimestamp)) - this.getTime(new Date(a.creationTimestamp));
    });
    return publishingNotes;
  }
}
