import { Directive, EventEmitter, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@shared/services/authentication.service';
import { takeUntil } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[hasRoles]'
})
export class HasRolesDirective implements OnInit, OnDestroy {
  @Input() hasRoles: string[];
  @Input() action: 'hide' | 'disable' = 'hide';

  @HostBinding('hidden')
  public get isHidden(): boolean {
    return this.action === 'hide' && !this.hasRole;
  }

  @HostBinding('attr.disabled')
  public get isDisabled(): true | null {
    const disabled = this.action === 'disable' && !this.hasRole;
    // Returning null prevents an HTML attribute from having '[disabled]="false"'
    return disabled ? true : null;
  }

  private hasRole = false;
  private destroy$ = new EventEmitter();

  constructor(private authenticationService: AuthenticationService) { }

  public ngOnInit(): void {
    this.authenticationService.userHasRoles(this.hasRoles).pipe(
      takeUntil(this.destroy$)
    ).subscribe(hasRole => this.hasRole = hasRole);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
