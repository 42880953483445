<div class="card">
  <div class="commentContainer card-header">
    <div class="row">
      <div class="col-md-6">
        <h2><i class="fa fa-comments" aria-hidden="true"></i><ng-container i18n="@@SuggestionDetails">Suggestion details</ng-container></h2>
      </div>
      <div class="text-right col-md-6 actionContainer">
        <button type="submit" form="updateForm" class="button saveButton" (click)="update()"
          [disabled]="!updateForm.valid">
          <i class="fa fa-floppy-o"></i><ng-container i18n="@@Save">Save</ng-container>
        </button>
        <button class="button cancelButton" (click)="close.emit()">
          <i class="fa fa-times"></i><ng-container i18n="@@Close">Close</ng-container>
        </button>
      </div>
    </div>
  </div>
  <div class="row card-body">
    <div class="col-8">
      <label for="status" i18n="@@Worker">Worker</label>
      <h3>{{proposition.worker?.displayName}}</h3>
    </div>
    <div class="col-4 text-right">
      <label for="status" i18n="@@Date">Date</label>
      <p>{{proposition.date | dateFormat}}</p>
    </div>
    <div class="col-12 ">
        <label i18n="@@Suggestion">Suggestion</label>
      <div class="commentZone">
        <p> {{proposition.text}}</p>
      </div>
    </div>
    <div class="col-12">
<hr/>
    </div>
    <div class="col-12">
      <form class="row" [formGroup]="updateForm">
        <div class="col-12">
          <label for="status" i18n="@@Status">Status</label>
        </div>
        <div class="col-12">
          <ng-select class="drop-down simple-select" name="status" [items]="listStatus" formControlName="status" [clearable]="false" [searchable]="false">
            <ng-template ng-label-tmp let-item="item">
              {{item | propositionStatus}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{item | propositionStatus}}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="comment" i18n="@@Comment">Comment</label>
            <textarea name="comment" class="form-control" rows="2" formControlName="comment"></textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
