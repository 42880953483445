import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';

@Injectable({
  providedIn: 'root'
})
export class SeedingService {
  private entityUrl = 'seeding/';

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public seedFormation(formationId: string, percentage: number): Observable<void> {
    return this.sintenoHttpService.put<void>(`${this.entityUrl}formations/${formationId}/readall/${percentage}`, null);
  }
}
