<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <label><ng-container i18n="@@Auditor">Auditor</ng-container>: </label>
      <p>{{audit.auditor.firstName+" "+audit.auditor.lastName}} </p>
    </div>
    <div class="col-6">
      <label><ng-container i18n="@@Worker">Worker</ng-container>: </label>
      <p>{{audit.auditionee.firstName+" "+audit.auditionee.lastName}} </p>
    </div>
  </div>
  <div class="row behaviourZone">
    <div class="col-12"> <label><ng-container i18n="@@AuditedBehaviour">Audited behaviour</ng-container>: </label></div>
    <app-behaviour-report class="col-12" [behaviour]="audit.behaviour" [score]="audit.score">
    </app-behaviour-report>
  </div>
  <div class="container-fluid">
    <div class="row commentContainer">
      <div class="col-6 commentZone">
        <label><ng-container i18n="@@AuditeeComment">Auditee comment</ng-container>: </label>
        <p> {{audit.auditioneeComment}}</p>
      </div>
      <div class="col-6 commentZone">
        <label><ng-container i18n="@@AuditorComment">Auditor comment</ng-container>:</label>
        <p> {{audit.auditorComment}} </p>
      </div>
      <div *ngIf="!audit.validated" class="col-12">
        <label><ng-container i18n="@@ReasonOfRefusal">Reason of refusal</ng-container>: </label>
        <p>{{audit.reasonRefusal}}</p>
      </div>
    </div>
  </div>
  <div *ngIf="showPropositions">
      <app-rule-change-proposition [proposition]="audit.proposition"></app-rule-change-proposition>
  </div>

  <div class="mt-1" *ngIf="audit.proposition">
    <button *ngIf="!showPropositions" type="button" class="button editButton" (click)="showPropositions = true" i18n="@@ShowSuggestion">
      Show suggestion </button>
    <button *ngIf="showPropositions" type="button" class="button editButton" (click)="showPropositions = false" i18n="@@HideSuggestion">
      Hide suggestion </button>
  </div>
  <div class="col-6">
    <label><ng-container i18n="@@Signature">Signature</ng-container> : </label>
    <img *ngIf="audit.auditioneeSignature && audit.auditioneeSignature.path" [src]=" audit.auditioneeSignature.path"
      class="signatureImg">
  </div>
  <div class="row">
    <label *ngIf="audit.medias && audit.medias.length > 1"><ng-container i18n="@@LinkedMedia">Linked media</ng-container>: </label>
    <label *ngIf="audit.medias && audit.medias.length === 1"><ng-container i18n="@@LinkedMedium">Linked medium</ng-container>: </label>
  </div>
  <div class="row">
    <div *ngFor="let media of audit.medias" class="col-6 offset-3">
      <img [src]="media.path" class="media" *ngIf="media.mediaType === MediaType.Picture">
      <video class="media" controls *ngIf="media.mediaType === MediaType.Video">
        <source [src]="media.path">
          <ng-container i18n="@@VideoNotSupported">Your browser does not support video playback</ng-container>
      </video>
    </div>
  </div>
</div>
