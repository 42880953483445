import { DetailedWorker, MinimalWorker, WorkerFormations, WorkerParent } from './worker.model';
import { State, StateContext, Action, Selector, Store } from '@ngxs/store';
// tslint:disable-next-line: max-line-length
import {
  GetPersonalWorker,
  GetGlobalWorkerStatistics,
  GetAllWorker,
  GetCurrentWorkerUser,
  DisableWorker,
  UpdateUser,
  AddImageToWorker,
  AddUser,
  AddLicenceToWorker,
  UpdateWorkerLicence,
  DeleteWorkerLicence,
  AddAuditorRightToWorker,
  UpdateWorkerAuditorRight,
  DeleteWorkerAuditorRight,
  GetPersonalPropositions,
  GetMinimalWorkers,
  GetWorkersFormations,
  GetWorkersSessions,
  AddImageToCurrentWorker,
  UpdateWorkerProfile,
  GetParentsById,
  SetParents
} from './worker.action';
import { WorkerService } from '@shared/services/worker.service';
import { GlobalWorkerStatistics } from '@shared/store/worker/global-worker-statistics.model';
import { RegisterModel } from '@core/authentication/models/user';
import { AuthenticationService } from '@shared/services/authentication.service';
import { switchMap, tap, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { CubeAssignmentService } from '@shared/services/cube-assignment.service';
import { PropositionModelDetail } from '@models/cube/proposition';
import { Constants } from '@shared/utils/constants-file';
import { ToastrService } from 'ngx-toastr';
import { TranslationService } from '@shared/services/translation.service';
import { GetAllCompanies, GetCompanyNodes, GetExtendedProperties } from '../company/company.action';

export interface WorkerStateModel {
  allWorkers: DetailedWorker[];
  minimalWorkers: MinimalWorker[];
  personalWorker: DetailedWorker;
  globalStatistics: GlobalWorkerStatistics;
  currentWorkerUser: RegisterModel;
  personalPropositions: PropositionModelDetail[];
  workersFormations: WorkerFormations[];
  workersSessions: WorkerFormations[];
  parents: WorkerParent[];
}

@State<WorkerStateModel>({
  name: 'worker',
  defaults: {
    allWorkers: [],
    minimalWorkers: [],
    personalWorker: null,
    globalStatistics: null,
    currentWorkerUser: null,
    personalPropositions: [],
    workersFormations: [],
    workersSessions: [],
    parents: []
  }
})
export class WorkerState {

  @Selector()
  public static allWorkers(workerSateModel: WorkerStateModel) {
    return workerSateModel.allWorkers;
  }

  @Selector()
  public static minimalWorkers(workerSateModel: WorkerStateModel) {
    return workerSateModel.minimalWorkers;
  }

  @Selector()
  public static personalWorker(workerSateModel: WorkerStateModel) {
    return workerSateModel.personalWorker;
  }

  @Selector()
  public static globalStatistics(workerStateModel: WorkerStateModel) {
    return workerStateModel.globalStatistics;
  }

  @Selector()
  public static currentWorkerUser(workerStateModel: WorkerStateModel) {
    return workerStateModel.currentWorkerUser;
  }

  @Selector()
  public static personalPropositions(workerSateModel: WorkerStateModel) {
    return workerSateModel.personalPropositions;
  }

  @Selector()
  public static filterWorkers(stateModel: WorkerStateModel) {
    return (name: string) => {
      return stateModel.allWorkers.filter(worker =>
        `${worker.firstName.toLowerCase()} ${worker.lastName.toLowerCase()}`
        .includes(name.toLowerCase()));
    };
  }

  @Selector()
  public static workerFormations(workerSateModel: WorkerStateModel) {
    return workerSateModel.workersFormations;
  }

  @Selector()
  public static workersSessions(workerSateModel: WorkerStateModel) {
    return workerSateModel.workersSessions;
  }


  @Selector()
  public static parents(workerSateModel: WorkerStateModel) {
    return workerSateModel.parents;
  }

  public constructor(
    private workerService: WorkerService,
    private authenticationService: AuthenticationService,
    private store: Store,
    private cubeAssignmenService: CubeAssignmentService,
    private toastr: ToastrService,
    private translationService: TranslationService
  ) {}

  @Action(GetPersonalWorker)
  public getPersonalWorker(
    ctx: StateContext<WorkerStateModel>,
    action: GetPersonalWorker
  ): void {
    this.workerService
      .getWorkerDetails(action.workerId)
      .subscribe(response => ctx.patchState({ personalWorker: response }));
  }

  @Action(GetPersonalPropositions)
  public getPersonalPropositions(
    ctx: StateContext<WorkerStateModel>,
    action: GetPersonalPropositions
  ): void {
    this.workerService
      .getWorkerPropositions(action.workerId)
      .subscribe(response => ctx.patchState({ personalPropositions: response }));
  }

  @Action(GetGlobalWorkerStatistics)
  public getGlobalWorkerStatistics(
    ctx: StateContext<WorkerStateModel>,
    action: GetGlobalWorkerStatistics
  ): void {
    this.workerService
      .getGlobalWorkerStatistics()
      .subscribe(statistics =>
        ctx.patchState({ globalStatistics: statistics })
      );
  }

  @Action(GetAllWorker)
  public getAllWorker(
    ctx: StateContext<WorkerStateModel>,
    action: GetAllWorker
  ): void {
    this.workerService
      .getWorkersDetails()
      .subscribe(workers => ctx.patchState({ allWorkers: workers }));
  }

  @Action(GetMinimalWorkers)
  public getMinimalWorkers(
    ctx: StateContext<WorkerStateModel>,
    action: GetMinimalWorkers
  ): void {
    this.workerService
      .getWorkers()
      .subscribe(workers => ctx.patchState({ minimalWorkers: workers }));
  }

  @Action(GetCurrentWorkerUser)
  public getCurrentWorkerUser(
    ctx: StateContext<WorkerStateModel>,
    action: GetCurrentWorkerUser
  ): void {
    this.authenticationService
      .getuserOfWorker(action.workerId)
      .subscribe(user => ctx.patchState({ currentWorkerUser: user }));
  }

  @Action(DisableWorker)
  public disableWorker(
    ctx: StateContext<WorkerStateModel>,
    action: DisableWorker
  ): void {
    this.workerService
      .disableEnableWorker(action.workerId)
      .pipe(
        switchMap(worker =>
          this.authenticationService.disableEnableWorker(worker.id)
        )
      )
      .subscribe(worker => {
        const getAllWorkerAction = new GetAllWorker();
        this.store.dispatch(getAllWorkerAction);
      });
  }

  @Action(UpdateUser)
  public updateUser(
    ctx: StateContext<WorkerStateModel>,
    action: UpdateUser
  ): void {
    this.authenticationService
      .update(action.user, action.workerId)
      .pipe(
        map(worker => {
          if (action.file) {
            this.store.dispatch(new AddImageToWorker(worker.id, action.file));
          }
        })
      )
      .subscribe(() => {
        this.store.dispatch(new GetAllWorker());
        // this.store.dispatch(new GetCompanyNodes());
      });
  }

  @Action(AddUser)
  public addUser(ctx: StateContext<WorkerStateModel>, action: AddUser): void {
    this.authenticationService
      .register(action.user, action.worker.id)
      .pipe(
        map(model => {
          if (action.file) {
            this.store.dispatch(new AddImageToWorker(model.worker.id, action.file));
          }
        }),
        tap(() => {
          if (action.refreshWorkers === true) {
            this.store.dispatch(new GetAllWorker());
          }
          if (action.refreshCompanies === true) {
            this.store.dispatch(new GetAllCompanies());
          }
        })
      )
      .subscribe();
  }

  @Action(AddImageToWorker)
  public addImageToWorker(
    ctx: StateContext<WorkerStateModel>,
    action: AddImageToWorker
  ): void {
    this.workerService
      .addImage(action.file, action.workerId)
      .subscribe(x => this.store.dispatch(new GetAllWorker()));
  }

  @Action(AddImageToCurrentWorker)
  public addImageToCurrentWorker(
    ctx: StateContext<WorkerStateModel>,
    action: AddImageToCurrentWorker
  ): void {
    this.workerService
      .addImage(action.file, Constants.GUID_EMPTY)
      .subscribe(x => this.toastr.success(this.translationService.translate('CurrentUserImageUpdatedSuccess')));
  }

  @Action(AddLicenceToWorker)
  public addLicenceToWorker(
    ctx: StateContext<WorkerStateModel>,
    action: AddLicenceToWorker
  ): void {
    this.cubeAssignmenService
      .addLicence(
        action.licence.cube.id,
        action.worker,
        action.licence.startDate,
        action.licence.endDate
      )
      .pipe(map(x => this.store.dispatch(new GetPersonalWorker(action.worker.id))))
      .subscribe(() => this.getAllWorker(ctx, new GetAllWorker()));
  }

  @Action(UpdateWorkerLicence)
  public updateWorkerLicence(
    ctx: StateContext<WorkerStateModel>,
    action: UpdateWorkerLicence
  ): void {
    this.cubeAssignmenService
      .updateLicence(
        action.licence.cube.id,
        action.licence.referenceId,
        action.worker,
        action.licence.startDate,
        action.licence.endDate
      )
      .pipe(map(x => this.store.dispatch(new GetPersonalWorker(action.worker.id))))
      .subscribe(() => this.getAllWorker(ctx, new GetAllWorker()));
  }

  @Action(DeleteWorkerLicence)
  public deleteWorkerLicence(
    ctx: StateContext<WorkerStateModel>,
    action: DeleteWorkerLicence
  ): void {
    this.cubeAssignmenService
      .deleteLicence(action.licence.cube.id, action.licence.referenceId)
      .subscribe(() => this.getAllWorker(ctx, new GetAllWorker()));
  }

  @Action(AddAuditorRightToWorker)
  public addAuditorRightToWorker(
    ctx: StateContext<WorkerStateModel>,
    action: AddAuditorRightToWorker
  ): void {
    this.cubeAssignmenService
      .addAuditor(
        action.audit.cube.id,
        action.worker,
        action.audit.startDate,
        action.audit.endDate
      )
      .pipe(map(x => this.store.dispatch(new GetPersonalWorker(action.worker.id))))
      .subscribe(() => this.getAllWorker(ctx, new GetAllWorker()));
  }

  @Action(UpdateWorkerAuditorRight)
  public updateWorkerAuditorRight(
    ctx: StateContext<WorkerStateModel>,
    action: UpdateWorkerAuditorRight
  ): void {
    this.cubeAssignmenService
      .updateAuditor(
        action.audit.cube.id,
        action.audit.referenceId,
        action.worker,
        action.audit.startDate,
        action.audit.endDate
      )
      .pipe(map(x => this.store.dispatch(new GetPersonalWorker(action.worker.id))))
      .subscribe(() => this.getAllWorker(ctx, new GetAllWorker()));
  }

  @Action(DeleteWorkerAuditorRight)
  public deleteWorkerAuditorRight(
    ctx: StateContext<WorkerStateModel>,
    action: DeleteWorkerAuditorRight
  ): void {
    this.cubeAssignmenService
      .deleteAuditor(action.audit.cube.id, action.audit.referenceId)
      .subscribe(() => this.getAllWorker(ctx, new GetAllWorker()));
  }

  @Action(GetWorkersFormations)
  public getWorkersFormations(
    ctx: StateContext<WorkerStateModel>,
    action: GetWorkersFormations
  ): void {
    this.workerService
      .getWorkersFormations()
      .subscribe(x =>
        ctx.patchState({ workersFormations: x })
      );
  }

  @Action(GetWorkersSessions)
  public getWorkersSessions(
    ctx: StateContext<WorkerStateModel>,
    action: GetWorkersSessions
  ): void {
    this.workerService
      .getWorkersSessions()
      .subscribe(x =>
        ctx.patchState({ workersSessions: x })
      );
  }

  @Action(UpdateWorkerProfile)
  public updateWorkerProfile(
    ctx: StateContext<WorkerStateModel>,
    action: UpdateWorkerProfile
  ): void {
    this.authenticationService
      .updateWorkerProfile(action.profile)
      .pipe(tap(x => {
        console.log('ICI', x);
        this.authenticationService.updateToken(x ? x.token : null);
        this.toastr.success(this.translationService.translate('ProfileUpdatedSuccess'));
    }))
      .subscribe();
  }


  @Action(GetParentsById)
  public getParentsById(
    ctx: StateContext<WorkerStateModel>,
    action: GetParentsById
  ): void {
    this.workerService
      .getParentsById(action.parentId, action.companyId)
      .subscribe(x =>
        ctx.patchState({ parents: x })
      );
  }

  @Action(SetParents)
  public setParents(
    ctx: StateContext<WorkerStateModel>,
    action: SetParents
  ): void {
    ctx.patchState({parents : action.parents});
  }
}
