import { StructuralNodeType } from '@models/structural-node-type/structural-node-type';

export class GetStructuralNodeTypes {
    public static readonly type = '[StructuralNodeType] Get StructuralNodeTypes';
}

export class GetStructuralNodeTypeAndLabels {
  public static readonly type = '[StructuralNodeType] Get StructuralNodeType';
  public constructor(public id: string) { }
}

export class AddStructuralNodeType {
    public static readonly type = '[StructuralNodeType] Add StructuralNodeType';
    public constructor(public structuralNodeType: StructuralNodeType) { }
}


export class UpdateStructuralNodeType {
    public static readonly type = '[StructuralNodeType] Update StructuralNodeType';
    public constructor(public structuralNodeType: StructuralNodeType) { }
}

export class GetStructuralNodeLabel {
  public static readonly type = '[StructuralNodeLabel] Get StructuralNodeLabel';
  public constructor(public id: string, public labelId: string) { }
}
