import { Behaviour, SelectableBehaviour } from './behaviour.model';

export class FlatChapter {
  public id: string;
  public name: string;
  public order: number;
  public numbering: string;
  public status: string;
  public content: string;
  public lectureTimeEstimation: number;
}

export function createFlatChapter(init?: Partial<FlatChapter>): FlatChapter {
  const flatChapter = {
    id: undefined,
    name: undefined,
    order: undefined,
    numbering: undefined,
    status: undefined,
    content: undefined,
    lectureTimeEstimation: undefined
  };
  Object.assign(flatChapter, init);
  return flatChapter;
}

export class Chapter extends FlatChapter {
  public numbering: string;
  public isRootChapter: boolean;
  public status: string;
  public chapters: Chapter[];
  public behaviours: Behaviour[];
  public hasCriteria: boolean;
  public numberOfQuestions: number;
  public hasEnoughQuestions: boolean;
  public hasDocumentation: boolean;
  public numberOfPropositions: number;
  public numberOfQuestionToComplete: number;
  public numberOfPendingPropositions: number;
  public statusLocked: boolean;
}

export function createChapter(init?: Partial<Chapter>): Chapter {
  const chapter = {
    ...createFlatChapter(),
    numbering: undefined,
    status: undefined,
    isRootChapter: undefined,
    chapters: undefined,
    behaviours: undefined,
    hasCriteria: undefined,
    numberOfQuestionToComplete: undefined,
    numberOfQuestions: undefined,
    hasEnoughQuestions: undefined,
    hasDocumentation: undefined,
    numberOfPropositions: undefined,
    numberOfPendingPropositions: undefined,
    statusLocked: undefined
  };
  Object.assign(chapter, init);

  if (chapter.numberOfQuestionToComplete == null) {
    chapter.numberOfQuestionToComplete = 0;
  }

  return chapter;
}

export class SelectableChapter {
  public id: string;
  public name: string;
  public numbering: string;
  public chapters: SelectableChapter[];
  public behaviours: SelectableBehaviour[];
  public selected: boolean;
}
