import { ExtendedProperty } from '@models/extended-property/extended-property';
import { ExtendedPropertyValue, FlatExtendedPropertyValue } from '@models/extended-property/extended-property-value';

export class NodeProperty {
  public id: string;
  public extendedProperty: ExtendedProperty;
  public extendedPropertyValues: ExtendedPropertyValue[];
}

export class NodePropertySelection extends NodeProperty {
  public availableExtendedPropertyValues: FlatExtendedPropertyValue[];
}
