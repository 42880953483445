import { Component, OnInit, Input } from '@angular/core';
import { CubeAssignment } from '@shared/store/worker/cube-assignment';
import { Cube } from '@shared/store/cube/models/cube.model';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-audit-rights-edit',
  templateUrl: './manage-audit-rights-edit.component.html'
})
export class ManageAuditRightsEditComponent implements OnInit {
  @Input() cubes: Cube[];
  @Input() assignment: CubeAssignment;

  public auditForm: FormGroup;
  public minDate: Date;
  public maxDate: Date;
  public startDate: Date;
  public endDate: Date;

  public constructor(private modal: NgbActiveModal) {}

  get cube() { return this.auditForm.get('cube'); }

  public ngOnInit(): void {
    this.auditForm = new FormGroup({
      cube: new FormControl(this.assignment.cube, [Validators.required])
    });

    const today = new Date();
    this.minDate = new Date(today);
    this.maxDate = new Date(today);
    this.minDate.setFullYear(today.getFullYear() - 100, 1);
    this.maxDate.setFullYear(today.getFullYear() + 10, 1);
    this.startDate = new Date(this.assignment.startDate);
    this.endDate = new Date(this.assignment.endDate);
  }

  public compareCubes(c1: Cube, c2: Cube) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  public update(): void {
    const assignment: CubeAssignment = {
      cube: this.cube.value,
      referenceId: this.assignment.referenceId,
      startDate: this.startDate,
      endDate: this.endDate
    };

    this.modal.close(assignment);
  }

  public close(): void {
    this.modal.dismiss();
  }
}
