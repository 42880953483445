import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetTitleAction, AddNavigationAction, BackNavigationAction } from './title.action';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { TranslationService } from '@shared/services/translation.service';

export interface TitleStateModel {
    title: string;
    navigationHistory: string[];
    canGoBack: boolean;
}

@State<TitleStateModel>({
    name: 'title',
    defaults: {
        title: null,
        navigationHistory: [],
        canGoBack: false
    }
})
export class TitleState {
    private HISTORY_MAX_LENGTH = 20;

    @Selector()
    public static title(stateModel: TitleStateModel) {
        return stateModel.title;
    }

    @Selector()
    public static canGoBack(stateModel: TitleStateModel) {
        return stateModel.canGoBack;
    }

    public constructor(private router: Router, private translationService: TranslationService) { }

    @Action(SetTitleAction)
    public setTitleAction(ctx: StateContext<TitleStateModel>, action: SetTitleAction): void {
        ctx.patchState({ title: action.title });
    }

    @Action(AddNavigationAction)
    public addNavigationAction(ctx: StateContext<TitleStateModel>, action: AddNavigationAction): void {
        const titleState = ctx.getState();

        if (!titleState.navigationHistory || action.url === '/' || action.url === '/login') {
            ctx.patchState({ navigationHistory: [action.url] });
            return;
        }

        if (titleState.navigationHistory[titleState.navigationHistory.length - 1] !== action.url) {
            const history = _.cloneDeep(titleState.navigationHistory);
            if (history.length >= this.HISTORY_MAX_LENGTH) {
              history.shift();
            }
            history.push(action.url);
            ctx.patchState({ navigationHistory: history });
        }

        if (ctx.getState().navigationHistory.length > 1) {
          ctx.patchState({ canGoBack: true });
        } else {
          ctx.patchState({ canGoBack: false });
        }
    }

    @Action(BackNavigationAction)
    public backNavigationAction(ctx: StateContext<TitleStateModel>, action: BackNavigationAction): void {
        const history = _.cloneDeep(ctx.getState()).navigationHistory;
        if (history.length > 1) {
            let url = history.pop();
            url = history[history.length - 1];
            ctx.patchState({ navigationHistory: history });
            this.router.navigate([url]);
        }
    }
}
