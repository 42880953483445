import { ProfileModel, RegisterModel } from '@core/authentication/models/user';
import { Worker, WorkerParent } from './worker.model';
import { CubeAssignment } from './cube-assignment';


export class GetPersonalWorker {
    public static readonly type = '[Worker] Get Personal Worker';

    public constructor(public workerId: string) { }
}

export class GetPersonalPropositions {
  public static readonly type = '[Worker] Get Personal Propositions';

  public constructor(public workerId: string) { }
}

export class GetGlobalWorkerStatistics {
    public static readonly type = '[Worker] Get Global Statistics';
  public constructor() { }
}


export class GetAllWorker {
    public static readonly type = '[Worker] Get all ';
  public constructor() { }
}

export class GetMinimalWorkers {
  public static readonly type = '[Worker] Get minimal workers';
  public constructor() { }
}

export class GetCurrentWorkerUser {
    public static readonly type = '[Worker] Get Current Worker User';

    public constructor(public workerId: string) { }
}

export class DisableWorker {
    public static readonly type = '[Worker] Disable Worker';

    public constructor(public workerId: string) { }
}

export class UpdateUser {
    public static readonly type = '[Worker] Update User';

    public constructor(public workerId: string, public user: RegisterModel, public file: File) { }
}

export class AddUser {
    public static readonly type = '[Worker] Add User';

    public constructor(public worker: Worker, public user: RegisterModel, public file: File,
      public refreshWorkers: boolean, public refreshCompanies) { }
}

export class AddImageToWorker {
    public static readonly type = '[Worker] Add Image';

    public constructor(public workerId: string, public file: File) { }
}

export class AddImageToCurrentWorker {
  public static readonly type = '[Worker] Add Image to current';

  public constructor(public file: File) { }
}

export class AddLicenceToWorker {
    public static readonly type = '[Worker] Add Licence';

    public constructor(public worker: Worker, public licence: CubeAssignment) {}
}

export class UpdateWorkerLicence {
    public static readonly type = '[Worker] Update Licence';

    public constructor(public worker: Worker, public licence: CubeAssignment) {}
}

export class DeleteWorkerLicence {
    public static readonly type = '[Worker] Delete Licence';

    public constructor(public licence: CubeAssignment) {}
}

export class AddAuditorRightToWorker {
    public static readonly type = '[Worker] Add Auditor RIght';

    public constructor(public worker: Worker, public audit: CubeAssignment) {}
}

export class UpdateWorkerAuditorRight {
    public static readonly type = '[Worker] Update Auditor Right';

    public constructor(public worker: Worker, public audit: CubeAssignment) {}
}

export class DeleteWorkerAuditorRight {
    public static readonly type = '[Worker] Delete Auditor Right';

    public constructor(public audit: CubeAssignment) {}
}

export class GetWorkersFormations {
  public static readonly type = '[Worker] Get Workers Formations';
  public constructor() { }
}

export class GetWorkersSessions {
  public static readonly type = '[Worker] Get Workers Sessions';
  public constructor() { }
}

export class UpdateWorkerProfile {
  public static readonly type = '[Worker] Update worker profile';
  public constructor(public profile: ProfileModel) {}
}

export class GetParentsById {
  public static readonly type = '[Worker] Get parents by Id';
  public constructor(public parentId: string, public companyId: string) {}
}

export class SetParents {
  public static readonly type = '[Worker] Set parents';
  public constructor(public parents: Array<WorkerParent>) {}
}