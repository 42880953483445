import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Constants } from '@shared/utils/constants-file';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const formattedDate = super.transform(value, Constants.DATE_FORMAT);
    if (!value || formattedDate === super.transform('9999-12-31T00:00:00', Constants.DATE_FORMAT)) {
      return 'N/A';
    }
    return formattedDate;
  }
}
