import { HttpHeaders, HttpParams } from '@angular/common/http';

export class HttpOptionsCustom {
  public headers?: HttpHeaders;
  public observe?: 'body';
  public params?: HttpParams;
  public reportProgress?: boolean;
  public responseType?: 'json';
  public withCredentials?: boolean;
}
