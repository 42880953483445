<div class="card">
  <div class="card-header">
    <div class="card-title">
      <h2 i18n="@@CreateTraining">Create a training</h2>
    </div>
  </div>
  <div class="card-body">
    <div class="form-group">
      <ng-select id="cubes" [(ngModel)]="selectedCube" class="simple-select" name="cube" [compareWith]="compareCubes"
        [items]="cubes" bindLabel="name" [multiple]="false" [clearable]="false" [searchable]="false">
      </ng-select>
    
    </div>
  </div>
  <div class="card-footer text-center">
    <button class="button saveButton" (click)="selectCube()"><i class="fa fa-plus"></i><ng-container i18n="@@Add">Add</ng-container></button>
    <button class="button cancelButton" (click)="close()"><i class="fa fa-times"></i><ng-container i18n="@@Cancel">Cancel</ng-container></button>
  </div>
</div>
