import { PropositionStatusPipe } from './pipes/propositionStatus.pipe';
import { NumberingPipe } from '@shared/pipes/numbering.pipe';
import { AuditListComponent } from './components/audit-list/audit-list.component';
import { AuditDetailComponent } from './components/audit-detail/audit-detail.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '../../../node_modules/@angular/router';
import { FontAwesomeIconsModule } from '@shared/modules/font-awesome-icons.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TreeModule as Angular2TreeModule } from 'angular-tree-component';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NgxEchartsModule } from 'ngx-echarts';
import { EchartBarComponent } from './components/echart-bar/echart-bar.component';
import { EchartBarAuditComponent } from './components/echart-bar-audit/echart-bar-audit.component';
import { IndicatorComponent } from './components/indicator/indicator.component';
import { DateFormatPipe } from './pipes/dateFormat.pipe';
import { PeriodPickerComponent } from './components/period-picker/period-picker.component';
import { AuditAverageCardComponent } from './components/audit-average-card/audit-average-card.component';
import { AuditChapterCardComponent } from './components/audit-chapter-card/audit-chapter-card.component';
import { AuditHistogramCardComponent } from './components/audit-histogram-card/audit-histogram-card.component';
import { CountoModule } from 'angular2-counto';
import { NumberCounterComponent } from './components/number-counter/number-counter.component';
import { ToastrModule } from 'ngx-toastr';
import { BehaviourReportComponent } from './components/behaviour-report/behaviour-report.component';
import { InputFileComponent } from './components/input-file/input-file.component';
import { FormationDetailComponent } from './components/formation-detail/formation-detail.component';
import { FormationChapterComponent } from './components/formation-chapter/formation-chapter.component';
import { FormationListComponent } from './components/formation-list/formation-list.component';
import { FormationStatisticsListComponent } from './components/formation-statistics-list/formation-statistics-list.component';
// tslint:disable-next-line:max-line-length
import { ContinuousFormationStatisticsListComponent } from './components/continuous-formation-statistics-list/continuous-formation-statistics-list.component';
import { RuleChangePropositionComponent } from './components/rule-change-proposition/rule-change-proposition.component';
import { RuleChangePropositionListComponent } from './components/rule-change-proposition-list/rule-change-proposition-list.component';
import { CreatePropositionComponent } from './components/create-proposition/create-proposition.component';
import { PropositionListComponent } from './components/proposition-list/proposition-list.component';
import { AnimatedAccordionComponent } from './components/animated-accordion/animated-accordion.component';
import { FormationSessionPreviewComponent } from './components/formation-session-preview/formation-session-preview.component';
import { PublishingNoteCreationComponent } from './components/publishing-note-creation/publishing-note-creation.component';
import { FormationSessionListComponent } from './components/formation-session-list/formation-session-list.component';
import { PublishingNoteListComponent } from './components/publishing-note-list/publishing-note-list.component';
import { AuditDetailModalComponent } from './components/audit-detail-modal/audit-detail-modal.component';
import { AnimatedAccordionButtonComponent } from './components/animated-accordion-button/animated-accordion-button.component';
import { FormationSessionPreviewContentComponent } from './components/formation-session-preview-content/formation-session-preview-content.component';
import { CubeFormationSessionListComponent } from './components/cube-formation-session-list/cube-formation-session-list.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { SelectableExtendedPropertyValueComponent } from './components/selectable-extended-property-value/selectable-extended-property-value.component';
import { WorkerCreateComponent } from './components/worker-create/worker-create.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateDirective } from './directives/translate.directive';
import { LanguageSelectionComponent } from './components/language-selection/language-selection.component';
import { PublishingNoteTranslationComponent } from './components/publishing-note-translation/publishing-note-translation.component';
import { QuestionPresentationComponent } from './components/question-presentation/question-presentation.component';
import { FormationQuestionResultComponent } from './components/formation-question-result/formation-question-result.component';
import { EchartLineComponent } from './components/echart-line/echart-line.component';
import { ChapterWithBehaviourPipe } from './pipes/chapterWithBehaviour.pipe';
import { TheoreticalDetailModalComponent } from './components/theoretical-evaluation-detail-modal/theoretical-evaluation-detail-modal.component';
import { TheoreticalEvaluationDetailComponent } from './components/theoretical-evaluation-detail/theoretical-evaluation-detail.component';
import { TheoreticalEvaluationListComponent } from './components/theoretical-evaluation-list/theoretical-evaluation-list.component';
import { TheoreticalEvaluationAverageCardComponent } from './components/theoretical-evaluation-average-card/theoretical-evaluation-average-card.component';
import { TheoreticalEvaluationHistogramCardComponent } from './components/theoretical-evaluation-histogram-card/theoretical-evaluation-histogram-card.component';
import { FormationSessionStatisticsListComponent } from './components/formation-session-statistics-list/formation-session-statistics-list.component';
import { FormationSessionStatisticComponent } from './components/formation-session-statistic/formation-session-statistic.component';
import { EchartLineEvolutionProgressComponent } from './components/echart-line-evolution-progress/echart-line-evolution-progress.component';
import { FormationSessionStatisticsWorkersListComponent } from './components/formation-session-statistics-workers-list/formation-session-statistics-workers-list.component';
import { AnimatedAccordionSimpleComponent } from './components/animated-accordion-simple/animated-accordion-simple.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FormationCoverComponent } from './components/formation-cover/formation-cover.component';
import { CoverPreviewComponent } from './components/cover-preview/cover-preview.component';
import { PublishingNotePreviewComponent } from './components/publishing-note-preview/publishing-note-preview.component';
import { WorkerEditComponent } from './components/worker-edit/worker-edit.component';
import { ManageAuditRightsComponent } from './components/manage-audit-rights/manage-audit-rights.component';
import { ManageAuditRightsAddComponent } from './components/manage-audit-rights/manage-audit-rights-add/manage-audit-rights-add.component';
import { ManageAuditRightsEditComponent } from './components/manage-audit-rights/manage-audit-rights-edit/manage-audit-rights-edit.component';
import { ManageFormationsComponent } from './components/manage-formations/manage-formations.component';
import { ManageFormationAddComponent } from './components/manage-formations/manage-formation-add/manage-formation-add.component';
import { ManageLicencesComponent } from './components/manage-licences/manage-licences.component';
import { ManageLicencesAddComponent } from './components/manage-licences/manage-licences-add/manage-licences-add.component';
import { ManageLincencesEditComponent } from './components/manage-licences/manage-lincences-edit/manage-lincences-edit.component';
import { HasRolesDirective } from './directives/has-roles.directive';
import { DurationFormatPipe } from './pipes/durationFormat.pipe';
import { HighchartsChartModule } from 'highcharts-angular';
import { UpdatablePropositionListComponent } from './components/updatable-proposition-list/updatable-proposition-list.component';
import { PropositionDetailComponent } from './components/proposition-detail/proposition-detail.component';
import { ChaptersBehavioursTableComponent } from './components/chapters-behaviours-table/chapters-behaviours-table.component';
import { ChapterSelectionComponent } from './components/chapters-behaviours-table/chapter-selection/chapter-selection.component';
import { BehaviourSelectionComponent } from './components/chapters-behaviours-table/behaviour-selection/behaviour-selection.component';
import { SelectableWorkerComponent } from './components/selectable-worker/selectable-worker.component';
import { PersonalDetailComponent } from './components/personal-detail/personal-detail.component';
import { PersonalLicenceListComponent } from './components/personal-licence-list/personal-licence-list.component';
import { PersonalPublishingNoteListComponent } from './components/personal-publishing-note-list/personal-publishing-note-list.component';
import { SelectableCompanyComponent } from './components/selectable-company/selectable-company.component';
import { NouisliderModule } from 'ng2-nouislider';
import { FormationSessionStatisticsCompaniesListComponent } from './components/formation-session-statistics-companies-list/formation-session-statistics-companies-list.component';
import { TermsOfAgreementComponent } from './terms-of-agreement/terms-of-agreement.component';
import { AuditContinuousCardComponent } from './components/audit-continuous-card/audit-continuous-card.component';
import { HierarchyDetailComponent } from './components/hierarchy-detail/hierarchy-detail.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeIconsModule,
    RouterModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    NgSelectModule,
    MatSelectModule,
    Angular2TreeModule.forRoot(),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgxEchartsModule,
    CountoModule,
    ToastrModule,
    MatCheckboxModule,
    DragDropModule,
    MatSidenavModule,
    MatMenuModule,
    HighchartsChartModule,
    NouisliderModule
  ],
  declarations: [
    ConfirmationDialogComponent,
    SafeHtmlPipe,
    EchartBarComponent,
    EchartBarAuditComponent,
    EchartLineComponent,
    EchartLineEvolutionProgressComponent,
    DatePickerComponent,
    IndicatorComponent,
    DateFormatPipe,
    ChapterWithBehaviourPipe,
    PropositionStatusPipe,
    NumberingPipe,
    PeriodPickerComponent,
    AuditAverageCardComponent,
    AuditChapterCardComponent,
    AuditHistogramCardComponent,
    TheoreticalEvaluationHistogramCardComponent,
    BehaviourReportComponent,
    AuditDetailComponent,
    AuditDetailModalComponent,
    TheoreticalEvaluationDetailComponent,
    TheoreticalDetailModalComponent,
    NumberCounterComponent,
    AuditListComponent,
    TheoreticalEvaluationAverageCardComponent,
    TheoreticalEvaluationListComponent,
    InputFileComponent,
    FormationDetailComponent,
    FormationChapterComponent,
    FormationQuestionResultComponent,
    FormationListComponent,
    FormationSessionListComponent,
    FormationStatisticsListComponent,
    FormationSessionStatisticsListComponent, FormationSessionStatisticComponent, FormationSessionStatisticsWorkersListComponent,
    ContinuousFormationStatisticsListComponent,
    RuleChangePropositionComponent,
    RuleChangePropositionListComponent,
    CreatePropositionComponent,
    PropositionListComponent,
    AnimatedAccordionComponent,
    AnimatedAccordionSimpleComponent,
    FormationSessionPreviewComponent,
    FormationSessionPreviewComponent,
    PublishingNoteCreationComponent,
    PublishingNoteListComponent,
    AnimatedAccordionButtonComponent,
    FormationSessionPreviewContentComponent,
    CubeFormationSessionListComponent,
    SelectableExtendedPropertyValueComponent,
    WorkerCreateComponent,
    TranslateDirective,
    LanguageSelectionComponent,
    PublishingNoteTranslationComponent,
    PublishingNoteTranslationComponent,
    QuestionPresentationComponent,
    FormationCoverComponent,
    CoverPreviewComponent,
    PublishingNotePreviewComponent,
    WorkerEditComponent,
    ManageAuditRightsComponent,
    ManageAuditRightsAddComponent,
    ManageAuditRightsEditComponent,
    ManageFormationsComponent,
    ManageFormationAddComponent,
    ManageLicencesComponent,
    ManageLicencesAddComponent,
    ManageLincencesEditComponent,
    HasRolesDirective,
    DurationFormatPipe,
    UpdatablePropositionListComponent,
    PropositionDetailComponent,
    ChaptersBehavioursTableComponent,
    ChapterSelectionComponent,
    BehaviourSelectionComponent,
    SelectableWorkerComponent,
    PersonalDetailComponent,
    PersonalLicenceListComponent,
    PersonalPublishingNoteListComponent,
    SelectableCompanyComponent,
    FormationSessionStatisticsCompaniesListComponent,
    TermsOfAgreementComponent,
    AuditContinuousCardComponent,
    AuditContinuousCardComponent,
    HierarchyDetailComponent
  ],
  exports: [
    CommonModule,
    AuditListComponent,
    TheoreticalEvaluationAverageCardComponent,
    TheoreticalEvaluationListComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeIconsModule,
    RouterModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    NgSelectModule,
    MatCheckboxModule,
    RouterModule,
    MatSelectModule,
    Angular2TreeModule,
    DragDropModule,
    FroalaEditorModule,
    FroalaViewModule,
    SafeHtmlPipe,
    NgxEchartsModule,
    EchartBarComponent,
    EchartLineComponent,
    EchartLineEvolutionProgressComponent,
    DatePickerComponent,
    IndicatorComponent,
    DateFormatPipe,
    ChapterWithBehaviourPipe,
    PropositionStatusPipe,
    NumberingPipe,
    PeriodPickerComponent,
    AuditAverageCardComponent,
    AuditChapterCardComponent,
    AuditHistogramCardComponent,
    TheoreticalEvaluationHistogramCardComponent,
    NumberCounterComponent,
    InputFileComponent,
    FormationDetailComponent,
    FormationChapterComponent,
    FormationListComponent,
    FormationQuestionResultComponent,
    FormationStatisticsListComponent,
    ContinuousFormationStatisticsListComponent,
    FormationSessionStatisticsListComponent, FormationSessionStatisticComponent, FormationSessionStatisticsWorkersListComponent,
    RuleChangePropositionComponent,
    RuleChangePropositionListComponent,
    PropositionListComponent,
    AnimatedAccordionComponent,
    AnimatedAccordionSimpleComponent,
    FormationSessionPreviewComponent,
    FormationSessionListComponent,
    PublishingNoteCreationComponent,
    PublishingNoteListComponent,
    AuditDetailModalComponent,
    TheoreticalDetailModalComponent,
    AnimatedAccordionButtonComponent,
    CubeFormationSessionListComponent,
    SelectableExtendedPropertyValueComponent,
    WorkerCreateComponent,
    MatSidenavModule,
    MatMenuModule,
    TranslateDirective,
    LanguageSelectionComponent,
    PublishingNoteTranslationComponent,
    QuestionPresentationComponent,
    CoverPreviewComponent,
    PublishingNotePreviewComponent,
    WorkerEditComponent,
    ManageAuditRightsComponent,
    ManageAuditRightsAddComponent,
    ManageAuditRightsEditComponent,
    ManageFormationsComponent,
    ManageFormationAddComponent,
    ManageLicencesComponent,
    ManageLicencesAddComponent,
    ManageLincencesEditComponent,
    HasRolesDirective,
    DurationFormatPipe,
    HighchartsChartModule,
    UpdatablePropositionListComponent,
    PersonalDetailComponent,
    PersonalLicenceListComponent,
    PersonalPublishingNoteListComponent,
    NouisliderModule,
    TermsOfAgreementComponent,
    AuditContinuousCardComponent
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-be' }
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    AuditDetailModalComponent,
    TheoreticalDetailModalComponent,
    CreatePropositionComponent,
    ManageLicencesAddComponent,
    ManageLincencesEditComponent,
    ManageAuditRightsAddComponent,
    ManageAuditRightsEditComponent,
    ManageFormationAddComponent]
})
export class SharedModule { }
