import { LoginModel, ProfileModel, RegisterModel } from '../../core/authentication/models/user';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { tap, first, map } from 'rxjs/operators';
import { JwtToken } from '@models/authentication/jwt-token';
import * as jwt_decode from 'jwt-decode';
import { UserSessionModel } from '@shared/store/session/session.model';
import { GetCurrentSessionAndGoToHome } from '@shared/store/session/session.action';
import { Store } from '@ngxs/store';
import { AuthResponse } from '@models/user/auth-response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private entityUrl = 'users/';
  public isAuthenticated$: Observable<boolean>;
  public token$: Observable<JwtToken>;
  public encodedToken$: Observable<string>;
  private encodedTokenSource = new BehaviorSubject<string>(null);
  public currentUserProfile$: Observable<UserSessionModel>;
  private currentUserProfileSource =
    new ReplaySubject<UserSessionModel>(1);
  private readonly tokenLocalStorageKey = 'access_token';

  public constructor(private sintenoHttpService: SintenoHttpService, private store: Store) {
    this.currentUserProfile$  = this.currentUserProfileSource.asObservable();
    this.encodedToken$ = this.encodedTokenSource.asObservable().pipe(
      tap(token => {
        if (token != null) {
          localStorage.setItem(this.tokenLocalStorageKey, token);
        }
      })
    );
    this.token$ = combineLatest([this.encodedToken$, this.currentUserProfile$]).pipe(
      map(resp => {
        console.log('OK ON PASSE DEDANS', resp);
        if (resp != null && resp[0] != null && resp[1] != null) {
          const decodedToken = this.decodeToken(resp[0]);
          this.initiateAutoRefresh(decodedToken.exp);
          return decodedToken;
        }
        return null;
      })
    );
    this.isAuthenticated$ = this.token$.pipe(
      map(x => x != null)
    );
    this.tryLoadToken();
  }

  public login(user: LoginModel): Observable<string> {
    return this.getUserJwtToken(user).pipe(
      first(),
      tap(x => {
        this.encodedTokenSource.next(x);
        this.store.dispatch(new GetCurrentSessionAndGoToHome());
      })
    );
  }

  public getCurrentSession(): Observable<UserSessionModel> {
    return this.sintenoHttpService.get<UserSessionModel>('users/currentsession/').pipe(map(x => {
      this.currentUserProfileSource.next(x);
      return x;
    }));
  }

  private getUserJwtToken(user: LoginModel): Observable<string> {
    return this.sintenoHttpService.post<AuthResponse>(this.entityUrl + 'login/', user).pipe(
      map(x => x.token)
    );
  }

  public register(user: RegisterModel, workerId: string): Observable<RegisterModel> {
    user.workerId = workerId;
    return this.sintenoHttpService.post<RegisterModel>(this.entityUrl + 'register/', user);
  }

  public update(user: RegisterModel, workerId: string): Observable<any> {
    user.workerId = workerId;
    return this.sintenoHttpService.put<RegisterModel>(this.entityUrl + workerId, user);
  }

  public logout(): void {
    localStorage.removeItem(this.tokenLocalStorageKey);
    this.encodedTokenSource.next(null);
    this.store.dispatch(new GetCurrentSessionAndGoToHome());
  }

  public disableEnableWorker(id: string): Observable<any> {
    return this.sintenoHttpService.put(this.entityUrl + id + '/disable', null);
  }

  public getuserOfWorker(workerId: string): Observable<RegisterModel> {
    return this.sintenoHttpService.get(this.entityUrl + workerId);
  }

  private decodeToken(token: string): JwtToken {
    return jwt_decode(token) as JwtToken;
  }

  private initiateAutoRefresh(expirationTimeStamp: number): void {
    const tokenExpirationDelay = expirationTimeStamp - this.currentUnixTimeStamp;
    setTimeout(() => {
      this.refreshToken().pipe(first()).subscribe(x => this.encodedTokenSource.next(x));
    }, tokenExpirationDelay * 1000);
  }

  /**
   * Returns the current Unix timestamp in seconds.
   */
  private get currentUnixTimeStamp(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  private refreshToken(): Observable<string> {
    return this.sintenoHttpService.post<AuthResponse>(this.entityUrl + 'refresh/', null).pipe(
      map(x => x.token)
    );
  }

  private tryLoadToken(): void {
    const encodedToken = localStorage.getItem(this.tokenLocalStorageKey);
    if (encodedToken != null && encodedToken !== '') {
      const decodedToken = this.decodeToken(encodedToken);
      if (this.isTokenValid(decodedToken)) {
        this.encodedTokenSource.next(encodedToken);
      } else {
        localStorage.removeItem(this.tokenLocalStorageKey);
      }
    }
  }

  private isTokenValid(token: JwtToken): boolean {
    return token.exp > this.currentUnixTimeStamp;
  }


  public userHasRoles(roles: string[]): Observable<boolean> {
    return this.currentUserProfile$.pipe(
      map(user => {
        if (!user || !user.roles) {
          return false;
        }
        return user.roles.filter(role => roles.includes(role)).length > 0;
      })
    );
  }

  public areTermsAccepted(): Observable<boolean> {
    return this.currentUserProfile$.pipe(
      map(user => {
        if (!user || !user.termsAccepted) {
          return false;
        }
        return true;
      })
    );
  }

  public updateWorkerProfile(profile: ProfileModel): Observable<AuthResponse> {
    return this.sintenoHttpService.put(`${this.entityUrl}profile`, profile);
  }

  public acceptTerms(): Observable<any> {
    return this.sintenoHttpService.post(`${this.entityUrl}accept-terms`, null);
  }

  public updateToken(token: string) {
    this.encodedTokenSource.next(token);
    if (token == null) {
      this.logout();
    }
  }
}
