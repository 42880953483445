export const environment = {
  production: false,
  loadExternalConfig: true,
  configFilePath: './assets/config.json',
  apiUrl: 'http://localhost:5000/api/',
  localesUrlPathMappings: {
    'fr-BE': '/fr',
    'nl-BE': '/nl',
    'en-US': ''
  }
};
