import { CubeSelection } from '../cube/models/cube-selection.model';

export class GetPersonalAuditHistogramData {
    public static readonly type = '[Audit] Get personal histogram data';
    public constructor(public startDate: Date, public endDate: Date, public workerId: string, public includeSubAssignedWorker: boolean) { }
}


export class GetPersonalTheoreticalHistogramData {
    public static readonly type = '[Theory] Get personal histogram data';
    public constructor(public startDate: Date, public endDate: Date, public workerId: string, public includeSubAssignedWorker: boolean) { }
}

export class GetPersonalTheoreticalHistogramPerFormation {
  public static readonly type = '[Theory] Get personal histogram per formation';
  public constructor(public workerId: string, public formationId: string, public showSessionData: boolean) { }
}

export class GetPersonalAuditData {
    public static readonly type = '[Audit] Get personal audit data';
    public constructor(public workerId: string, public startDate: Date, public endDate: Date, public includeSubAssignedWorker: boolean) { }
}


export class GetAuditorData {
  public static readonly type = '[Audit] Get auditor data';
  public constructor(public workerId: string, public startDate: Date, public endDate: Date) { }
}

export class GetPersonalTheoryDataPerFormation {
  public static readonly type = '[Theory] Get personal audit data per formation';
  public constructor(public workerId: string, public formationId: string, public showSessionData: boolean) { }
}

export class GetCubesEnrichedWithAudits {
    public static readonly type = '[Audit] Get personal cubes';
    public constructor(public workerId: string, public startDate: Date, public endDate: Date, public includeSubAssignedWorker: boolean) { }
}

export class GetCubesEnrichedWithAuditsForAuditor {
  public static readonly type = '[Audit] Get auditor cubes';
  public constructor(public workerId: string, public startDate: Date, public endDate: Date) { }
}

export class GetCubesEnrichedWithTheoreticalEvaluations {
    public static readonly type = '[Theory] Get personal cubes';
    public constructor(public workerId: string, public startDate: Date, public endDate: Date, public includeSubAssignedWorker: boolean) { }
}

export class GetCubesEnrichedWithTheoreticalEvaluationsPerFormation {
  public static readonly type = '[Theory] Get personal cubes per formation';
  public constructor(public workerId: string, public formationId: string, public showSessionData: boolean) { }
}

export class GetAuditData {
    public static readonly type = '[Audit] Get audit data';
    public constructor(public auditId: string) { }
}

export class GetTheoryEvaluationData {
    public static readonly type = '[Theory] Get Theory data';
    public constructor(public theoryEvaluationId: string) { }
}

export class GetCubeWithEvaluation {
    public static readonly type = '[Audit] Get evalutions cube';
    public constructor(public cubeSelection: CubeSelection) { }
}


export class GetBehaviorStatistics {
    public static readonly type = '[Audit] Get Behavior statistics';
    // tslint:disable-next-line:max-line-length
    public constructor(public cubeId: string, public chapterId: string, public behaviorId: string, public startDate: Date, public endDate: Date) { }
}


export class CloseBehaviorStatistics {
    public static readonly type = '[Audit] Close Behavior statistics';
    // public constructor() { }
}

export class GetTheoreticalHistogramPerPublishingNote {
  public static readonly type = '[Theory] Get histogram per publishingNote';
  public constructor(public publishingNoteId: string) { }
}

export class GetCubesEnrichedWithTheoreticalEvaluationsPerPublishingNote {
  public static readonly type = '[Theory] Get cubes per publishingNotes';
  public constructor(public publishingNoteId: string) { }
}

export class GetTheoryDataPerPublishingNote {
  public static readonly type = '[Theory] Get audit data per publishing note';
  public constructor(public publishingNoteId: string) { }
}

export class FilterBehaviours {
  public static readonly type = '[Audit] Filter Behaviours';
  public constructor(public keyword: string, public minQuestion: number, public maxQuestion: number, public minAudit: number, public maxAudit: number) { }
}

export class EmptyCubeWithEvaluation {
  public static readonly type = '[Audit] Empty cube with evaluation';
  public constructor() { }
}


export class SetIsCubesWithNodes {
  public static readonly type = '[Audit] Set Cubes With Node';
  public constructor(public value: boolean) { }
}

export class GetAuditorHistogramData {
  public static readonly type = '[Audit] Get auditor Histogram data';
  public constructor(public startDate: Date, public endDate: Date, public workerId: string) { }
}
