import { SintenoHttpInterceptor } from './shared/interceptors/http/sinteno-http.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { AppRoutingModule } from './routing/app-routing.module';
import { Router } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFrBe from '@angular/common/locales/fr-BE';
import localeFrBeExtra from '@angular/common/locales/extra/fr-BE';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@shared/store/store.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SpinnerInterceptor } from '@shared/interceptors/http/spinner.interceptor';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '@shared/utils/ngb-date-parser-formatter';
import 'froala-editor/js/plugins.pkgd.min.js';
import { TranslationService } from '@shared/services/translation.service';
import { AuthenticationInterceptor } from '@shared/interceptors/authentication.interceptor';


registerLocaleData(localeFrBe, localeFrBeExtra);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    StoreModule,
    NgbModule
  ],
  providers: [
    TranslationService,
    { provide: LOCALE_ID, useValue: 'fr-be' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SintenoHttpInterceptor,
      multi: true,
    },
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public constructor(router: Router) {
    console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }
}
