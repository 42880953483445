import { Injectable } from '@angular/core';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { Worker } from '../store/worker/worker.model';
import { Observable } from 'rxjs';
import { AuditorAssignment, LicenceAssignment } from '../store/cube/models/assignment.model';
import { CubeDetailed } from '../store/cube/models/cube.model';

@Injectable({
  providedIn: 'root'
})
export class CubeAssignmentService {
  public entityUrl = 'cubes/';
  public auditorsEntityName = '/auditors/';
  public licencesEntityName = '/licences/';
  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public addAuditor(cubeId: string, worker: Worker, startDate: Date, endDate: Date): Observable<AuditorAssignment> {
    const model = new AuditorAssignment();
    model.worker = worker;
    model.startDate = startDate;
    model.endDate = endDate;
    return this.sintenoHttpService.post<AuditorAssignment>(this.entityUrl + cubeId + this.auditorsEntityName, model);
  }

  public updateAuditor(cubeId: string, auditorRightId: string, worker: Worker, startDate: Date, endDate: Date)
    : Observable<AuditorAssignment> {
    const model = new AuditorAssignment();
    model.worker = worker;
    model.startDate = startDate;
    model.endDate = endDate;
    model.id = auditorRightId;
    return this.sintenoHttpService.put<AuditorAssignment>(this.entityUrl + cubeId + this.auditorsEntityName + auditorRightId, model);
  }

  public deleteAuditor(cubeId: string, auditorRightId: string): Observable<CubeDetailed> {
    return this.sintenoHttpService.delete<CubeDetailed>(this.entityUrl + cubeId + this.auditorsEntityName + auditorRightId);
  }

  public addLicence(cubeId: string, worker: Worker, startDate: Date, endDate: Date): Observable<LicenceAssignment> {
    const model = new LicenceAssignment();
    model.worker = worker;
    model.startDate = startDate;
    model.endDate = endDate;
    return this.sintenoHttpService.post<LicenceAssignment>(this.entityUrl + cubeId + this.licencesEntityName, model);
  }

  public updateLicence(cubeId: string, licenceId: string, worker: Worker, startDate: Date, endDate: Date): Observable<LicenceAssignment> {
    const model = new LicenceAssignment();
    model.worker = worker;
    model.startDate = startDate;
    model.endDate = endDate;
    model.id = licenceId;
    return this.sintenoHttpService.put<LicenceAssignment>(this.entityUrl + cubeId + this.licencesEntityName + licenceId, model);
  }

  public deleteLicence(cubeId: string, licenceId: string): Observable<CubeDetailed> {
    return this.sintenoHttpService.delete<CubeDetailed>(this.entityUrl + cubeId + this.licencesEntityName + licenceId);
  }
}
