import { FormationStatisticByWorker } from '../models/formation/formation.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { BehaviorStatistic, CubeDetailedWithEvaluation } from '@shared/store/audit/audit.model';
import { FormationSessionStatisticsPerPublishingNote, FormationSessionStatisticsPerPublishingNoteDetail } from '@models/formation/formation-session-statistics.model';
import { HttpParams } from '@angular/common/http';
import { generateDateParam } from '@shared/utils/utils';
import { TrainingStatistics } from '@shared/store/audit/training-statistics.model';
import { CubeSelection } from '@shared/store/cube/models/cube-selection.model';
import { HttpOptionsCustom } from '@models/http/http-options.model';

@Injectable({
  providedIn: 'root'
})
export class StatisticService {
  private entityUrl = 'statistics/';

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public getWorkerFormationsStatistics(): Observable<FormationStatisticByWorker[]> {
    return this.sintenoHttpService.get<FormationStatisticByWorker[]>(
      `${this.entityUrl}GetWorkersFormationsStatistics`
    );
  }

  public getFormationSessionStatisticsByPublishingNoteId(publishingNoteId: string):
    Observable<FormationSessionStatisticsPerPublishingNoteDetail> {
    return this.sintenoHttpService.get<FormationSessionStatisticsPerPublishingNoteDetail>(
      `${this.entityUrl}GetFormationSessionStatisticsByPublishingNoteId?publishingNoteId=${publishingNoteId}`
    );
  }

  public getCubeFormationSessionStatistics(cubeIds: string[]): Observable<FormationSessionStatisticsPerPublishingNote[]> {
    let params = new HttpParams();

    for (const id of cubeIds) {
      params = params.append('cubeIds', id);
    }

    return this.sintenoHttpService.get<FormationSessionStatisticsPerPublishingNote[]>(
      `${this.entityUrl}GetFormationSessionStatistics`, { params }
    );
  }

  public getCubesWithEvaluations(cubeSelection: CubeSelection): Observable<CubeDetailedWithEvaluation[]> {
    const url = `${this.entityUrl}GetCubesWithEvaluations`;
    const options = new HttpOptionsCustom();
    options.params = new HttpParams();
    options.params = options.params.append('listIds', cubeSelection.ids.toString());
    options.params = options.params.append('startDate',
      `${cubeSelection.startDate.getMonth() + 1}/${cubeSelection.startDate.getDate()}/${cubeSelection.startDate.getFullYear()}`);
    options.params = options.params.append('endDate',
      `${cubeSelection.endDate.getMonth() + 1}/${cubeSelection.endDate.getDate()}/${cubeSelection.endDate.getFullYear()}`);
    return this.sintenoHttpService.get<CubeDetailedWithEvaluation[]>(url, options);
  }
  public getStatisticsForBehavior(cubeId: string, chapterId: string, behaviorId: string, startDate: Date, endDate: Date)
    : Observable<BehaviorStatistic> {
    let url = `${this.entityUrl}GetStatisticsForBehavior/`;

    url = url.concat('?cubeId=' + cubeId);
    url = url.concat('&chapterId=' + chapterId);
    url = url.concat('&behaviorId=' + behaviorId);
    url = url.concat('&' + generateDateParam(startDate, endDate));

    return this.sintenoHttpService.get<BehaviorStatistic>(url);
  }

  public getCurrentUserTrainingStatistics(): Observable<TrainingStatistics> {
    return this.sintenoHttpService.get<TrainingStatistics>(`${this.entityUrl}currentuser/training`);
  }
}
