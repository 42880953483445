import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouterState } from '@angular/router';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { TitleState } from '@shared/store/title/title.state';
import { Observable, Subject, Subscription } from 'rxjs';
import { SetTitleAction } from '@shared/store/title/title.action';
import { UserSessionModel } from '@shared/store/session/session.model';
import { SessionState } from '@shared/store/session/session.state';
import { UserRoles } from '@shared/utils/user-roles';
import { AuthenticationService } from '@shared/services/authentication.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit, OnDestroy {
  private routerSubscription = Subscription.EMPTY;
  @Select(TitleState.title) public title$: Observable<string>;
  @Select(TitleState.canGoBack) public canGoBack$: Observable<boolean>;
  @Select(SessionState.currentSession) public currentUser$: Observable<UserSessionModel>;

  public printingAllowed = false;
  public userRoles = UserRoles;
  private destroyed$ = new Subject<boolean>();
  public userIsAdmin = true;

  public constructor(private router: Router, private activatedRoute: ActivatedRoute, private store: Store, private authenticationService: AuthenticationService) { }

  public ngOnInit(): void {
    this.routerSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route = this.activatedRoute;

        while (route.firstChild) {
          route = route.firstChild;
        }

        return route;
      }),
      mergeMap((route) => route.data)
    ).subscribe((data) => {
      const action = new SetTitleAction(data['title']);
      if (data['allowPrinting'] && data['allowPrinting'] === true) {
        this.printingAllowed = true;
      } else {
        this.printingAllowed = false;
      }
      this.store.dispatch(action);
    });

    this.authenticationService.userHasRoles([this.userRoles.ADMINISTRATOR, this.userRoles.HierarchyPartialAdministrator]).pipe(map(hasRoles => this.userIsAdmin = hasRoles),
      takeUntil(this.destroyed$)).subscribe();
  }

  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  public print(): void {
    window.print();
  }
}

let flag = 'hide';
window.addEventListener('scroll', (e) => {
  const menuHeight = 80;
  if (document.querySelector('.printBtn')) {
    const btn = document.querySelector('.printBtnFixed');
    const scrollY = window.scrollY;
    if (scrollY > menuHeight) {
      if (flag === 'hide') {
        btn.classList.remove('hide');
        btn.classList.add('show');
        flag = 'show';
      }
    } else {
      if (flag === 'show') {
        btn.classList.add('hide');
        btn.classList.remove('show');
        flag = 'hide';
      }
    }
  }
});

