import { AuthenticationModule } from './authentication/authentication.module';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { throwIfAlreadyLoaded } from '@shared/utils/utils';
import { HomeComponent } from '@core/components/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { TitleComponent } from './components/title/title.component';
import { BackButtonComponent } from './components/title/back-button/back-button.component';

@NgModule({
  imports: [
    SharedModule,
    AuthenticationModule
  ],
  declarations: [HomeComponent, MenuComponent, TitleComponent, BackButtonComponent],
  exports: [
    HomeComponent,
    MenuComponent,
    TitleComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
