import {
  GetAllCompanies,
  AddCompany,
  UpdateCompany,
  DeleteCompany,
  AddContactPerson,
  UpdateContactPerson,
  DeleteContactPerson,
  GetCompany,
  GetCompanyEmployees,
  GetCompanyHistogramData,
  GetCompanyEnrichedCubes,
  GetCompanyWorkerStatistics,
  AddCompanyLogo,
  RemoveCompanyAdministrator,
  GetCompanyTheoryHistogramData,
  GetCompanyEnrichedCubesWithTheory,
  GetCompanyNodes,
  GetExtendedProperties
} from './company.action';
import {
  State,
  StateContext,
  Action,
  Selector,
  createSelector,
  Store
} from '@ngxs/store';
import { Company } from './company.model';
import { CompanyWorker } from './company-worker.model';
import { HistogramData, EnrichedCubeModel } from '../audit/audit.model';
import { AuditService } from '@shared/services/audit.service';
import { CompanyService } from '@shared/services/company.service';
import { map } from 'rxjs/operators';
import { GlobalWorkerStatistics } from '../worker/global-worker-statistics.model';
import { HierarchicalSimpleNode } from '@models/hierarchical-line/hierarchical-node';
import { DetailedExtendedProperty } from '@models/extended-property/extended-property';
import { ExtendedPropertyService } from '@shared/services/extended-property.service';

export interface CompanyStateModel {
  company: Company;
  companyEmployees: CompanyWorker[];
  allCompanies: Company[];
  companyHistogram: HistogramData;
  companyEnrichedCubes: EnrichedCubeModel[];
  companyStatistics: GlobalWorkerStatistics;
  companyNodes: HierarchicalSimpleNode[];
  extendedProperties: DetailedExtendedProperty[];
  companyTheoryHistrogram: HistogramData;
  companyEnrichedCubesWithTheory: EnrichedCubeModel[];
}

@State<CompanyStateModel>({
  name: 'company',
  defaults: {
    company: null,
    companyEmployees: [],
    allCompanies: [],
    companyHistogram: null,
    companyEnrichedCubes: [],
    companyStatistics: null,
    companyNodes: [],
    extendedProperties: [],
    companyTheoryHistrogram: null,
    companyEnrichedCubesWithTheory: []
  }
})
export class CompanyState {
  @Selector()
  public static company(companyStateModel: CompanyStateModel) {
    return companyStateModel.company;
  }
  @Selector()
  public static companyEmployees(companyStateModel: CompanyStateModel) {
    return companyStateModel.companyEmployees;
  }

  @Selector()
  public static companyHistogramData(stateModel: CompanyStateModel): HistogramData {
    return stateModel.companyHistogram;
  }

  @Selector()
  public static companyEnrichedCubes(stateModel: CompanyStateModel): EnrichedCubeModel[] {
    return stateModel.companyEnrichedCubes;
  }

  public static getCompany(id: string) {
    return createSelector([CompanyState], (state: CompanyStateModel) => state.allCompanies.find(c => c.id === id));
  }

  @Selector()
  public static allCompanies(companyStateModel: CompanyStateModel) {
    return companyStateModel.allCompanies;
  }

  @Selector()
  public static companyStatistics(companyStateModel: CompanyStateModel) {
    return companyStateModel.companyStatistics;
  }

  @Selector()
  public static companyNodes(companyStateModel: CompanyStateModel) {
    return companyStateModel.companyNodes;
  }

  @Selector()
  public static extendedProperties(companyStateModel: CompanyStateModel) {
    return companyStateModel.extendedProperties;
  }

  @Selector()
  public static companyTheoryHistrogram(companyStateModel: CompanyStateModel) {
    return companyStateModel.companyTheoryHistrogram;
  }

  @Selector()
  public static companyEnrichedCubesWithTheory(companyStateModel: CompanyStateModel) {
    return companyStateModel.companyEnrichedCubesWithTheory;
  }

  public constructor( private companyService: CompanyService, private auditService: AuditService, private store: Store,
                      private extendedPropertyService: ExtendedPropertyService) { }

  @Action(GetCompany)
  public getCompany(ctx: StateContext<CompanyStateModel>, action: GetCompany): void {
    this.companyService.getCompany(action.companyId)
      .subscribe(response => ctx.patchState({ company: response }));
  }

  @Action(GetCompanyEmployees)
  public getCompanyEmployees(ctx: StateContext<CompanyStateModel>, action: GetCompanyEmployees): void {
    this.companyService.getCompanyEmployees(action.companyId)
      .subscribe(response => ctx.patchState({ companyEmployees: response }));
  }

  @Action(GetAllCompanies)
  public getAllCompanies(ctx: StateContext<CompanyStateModel>, action: GetAllCompanies): void {
    this.companyService.getCompanies()
      .subscribe(response => ctx.patchState({ allCompanies: response }));
  }

  @Action(GetCompanyHistogramData)
  public getCompanyHistogramData(ctx: StateContext<CompanyStateModel>, action: GetCompanyHistogramData): void {
    this.companyService.getHistogramData(action.startDate, action.endDate, action.companyId)
      .subscribe(response => ctx.patchState({ companyHistogram: response }));
  }

  @Action(GetCompanyEnrichedCubes)
  public getCompanyEnrichedCubes(ctx: StateContext<CompanyStateModel>, action: GetCompanyEnrichedCubes): void {
    this.auditService.getCompanyEnrichedCubes(action.companyId, action.startDate, action.endDate)
      .subscribe((response) => ctx.patchState({ companyEnrichedCubes: response }));
  }

  @Action(GetCompanyWorkerStatistics)
  public getCompanyWorkerStatistics(ctx: StateContext<CompanyStateModel>, action: GetCompanyWorkerStatistics): void {
    this.companyService.getCompanyWorkerStatistics(action.companyId)
      .subscribe((statistics) => ctx.patchState({ companyStatistics: statistics }));
  }

  @Action(GetCompanyNodes)
  public getCompanyNodes(ctx: StateContext<CompanyStateModel>, action: GetCompanyNodes): void {
    this.companyService.getCompanyNodes(action.workerId)
      .subscribe((nodes) => ctx.patchState({ companyNodes: nodes }));
  }

  @Action(GetExtendedProperties)
  public GetExtendedProperties(ctx: StateContext<CompanyStateModel>, action: GetCompanyNodes): void {
    this.extendedPropertyService.getExtendedProperties()
      .subscribe((properties) => ctx.patchState({ extendedProperties: properties }));
  }

  @Action(AddCompany)
  public addCompany(ctx: StateContext<CompanyStateModel>, action: AddCompany): void {
    this.companyService.addCompany(action.company).pipe(
      map(company => {
        if (action.file) {
          this.store.dispatch(new AddCompanyLogo(company.id, action.file));
        } else {
          this.store.dispatch(new GetAllCompanies());
        }
      })
    ).subscribe();
  }

  @Action(UpdateCompany)
  public updateCompany(ctx: StateContext<CompanyStateModel>, action: UpdateCompany): void {
    this.companyService.updateCompany(action.company)
      .subscribe(() => this.getAllCompanies(ctx, new GetAllCompanies()));
  }

  @Action(DeleteCompany)
  public deleteCompany(ctx: StateContext<CompanyStateModel>, action: DeleteCompany): void {
    this.companyService.deleteCompany(action.company.id)
      .subscribe(() => this.getAllCompanies(ctx, new GetAllCompanies()));
  }

  @Action(AddContactPerson)
  public addContactPerson(ctx: StateContext<CompanyStateModel>, action: AddContactPerson): void {
    this.companyService.addContactPersonToCompany(action.company, action.contactPerson)
      .subscribe(() => this.getAllCompanies(ctx, new GetAllCompanies()));
  }

  @Action(UpdateContactPerson)
  public updateContactPerson(ctx: StateContext<CompanyStateModel>, action: UpdateContactPerson): void {
    this.companyService.updateContactPersonToCompany(action.company, action.contactPerson)
      .subscribe(() => this.getAllCompanies(ctx, new GetAllCompanies()));
  }

  @Action(DeleteContactPerson)
  public deleteContactPerson(ctx: StateContext<CompanyStateModel>, action: DeleteContactPerson): void {
    this.companyService.deleteContactPersonToCompany(action.company, action.contactPerson)
      .subscribe(() => this.getAllCompanies(ctx, new GetAllCompanies()));
  }

  @Action(AddCompanyLogo)
  public addCompanyLogo(ctx: StateContext<CompanyStateModel>, action: AddCompanyLogo): void {
    this.companyService.addImage(action.file, action.companyId)
      .subscribe(() => this.getAllCompanies(ctx, new GetAllCompanies()));
  }

  @Action(RemoveCompanyAdministrator)
  public removeCompanyAdministrator(ctx: StateContext<CompanyStateModel>, action: RemoveCompanyAdministrator): void {
    this.companyService.removeAdministrator(action.companyId, action.workerId)
      .subscribe(() => this.getAllCompanies(ctx, new GetAllCompanies()));
  }

  @Action(GetCompanyTheoryHistogramData)
  public getCompanyTheoryHistogramData(ctx: StateContext<CompanyStateModel>, action: GetCompanyTheoryHistogramData): void {
    this.companyService.getTheoryHistogramData(action.companyId, action.startDate, action.endDate)
      .subscribe(response => ctx.patchState({ companyTheoryHistrogram: response }));
  }

  @Action(GetCompanyEnrichedCubesWithTheory)
  public getCompanyEnrichedCubesWithTheory(ctx: StateContext<CompanyStateModel>, action: GetCompanyEnrichedCubesWithTheory): void {
    this.companyService.getCompanyEnrichedCubesWithTheory(action.companyId, action.startDate, action.endDate)
      .subscribe((response) => ctx.patchState({ companyEnrichedCubesWithTheory: response }));
  }
}
