export class GetWorkerCubeSessions {
    public static readonly type = '[FormationSession] Get worker cube sessions';

    public constructor(public workerId: string) { }
}

export class GetFormationSessionStatistics {
  public static readonly type = '[FormationSession] Get statistics';

  public constructor(public workerId: string) { }
}

export class GetFormationSessionStatisticsDetails {
  public static readonly type = '[FormationSession] Get statistics details';

  public constructor(public workerId: string) { }
}

