import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { generateSpace } from '@shared/utils/utils';
import { ExtendedProperty, DetailedExtendedProperty } from '@models/extended-property/extended-property';
import { DetailedExtendedPropertyValue, FlatExtendedPropertyValue } from '@models/extended-property/extended-property-value';
import { NodePropertySelection, NodeProperty } from '@models/hierarchical-line/node-property';
import { SelectableExtendedProperty, SelectableExtendedPropertyValue } from '@models/extended-property/selectable-extended-property';

@Injectable({
  providedIn: 'root'
})
export class ExtendedPropertyService {
  private entityUrl = 'extendedproperties/';

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public getExtendedProperties(): Observable<DetailedExtendedProperty[]> {
    return this.sintenoHttpService.get<DetailedExtendedProperty[]>(this.entityUrl);
  }

  public addExtendedProperty(extendedProperty: ExtendedProperty): Observable<ExtendedProperty> {
    return this.sintenoHttpService.post<ExtendedProperty>(this.entityUrl, extendedProperty);
  }

  public updateExtendedProperty(extendedProperty: ExtendedProperty): Observable<ExtendedProperty> {
    return this.sintenoHttpService.put<ExtendedProperty>(this.entityUrl + extendedProperty.id, extendedProperty);
  }

  public generateNodePropertySelection(extendedProperties: DetailedExtendedProperty[], nodeProperties?: NodeProperty[])
    : NodePropertySelection[] {

    const nodePropertySelectionList = new Array<NodePropertySelection>();
    extendedProperties?.forEach(extendedProperty => {
      const newExtendedProperty = new NodePropertySelection();
      newExtendedProperty.extendedPropertyValues = [];
      newExtendedProperty.extendedProperty = extendedProperty;
      newExtendedProperty.availableExtendedPropertyValues =
        this.getAvailableExtendedPropertyValues(extendedProperty.extendedPropertyValues, 0);
        // populate fields if nodeProperty of current extendedProperty exists
        if (nodeProperties?.length > 0) {
          const selectedValuesForProperty = nodeProperties.filter(x => x.extendedProperty.id === extendedProperty.id);
          if (selectedValuesForProperty.length > 0) {
            newExtendedProperty.id = selectedValuesForProperty[0].id;
            newExtendedProperty.extendedPropertyValues = selectedValuesForProperty[0].extendedPropertyValues;
          }
        }

      nodePropertySelectionList.push(newExtendedProperty);
    });
    return nodePropertySelectionList;
  }

  private getAvailableExtendedPropertyValues(values: Array<DetailedExtendedPropertyValue>, depth: number): FlatExtendedPropertyValue[] {
    let valueList = new Array<FlatExtendedPropertyValue>();

    values?.forEach(value => {
      const newValue = new FlatExtendedPropertyValue();
      newValue.id = value.id;
      newValue.name = generateSpace(depth) + value.name;
      valueList.push(newValue);

      if (value.values != null && value.values.length > 0) {
        valueList = valueList.concat(this.getAvailableExtendedPropertyValues(value.values, depth + 1));
      }
    });

    return valueList;
  }

  public generateSelectableExtendedProperties(extendedProperties: DetailedExtendedProperty[]): SelectableExtendedProperty[] {
    const selectableProperties = [];
    extendedProperties.forEach(extendedProperty => {
      const selectableProperty = new SelectableExtendedProperty();
      selectableProperty.id = extendedProperty.id;
      selectableProperty.name = extendedProperty.name;
      selectableProperty.technicalName = extendedProperty.technicalName;
      selectableProperty.allowMultipleSelect = extendedProperty.allowMultipleSelect;
      selectableProperty.extendedPropertyValues = [];
      extendedProperty.extendedPropertyValues.forEach(extendedPropertyValue => {
        selectableProperty.extendedPropertyValues.push(this.generateSelectableExtendedPropertyValue(extendedPropertyValue));
      });
      selectableProperties.push(selectableProperty);
    });
    return selectableProperties;
  }

  public generateSelectableExtendedPropertyValue(extendedPropertyValue: DetailedExtendedPropertyValue): SelectableExtendedPropertyValue {
    const selectableProperty = new SelectableExtendedPropertyValue();
    selectableProperty.id = extendedPropertyValue.id;
    selectableProperty.name = extendedPropertyValue.name;
    selectableProperty.selected = false;
    selectableProperty.values = [];
    extendedPropertyValue.values.forEach(x => {
      selectableProperty.values.push(this.generateSelectableExtendedPropertyValue(x));
    });
    return selectableProperty;
  }
}
