import { Question } from './question.model';
import { Criteria } from './criteria.model';
export class Behaviour {
  public id: string;
  public name: string;
  public content: string;
  public criteria: Criteria;
  public order: number;
  public hasCriteria: boolean;
  public numberOfQuestions: number;
  public hasDocumentation: boolean;
  public numberOfPropositions: number;
  public numberOfPendingPropositions: number;
  public auditableStatus: string;
  public status: number;
  public statusLocked: boolean;
  public questions: Array<Question>;
  public lectureTimeEstimation: number;
}

export function createBehaviour(init?: Partial<Behaviour>): Behaviour {
  const behaviour = {
    id: undefined,
    name: undefined,
    content: undefined,
    criteria: undefined,
    order: undefined,
    hasCriteria: undefined,
    numberOfQuestions: undefined,
    hasDocumentation: undefined,
    numberOfPropositions: 0,
    numberOfPendingPropositions: 0,
    auditableStatus: undefined,
    status: 0,
    statusLocked: undefined,
    questions: [],
    lectureTimeEstimation: undefined
  };
  Object.assign(behaviour, init);
  return behaviour;
}

export class SelectableBehaviour {
  public id: string;
  public name: string;
  public selected: boolean;
}
