import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { UserLocale } from '@models/user/user-locale';

@Injectable({
  providedIn: 'root'
})
export class ContextService {
  public websiteLocale: UserLocale;

  constructor() {
    this.websiteLocale = this.getWebsiteLocale();
  }

  private getWebsiteLocale(): UserLocale {
    const languageFromUrl = `${window.location.pathname.slice(0, 3)}`;
    const localeMappings = environment.localesUrlPathMappings;
    const mappedUserLocale = Object.keys(localeMappings).find(key => localeMappings[key] === languageFromUrl) as UserLocale;
    if (!mappedUserLocale) {
      return 'en-US';
    }
    return mappedUserLocale;
  }
}
