import { FormationChapter } from './../../models/formation/formation-chapter.model';
import { QuestionPresentation, ResultFormationAudit } from '@shared/store/cube/models/question-presentation.model';
import { AuditService } from '@shared/services/audit.service';
import { Formation, FormationPresentation, FormationStatisticByWorker, FormationStatistics } from '../../models/formation/formation.model';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
// tslint:disable-next-line:max-line-length
import { GetWorkerFormations, AddWorkerFormation, GetFormationDetail, CloseFormationDetail, ChangeFormationChapterLecture, DeleteWorkerFormation, GetWorkerFormationsStatistics, SetFormationLanguage, GetQuestionForSelectedChapter, AnswerQuestion, GoBackToFormationChapter, GoNextQuestion, GetAllWorkersFormationStatistics, SelectCover, CloseCover, SeedFormation, GetCurrentUserTrainingStatistics, GetFormationStatistics } from './formation.action';
import { tap } from 'rxjs/operators';
import { FormationService } from '@shared/services/formation.service';
import * as _ from 'lodash';
import { SetTitleAction } from '../title/title.action';
import { FormationChapterStatus } from '@models/formation/formation-chapter-status.enum';
import { StatisticService } from '@shared/services/statistic.service';
import { FormationChapterPresentation } from '@models/formation/formation-chapter-presentation.model';
import { SeedingService } from '@shared/services/seeding.service';
import { GetWorkersFormations, GetWorkersSessions } from '../worker/worker.action';
import { TrainingStatistics } from '../audit/training-statistics.model';

export interface FormationStateModel {
    formations: Formation[];
    formationsStatistics: FormationStatistics[];
    allWorkersFormationStatistics: FormationStatisticByWorker[];
    selectedFormation: FormationPresentation;
    questionToAnswer: QuestionPresentation;
    questionResult: ResultFormationAudit;
    selectedFormationChapter: FormationChapter;
    isCoverSelected: boolean;
    currentUserTrainingStatistics: TrainingStatistics;
    formationStatistics: FormationStatistics;
}

@State<FormationStateModel>({
    name: 'formation',
    defaults: {
        formations: [],
        formationsStatistics: [],
        selectedFormation: null,
        questionToAnswer: null,
        selectedFormationChapter: null,
        questionResult: null,
        allWorkersFormationStatistics: [],
        isCoverSelected: null,
        currentUserTrainingStatistics: null,
        formationStatistics: null
    }
})
export class FormationState {


    @Selector()
    public static allWorkersFormationStatistics(formationStateModel: FormationStateModel) {
        return formationStateModel.allWorkersFormationStatistics;
    }


    @Selector()
    public static formations(formationStateModel: FormationStateModel) {
        return formationStateModel.formations;
    }


    @Selector()
    public static selectedFormationChapter(formationStateModel: FormationStateModel) {
        return formationStateModel.selectedFormationChapter;
    }

    @Selector()
    public static isCoverSelected(formationStateModel: FormationStateModel) {
        return formationStateModel.isCoverSelected;
    }


    @Selector()
    public static questionToAnswer(formationStateModel: FormationStateModel) {
        return formationStateModel.questionToAnswer;
    }

    @Selector()
    public static questionResult(formationStateModel: FormationStateModel) {
        return formationStateModel.questionResult;
    }

    @Selector()
    public static formationsStatistics(formationStateModel: FormationStateModel) {
        return formationStateModel.formationsStatistics;
    }

    @Selector()
    public static selectedFormation(formationStateModel: FormationStateModel) {
        return formationStateModel.selectedFormation;
    }

    @Selector()
    public static currentUserTrainingStatistics(formationStateModel: FormationStateModel) {
        return formationStateModel.currentUserTrainingStatistics;
    }

    @Selector()
    public static formationStatistics(formationStateModel: FormationStateModel) {
        return formationStateModel.formationStatistics;
    }

    public constructor(private store: Store, private statisticService: StatisticService,
        private formationService: FormationService, private auditService: AuditService, private seedingService: SeedingService) { }

    @Action(GetFormationDetail)
    public getFormationDetail(ctx: StateContext<FormationStateModel>, action: GetFormationDetail): void {
        this.formationService.getWorkerFormationPresentation(action.workerId, action.formationId, action.languageCode)
            .subscribe((formation) => {
                ctx.patchState({ selectedFormation: formation, selectedFormationChapter: undefined});

                let selectedChapter: FormationChapterPresentation = null;
                const lectureAlreadyStarted = formation.chapters.find(
                  chapter => chapter.status !== FormationChapterStatus.Unread) != null ? true : false;
                if (lectureAlreadyStarted) {
                  selectedChapter = formation.chapters.find(
                      chapter => chapter.status !== FormationChapterStatus.Read
                  );
                  if (selectedChapter) {
                    ctx.dispatch(
                        new ChangeFormationChapterLecture(action.workerId,
                            formation.id, action.languageCode, undefined, selectedChapter.id, false, false)
                    );
                  }
                } else if (!formation.publishingNote) {
                  this.store.dispatch(new SelectCover());
                } else if (formation.chapters.length > 0) {
                  ctx.dispatch(
                    new ChangeFormationChapterLecture(action.workerId,
                        formation.id, action.languageCode, undefined, formation.chapters[0].id, false, false)
                  );
                }

                if (formation.publishingNote) {
                    this.store.dispatch(new SetTitleAction('Session de formation: ' + formation.cube.name));
                } else {
                    this.store.dispatch(new SetTitleAction('Formation ' + formation.cube.name));
                }
            });
    }

    @Action(CloseFormationDetail)
    public closeFormationDetail(ctx: StateContext<FormationStateModel>, action: CloseFormationDetail): void {
        ctx.patchState({
            selectedFormation: null,
            questionToAnswer: null,
            selectedFormationChapter: null,
            questionResult: null,
            isCoverSelected: false
        });
    }

    @Action(GetWorkerFormations)
    public getWorkerFormations(ctx: StateContext<FormationStateModel>, action: GetWorkerFormations): void {
        this.formationService.getWorkerFormations(action.workerId)
            .subscribe((response) => ctx.patchState({ formations: response }));
    }

    @Action(GetWorkerFormationsStatistics)
    public getWorkerFormationsStatistics(ctx: StateContext<FormationStateModel>, action: GetWorkerFormationsStatistics): void {
        this.formationService.getWorkerFormationsStatistics(action.workerId)
            .subscribe((response) => ctx.patchState({ formationsStatistics: response }));
    }

    @Action(AddWorkerFormation)
    public addWorkerFormation(ctx: StateContext<FormationStateModel>, action: AddWorkerFormation): void {
        this.formationService.addWorkerFormation(action.workerId, action.cubeId).pipe(
            tap(() => {
                const getFormations = new GetWorkerFormations(action.workerId);
                this.store.dispatch(getFormations);
            })
        ).subscribe();
    }

    @Action(ChangeFormationChapterLecture)
    public changeFormationChapterLecture(ctx: StateContext<FormationStateModel>, action: ChangeFormationChapterLecture): void {
        this.formationService.changeFormationChapterLecture(action.workerId, action.formationId, action.previousChapterId,
            action.currentChapterId, action.isFinished)
            .pipe(
                tap(updatedChapter => {
                  const state = ctx.getState();
                  const chapters = _.cloneDeep(state.selectedFormation.chapters) as FormationChapterPresentation[];
                  if (action.previousChapterId) {
                    const previousChapter = chapters.find(x => x.id === action.previousChapterId);
                    previousChapter.status = action.isFinished ? FormationChapterStatus.Read : FormationChapterStatus.Presented;
                  }

                  if (action.currentChapterId) {
                    const selectedChapter = chapters.find(chapter => chapter.id === action.currentChapterId);
                    updatedChapter.status = selectedChapter.status;
                    selectedChapter.status = FormationChapterStatus.Presented;
                    updatedChapter.numbering = selectedChapter.numbering;
                  }

                  ctx.patchState({ selectedFormation: {
                    ...state.selectedFormation,
                    chapters: chapters
                    },
                    selectedFormationChapter: updatedChapter,
                    isCoverSelected: updatedChapter == null && action.selectCover});
                })
            ).subscribe();
    }

    @Action(DeleteWorkerFormation)
    public deleteWorkerFormation(ctx: StateContext<FormationStateModel>, action: DeleteWorkerFormation): void {
        this.formationService.deleteWorkerFormation(action.workerId, action.formationId).pipe(
            tap(() => {
                const getFormations = new GetWorkerFormations(action.workerId);
                this.store.dispatch(getFormations);
            })
        ).subscribe();
    }



    @Action(GetAllWorkersFormationStatistics)
    public getAllWorkersFormationStatistics(ctx: StateContext<FormationStateModel>, action: GetAllWorkersFormationStatistics): void {

        this.statisticService.getWorkerFormationsStatistics().subscribe(
            result => {
                ctx.patchState({ allWorkersFormationStatistics: result });
            });
    }


    @Action(SetFormationLanguage)
    public setCubeFormationsLanguage(ctx: StateContext<FormationStateModel>, action: SetFormationLanguage): void {
        this.formationService.setFormationLanguage(action.workerId, action.formationId, action.languageId).subscribe();
    }

    @Action(AnswerQuestion)
    public answerQuestion(ctx: StateContext<FormationStateModel>, action: AnswerQuestion): void {


        this.auditService.createAuditFromFormation(action.questionAnswered).subscribe(
            result => {

                const cloneSelectedChapter = _.cloneDeep(ctx.getState().selectedFormationChapter) as FormationChapter;
                if (result.isCorrect) {

                    cloneSelectedChapter.formationBehavioursToHighLightId = null;

                    const listQuestion = this.getQuestionFromFormationChapter(cloneSelectedChapter);

                    const questionSelected = listQuestion.find(q => q.formationQuestionId === result.formationQuestionId);
                    questionSelected.isCompleted = true;


                    ctx.patchState({ questionResult: result, selectedFormationChapter: cloneSelectedChapter });

                } else {
                    // false so return to formation
                    cloneSelectedChapter.formationBehavioursToHighLightId = action.questionAnswered.formationBehaviourId;
                    ctx.patchState({ questionResult: result, selectedFormationChapter: cloneSelectedChapter });
                }
            }
        );

    }


    @Action(GoBackToFormationChapter)
    public goBackToChapter(ctx: StateContext<FormationStateModel>, action: GoBackToFormationChapter): void {

        ctx.patchState({ questionToAnswer: null, questionResult: null });
    }

    @Action(GoNextQuestion)
    public goNextQuestion(ctx: StateContext<FormationStateModel>, action: GoNextQuestion): void {

        const selectedChapter = ctx.getState().selectedFormationChapter;

        const listQuestion = this.getQuestionFromFormationChapter(selectedChapter);

        const firstUnCorrect = listQuestion.find(q => q.isCompleted === false);

        if (firstUnCorrect === undefined) {
            // return to formation
            ctx.patchState({ questionToAnswer: null, questionResult: null });
        } else {
            ctx.patchState({ questionToAnswer: firstUnCorrect, questionResult: null });
        }

    }

    @Action(GetQuestionForSelectedChapter)
    public getQuestionForSelectedChapter(ctx: StateContext<FormationStateModel>, action: GetQuestionForSelectedChapter): void {


        const cloneSelectedChapter = _.cloneDeep(ctx.getState().selectedFormationChapter) as FormationChapter;

        const questionSelected = this.getQuestionFromFormationChapter(cloneSelectedChapter).find(q => q.isCompleted === false);

        ctx.patchState({ questionToAnswer: questionSelected });

    }

    @Action(SelectCover)
    public selectCover(ctx: StateContext<FormationStateModel>, action: SelectCover): void {
      ctx.patchState({ selectedFormationChapter: null,
        isCoverSelected: true});
    }

    @Action(CloseCover)
    public closeCover(ctx: StateContext<FormationStateModel>, action: CloseCover): void {
      ctx.patchState({ selectedFormationChapter: null,
        isCoverSelected: false});
    }

    @Action(SeedFormation)
    public seedFormation(ctx: StateContext<FormationStateModel>, action: SeedFormation): void {
        this.seedingService.seedFormation(action.id, action.percentage)
          .pipe(tap(() => {
            this.store.dispatch(new GetWorkersFormations());
            this.store.dispatch(new GetWorkersSessions());
          })).subscribe();
    }

    @Action(GetCurrentUserTrainingStatistics)
    public getCurrentUserTrainingStatistics(ctx: StateContext<FormationStateModel>, action: GetCurrentUserTrainingStatistics): void {
        this.statisticService.getCurrentUserTrainingStatistics()
          .pipe(tap(x => ctx.patchState({currentUserTrainingStatistics: x}))).subscribe();
    }

    private getQuestionFromFormationChapter(formationChapter: FormationChapter): Array<QuestionPresentation> {
        const questions = new Array<QuestionPresentation>();
        formationChapter.formationBehaviours.forEach(formationBehaviour => {
            formationBehaviour.questions.forEach(question => {
                questions.push(question);
            });
        });

        return questions;
    }

    @Action(GetFormationStatistics)
    public getFormationStatistics(ctx: StateContext<FormationStateModel>, action: GetFormationStatistics): void {
        this.formationService.getFormationStatistics(action.workerId, action.formationId)
            .subscribe((formation) => ctx.patchState({ formationStatistics: formation }));
    }
}
