import { FormGroup, ValidationErrors } from '@angular/forms';
import { Behaviour, SelectableBehaviour } from '@shared/store/cube/models/behaviour.model';
import { Chapter, SelectableChapter } from '@shared/store/cube/models/chapter.model';
import { Constants } from './constants-file';

export function isNullOrWhiteSpace(s: string): boolean {
    return s == null || s.trim() === '';
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string): void {
    if (parentModule) {
        throw new Error(`${moduleName} has already been loaded.`);
    }
}

export function padNumber(value: number): string {
  if (isNumber(value)) {
      return `0${value}`.slice(-2);
  } else {
      return '';
  }
}

export function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function isDate (value: any): boolean {
  return value instanceof Date;
}

export function isNullOrEmptyGuid(s: string): boolean {
  return s == null || s === Constants.GUID_EMPTY;
}

export function generateSpace(nbSpace: number): string {
  let stringWithSpace = '';
  for (let index = 0; index < nbSpace; index++) {
    stringWithSpace += '  '; // alt255 caracter in the string
  }
  return stringWithSpace;
}

export function scrollToTop(): void {
  window.scrollTo(0, 0);
}

export function generateDateParam(startDate: Date, endDate: Date): string {
  return 'startDate=' + (startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear() +
    '&endDate=' + (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear();
}

export function toSelectableChapter(chapter: Chapter, behaviour?: Behaviour): SelectableChapter {
  const selectableChapter: SelectableChapter = {
    id: chapter.id,
    name: chapter.name,
    numbering: chapter.numbering,
    selected: true,
    chapters: [],
    behaviours: []
  }
  if (!behaviour) {
    chapter.chapters.forEach(c => {
      selectableChapter.chapters.push(toSelectableChapter(c));
    });
    chapter.behaviours.forEach(b => {
      selectableChapter.behaviours.push(toSelectableBehaviour(b));
    });
    return selectableChapter;
  }
  selectableChapter.behaviours.push(toSelectableBehaviour(behaviour));
  return selectableChapter;
}

export function toSelectableBehaviour(behaviour: Behaviour): SelectableBehaviour {
  const selectableBehaviour: SelectableBehaviour = {
    id: behaviour.id,
    name: behaviour.name,
    selected: true
  };
  return selectableBehaviour;
}

const isFieldEmpty = (fieldName: string, fg: FormGroup) => {
  const field = fg.get(fieldName).value;
  if (typeof field === 'number') { return field && field >= 0 ? true : false; }
  if (typeof field === 'string') { return field && field.length > 0 ? true : false; }
};

export function atLeastOne(...fields: string[]) {
return (fg: FormGroup): ValidationErrors | null => {
  return fields.some(fieldName => isFieldEmpty(fieldName, fg))
    ? null
    : ({ atLeastOne: 'At least one field has to be provided.' } as ValidationErrors);
  };
}
