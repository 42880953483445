import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { GetFormationSessionStatistics, GetFormationSessionStatisticsDetails } from './formation-session.action';
import { FormationSessionService } from '@shared/services/formation-session.service';
import * as _ from 'lodash';
import { FormationStatistics } from '@models/formation/formation.model';
import { FormationSessionStatistics } from '@models/formation/formation-session-statistics.model';


export interface FormationSessionsStateModel {
  formationSessionStatistics: FormationSessionStatistics[];
  formationSessionStatisticsDetails: FormationStatistics[];
}

@State<FormationSessionsStateModel>({
  name: 'formationsession',
  defaults: {
    formationSessionStatistics: [],
    formationSessionStatisticsDetails: []
  }
})
export class FormationSessionsState {

  @Selector()
  public static formationSessionStatistics(formationSessionsStateModel: FormationSessionsStateModel) {
    return formationSessionsStateModel.formationSessionStatistics;
  }

  @Selector()
  public static formationSessionStatisticsDetails(formationSessionsStateModel: FormationSessionsStateModel) {
    return formationSessionsStateModel.formationSessionStatisticsDetails;
  }

  public constructor(private formationSessionService: FormationSessionService, private store: Store) { }

  @Action(GetFormationSessionStatistics)
  public getFormationSessionStatistics(ctx: StateContext<FormationSessionsStateModel>,
    action: GetFormationSessionStatistics): void {
    this.formationSessionService.getCubeFormationSessionStatistics(action.workerId)
      .subscribe((response) => ctx.patchState({ formationSessionStatistics: response }));
  }

  @Action(GetFormationSessionStatisticsDetails)
  public getFormationSessionStatisticsDetails(ctx: StateContext<FormationSessionsStateModel>,
    action: GetFormationSessionStatisticsDetails): void {
    this.formationSessionService.getCubeFormationSessionStatisticsDetails(action.workerId)
      .subscribe((response) => ctx.patchState({ formationSessionStatisticsDetails: response }));
  }
}
