import { Chapter } from './chapter.model';

export class TableOfContent {
  public id: string;
  public chapters: Chapter[];
}

export function createTableOfContent(init?: Partial<TableOfContent>): TableOfContent {
  const criteria = {
    id: undefined,
    chapters: undefined
  };
  Object.assign(criteria, init);
  return criteria;
}
