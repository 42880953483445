import { Constants } from '@shared/utils/constants-file';
export class Question {
    public id: string;
    public name: string;
    public title: string;
    public content: string;
    public order: number;
    public active: boolean;
    public fatalFlaw: boolean;
    public oblige: boolean;
    public answers: Array<MCQAnswer>;
}




export class MCQAnswer {
    public id: string;
    public order: number;
    public content: string;
    public isCorrect: boolean;
    public points: number;
}


export class TestMCQAnswer {
    public isSelected: boolean;
    public id: string;
    public order: number;
    public content: string;
    public isCorrect: boolean;
    public originIsCorrect: boolean;
    public points: number;
}


export function createMCQAnswer(init?: Partial<MCQAnswer>): MCQAnswer {
    const obj = {
        id: Constants.GUID_EMPTY,
        order: 0,
        content: '',
        isCorrect: false,
        points: 0
    };
    Object.assign(obj, init);
    return obj;
}

export function createQuestion(init?: Partial<Question>): Question {
    const question = {
        id: Constants.GUID_EMPTY,
        title: undefined,
        name: undefined,
        label: undefined,
        text: undefined,
        content: undefined,
        order: 0,
        active: false,
        fatalFlaw: false,
        oblige: false,
        answers: []
    };
    Object.assign(question, init);
    return question;
}

