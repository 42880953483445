export class Constants {
  public static readonly GUID_EMPTY = '00000000-0000-0000-0000-000000000000';
  public static readonly DATE_FORMAT = 'dd/MM/yyyy';
  public static readonly LOCALE_STRING = 'fr-be';
  public static readonly COLOR_GREEN = '#63D677';
  public static readonly COLOR_RED = '#EA5729';
  public static readonly COLOR_GREY = '#95989A';
  public static readonly COLOR_YELLOW = '#EAAA15';
  public static readonly COLOR_LIGHT_BLUE = '#4ECDC4';
  public static readonly COLOR_BLUE = '#4777FC';
}
