import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@shared/services/translation.service';

@Pipe({
  name: 'propositionStatus'
})
export class PropositionStatusPipe implements PipeTransform {
  constructor(private translationService: TranslationService) {
  }
  transform(value: number): string {

    switch (value) {
      case 0: return this.translationService.translate('Pending');
      case 1: return this.translationService.translate('Accepted');
      case 2: return this.translationService.translate('Rejected');
      default: return this.translationService.translate('NoMappingFoundForId') + ' ' + value;
    }
  }
}
