import { Injectable } from '@angular/core';
import { Worker, DetailedWorker, MinimalWorker, WorkerFormations, WorkerParent } from '../store/worker/worker.model';
import { Media } from '../../admin/media/models/media';
import { Observable } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { GlobalWorkerStatistics } from '@shared/store/worker/global-worker-statistics.model';
import { PropositionModelDetail } from '@models/cube/proposition';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  private entityUrl = 'workers/';

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public getWorkers(): Observable<MinimalWorker[]> {
    return this.sintenoHttpService.get<MinimalWorker[]>(this.entityUrl);
  }

  public getWorkersDetails(): Observable<DetailedWorker[]> {
    return this.sintenoHttpService.get<DetailedWorker[]>(this.entityUrl + 'details/');
  }

  public getWorkerDetails(id: string): Observable<DetailedWorker> {
    return this.sintenoHttpService.get<DetailedWorker>(this.entityUrl + id + '/details/');
  }

  public getWorkerPropositions(id: string): Observable<PropositionModelDetail[]> {
    return this.sintenoHttpService.get<PropositionModelDetail[]>(this.entityUrl + id + '/propositions/');
  }

  public addImage(file: File, workerId: string): Observable<Media> {
    if (file) {
      const formData = new FormData();
      formData.append(file.name, file);
      return this.sintenoHttpService.post<Media>(this.entityUrl + workerId + '/image', formData, { reportProgress: true });
    } else {
      return new Observable();
    }
  }

  public disableEnableWorker(id: string): Observable<Worker> {
    return this.sintenoHttpService.put<Worker>(this.entityUrl + id + '/disable', null);
  }

  public getGlobalWorkerStatistics(): Observable<GlobalWorkerStatistics> {
    return this.sintenoHttpService.get<GlobalWorkerStatistics>(`${this.entityUrl}statistics`);
  }

  public getWorkersFormations(): Observable<Array<WorkerFormations>> {
    return this.sintenoHttpService.get<Array<WorkerFormations>>(`${this.entityUrl}formations`);
  }

  public getWorkersSessions(): Observable<Array<WorkerFormations>> {
    return this.sintenoHttpService.get<Array<WorkerFormations>>(`${this.entityUrl}sessions`);
  }

  public getParentsById(parentId: string, companyId): Observable<Array<WorkerParent>> {
    return this.sintenoHttpService.get<Array<WorkerParent>>(this.entityUrl + companyId + '/parents/' + parentId);
  }
}
