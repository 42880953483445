import { TheoreticalEvaluationService } from './../../services/theoreticalEvaluation.service';
import { StatisticService } from '@shared/services/statistic.service';
import {
    HistogramData, EnrichedCubeModel, AuditModel,
    BehaviorStatistic, InlineAuditModel, ResultTestQuestionViewModel, InlineTheoreticalEvaluationModel, HistogramWithTheoryModel, EvolutionData
} from './audit.model';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { AuditService } from '@shared/services/audit.service';
import {
    GetPersonalAuditHistogramData, GetCubesEnrichedWithAudits, GetPersonalAuditData, GetAuditData, GetCubeWithEvaluation,
    GetBehaviorStatistics, CloseBehaviorStatistics,
    GetPersonalTheoreticalHistogramData, GetCubesEnrichedWithTheoreticalEvaluations,
    GetCubesEnrichedWithTheoreticalEvaluationsPerPublishingNote, GetTheoryEvaluationData, GetPersonalTheoreticalHistogramPerFormation,
    GetCubesEnrichedWithTheoreticalEvaluationsPerFormation, GetPersonalTheoryDataPerFormation, GetTheoreticalHistogramPerPublishingNote,
    GetTheoryDataPerPublishingNote,
    FilterBehaviours,
    EmptyCubeWithEvaluation,
    SetIsCubesWithNodes,
    GetCubesEnrichedWithAuditsForAuditor,
    GetAuditorData,
    GetAuditorHistogramData
} from './audit.action';
import { CubeWithNodeEvaluations } from '../cube/models/table-of-content-node.model';
import * as _ from 'lodash';


export interface AuditStateModel {
    personalAuditHistogram: HistogramData;
    personalTheroryHistogram: HistogramWithTheoryModel;
    personalTheroryHistogramPerFormation: HistogramWithTheoryModel;
    cubesEnrichedWithAudits: EnrichedCubeModel[];
    cubesEnrichedForAuditor: EnrichedCubeModel[];
    cubesEnrichedWithTheoreticalEvaluations: EnrichedCubeModel[];
    cubeEnrichedWithTheoreticalEvaluationsPerFormation: EnrichedCubeModel[];
    personalAudits: InlineAuditModel[];
    personalTheoreticalEvaluationsPerFormation: InlineTheoreticalEvaluationModel[];
    audit: AuditModel;
    theoreticalEvaluationDetail: ResultTestQuestionViewModel;
    cubesWithEvaluations: CubeWithNodeEvaluations[];
    behaviorStatistic: BehaviorStatistic;
    theoreticalHistogramPerPublishingNote: HistogramWithTheoryModel;
    cubeEnrichedWithTheoreticalEvaluationsPerPublishingNote: EnrichedCubeModel[];
    theoreticalEvaluationsPerPublishingNote: InlineTheoreticalEvaluationModel[];
    isCubesWithNodes: boolean;

    auditorHistogramData: EvolutionData;
    auditorAudits: InlineAuditModel[];

}

@State<AuditStateModel>({
    name: 'audit',
    defaults: {
        personalAuditHistogram: null,
        personalTheroryHistogram: null,
        personalTheroryHistogramPerFormation: null,
        cubesEnrichedWithAudits: [],
        cubesEnrichedForAuditor: [],
        cubesEnrichedWithTheoreticalEvaluations: [],
        cubeEnrichedWithTheoreticalEvaluationsPerFormation: [],
        personalAudits: [],
        personalTheoreticalEvaluationsPerFormation: [],
        audit: null,
        theoreticalEvaluationDetail: null,
        cubesWithEvaluations: null,
        behaviorStatistic: null,
        theoreticalHistogramPerPublishingNote: null,
        cubeEnrichedWithTheoreticalEvaluationsPerPublishingNote: null,
        theoreticalEvaluationsPerPublishingNote: null,
        isCubesWithNodes: null,
        auditorHistogramData: null,
        auditorAudits : []
    }
})
export class AuditState {
    @Selector()
    public static currentBehaviorStatistic(stateModel: AuditStateModel): BehaviorStatistic {
        return stateModel.behaviorStatistic;
    }

    @Selector()
    public static personalAuditHistogram(stateModel: AuditStateModel): HistogramData {
        return stateModel.personalAuditHistogram;
    }

    @Selector()
    public static personalTheroryHistogram(stateModel: AuditStateModel): HistogramWithTheoryModel {
        return stateModel.personalTheroryHistogram;
    }

    @Selector()
    public static personalTheroryHistogramPerFormation(stateModel: AuditStateModel): HistogramWithTheoryModel {
        return stateModel.personalTheroryHistogramPerFormation;
    }

    @Selector()
    public static cubesWithEvaluations(stateModel: AuditStateModel): CubeWithNodeEvaluations[] {
        return stateModel.cubesWithEvaluations;
    }

    @Selector()
    public static isCubesWithNodes(stateModel: AuditStateModel): boolean {
        return stateModel.isCubesWithNodes;
    }


    // @Selector()
    // static filteredBehaviours(stateModel: AuditStateModel) {
    //   return (searchString: string) => {
    //     const table = stateModel.flatBehavioursEvaluations.filter(c => c.name.toLowerCase().includes(searchString.toLowerCase()));
    //     return table;
    //   };
    // }


    @Selector()
    public static cubesEnrichedWithAudits(stateModel: AuditStateModel): EnrichedCubeModel[] {
        return stateModel.cubesEnrichedWithAudits;
    }

    @Selector()
    public static cubesEnrichedForAuditor(stateModel: AuditStateModel): EnrichedCubeModel[] {
        return stateModel.cubesEnrichedForAuditor;
    }

    @Selector()
    public static cubesEnrichedWithTheoreticalEvaluations(stateModel: AuditStateModel): EnrichedCubeModel[] {
        return stateModel.cubesEnrichedWithTheoreticalEvaluations;
    }

    @Selector()
    public static cubeEnrichedWithTheoreticalEvaluationsPerFormation(stateModel: AuditStateModel): EnrichedCubeModel[] {
        return stateModel.cubeEnrichedWithTheoreticalEvaluationsPerFormation;
    }


    @Selector()
    public static personalAudits(stateModel: AuditStateModel): InlineAuditModel[] {
        return stateModel.personalAudits;
    }

    @Selector()
    public static auditorAudits(stateModel: AuditStateModel): InlineAuditModel[] {
        return stateModel.auditorAudits;
    }



    @Selector()
    public static personalTheoreticalEvaluationsPerFormation(stateModel: AuditStateModel): InlineTheoreticalEvaluationModel[] {
        return stateModel.personalTheoreticalEvaluationsPerFormation;
    }

    @Selector()
    public static audit(stateModel: AuditStateModel): AuditModel {
        return stateModel.audit;
    }


    @Selector()
    public static theoreticalEvaluationDetail(stateModel: AuditStateModel): ResultTestQuestionViewModel {
        return stateModel.theoreticalEvaluationDetail;
    }

    @Selector()
    public static theoreticalHistogramPerPublishingNote(stateModel: AuditStateModel): HistogramWithTheoryModel {
        return stateModel.theoreticalHistogramPerPublishingNote;
    }

    @Selector()
    public static cubeEnrichedWithTheoreticalEvaluationsPerPublishingNote(stateModel: AuditStateModel): EnrichedCubeModel[] {
        return stateModel.cubeEnrichedWithTheoreticalEvaluationsPerPublishingNote;
    }

    @Selector()
    public static theoreticalEvaluationsPerPublishingNote(stateModel: AuditStateModel): InlineTheoreticalEvaluationModel[] {
        return stateModel.theoreticalEvaluationsPerPublishingNote;
    }

    @Selector()
    public static auditorHistogramData(stateModel: AuditStateModel): EvolutionData {
        return stateModel.auditorHistogramData;
    }

    public constructor(private auditService: AuditService, private theoreticalEvaluationService: TheoreticalEvaluationService,
        private statisticService: StatisticService) { }

    @Action(GetPersonalAuditHistogramData)
    public getPersonalHistogramData(ctx: StateContext<AuditStateModel>, action: GetPersonalAuditHistogramData): void {
        this.auditService.getHistogramData(action.startDate, action.endDate, action.workerId, action.includeSubAssignedWorker)
            .subscribe((response) => {
                ctx.patchState({ personalAuditHistogram: response });
            });
    }

    @Action(GetPersonalTheoreticalHistogramData)
    public getPersonalTheoreticalHistogramData(ctx: StateContext<AuditStateModel>, action: GetPersonalTheoreticalHistogramData): void {
        this.theoreticalEvaluationService
            .getHistogramData(action.startDate, action.endDate, action.workerId, action.includeSubAssignedWorker)
            .subscribe((response) => {
                ctx.patchState({ personalTheroryHistogram: response });
            });
    }

    @Action(GetPersonalTheoreticalHistogramPerFormation)
    public getPersonalTheoreticalHistogramPerFormation(ctx: StateContext<AuditStateModel>,
      action: GetPersonalTheoreticalHistogramPerFormation): void {
        this.theoreticalEvaluationService
            .getHistogramPerFormation(action.workerId, action.formationId, action.showSessionData)
            .subscribe((response) => {
                ctx.patchState({ personalTheroryHistogramPerFormation: response });
            });
    }

    @Action(GetPersonalAuditData)
    public getPersonalAuditData(ctx: StateContext<AuditStateModel>, action: GetPersonalAuditData): void {
        this.auditService.getAuditForWorker(action.workerId, action.startDate, action.endDate, action.includeSubAssignedWorker)
            .subscribe((response) => {
                ctx.patchState({ personalAudits: response });
            });
    }

    @Action(GetAuditorData)
    public getAuditorData(ctx: StateContext<AuditStateModel>, action: GetAuditorData): void {
        this.auditService.getAuditForAuditor(action.workerId, action.startDate, action.endDate)
            .subscribe((response) => {
                ctx.patchState({ auditorAudits: response });
            });
    }


    @Action(GetPersonalTheoryDataPerFormation)
    public getPersonalTheoryDataPerFormation(ctx: StateContext<AuditStateModel>, action: GetPersonalTheoryDataPerFormation): void {
        this.theoreticalEvaluationService
            .getAuditForWorkerPerFormation(action.workerId, action.formationId, action.showSessionData)
            .subscribe((response) => {
                ctx.patchState({ personalTheoreticalEvaluationsPerFormation: response });
            });
    }

    @Action(GetCubesEnrichedWithAudits)
    public getPersonalCubeDetails(ctx: StateContext<AuditStateModel>, action: GetCubesEnrichedWithAudits): void {
        this.auditService.getEnrichedChaptersByWorker(action.workerId, action.startDate, action.endDate, action.includeSubAssignedWorker)
            .subscribe((response) => {
                ctx.patchState({ cubesEnrichedWithAudits: response });
            });
    }

    @Action(GetCubesEnrichedWithAuditsForAuditor)
    public getCubesEnrichedWithAuditsForAuditor(ctx: StateContext<AuditStateModel>, action: GetCubesEnrichedWithAudits): void {
        this.auditService.getEnrichedChaptersForAuditor(action.workerId, action.startDate, action.endDate)
            .subscribe((response) => {
                ctx.patchState({ cubesEnrichedForAuditor: response });
            });
    }



    @Action(GetCubesEnrichedWithTheoreticalEvaluations)
    // tslint:disable-next-line:max-line-length
    public getCubesEnrichedWithTheoreticalEvaluations(ctx: StateContext<AuditStateModel>, action: GetCubesEnrichedWithTheoreticalEvaluations): void {
        this.theoreticalEvaluationService
            .getEnrichedChaptersByWorker(action.workerId, action.startDate, action.endDate, action.includeSubAssignedWorker)
            .subscribe((response) => {
                ctx.patchState({ cubesEnrichedWithTheoreticalEvaluations: response });
            });
    }

    @Action(GetCubesEnrichedWithTheoreticalEvaluationsPerFormation)
    // tslint:disable-next-line:max-line-length
    public getCubeEnrichedWithTheoreticalEvaluationsPerFormation(ctx: StateContext<AuditStateModel>, action: GetCubesEnrichedWithTheoreticalEvaluationsPerFormation): void {
        this.theoreticalEvaluationService
            .getEnrichedChaptersByWorkerPerFormation(action.workerId, action.formationId, action.showSessionData)
            .subscribe((response) => {
              const cubeList = [];
              cubeList.push(response);
              ctx.patchState({ cubeEnrichedWithTheoreticalEvaluationsPerFormation: cubeList });
            });
    }

    @Action(GetAuditData)
    public getAudit(ctx: StateContext<AuditStateModel>, action: GetAuditData): void {
        this.auditService.getAudit(action.auditId)
            .subscribe((response) => {
                ctx.patchState({ audit: response });
            });
    }


    @Action(GetTheoryEvaluationData)
    public getTheoryEvaluationData(ctx: StateContext<AuditStateModel>, action: GetTheoryEvaluationData): void {
        this.theoreticalEvaluationService.getAudit(action.theoryEvaluationId)
            .subscribe((response) => {
                ctx.patchState({ theoreticalEvaluationDetail: response });
            });
    }



    @Action(GetBehaviorStatistics)
    public getBehaviorStatistics(ctx: StateContext<AuditStateModel>, action: GetBehaviorStatistics): void {
        this.statisticService.getStatisticsForBehavior(action.cubeId, action.chapterId, action.behaviorId, action.startDate, action.endDate)
            .subscribe((response) => {

                ctx.patchState({ behaviorStatistic: response });
            });
    }

    @Action(GetCubeWithEvaluation)
    public getCubeWithEvaluation(ctx: StateContext<AuditStateModel>, action: GetCubeWithEvaluation): void {

        this.statisticService.getCubesWithEvaluations(action.cubeSelection)
            .subscribe((cubes) => {
              const cubesWithNodes: CubeWithNodeEvaluations[] = [];
              cubes.forEach(cube => {
                cubesWithNodes.push(CubeWithNodeEvaluations.generateCubeWithEvaluations(cube));
              });
              ctx.patchState({ cubesWithEvaluations: cubesWithNodes });
              ctx.patchState({ isCubesWithNodes : cubes?.length > 0});
            });
    }


    @Action(CloseBehaviorStatistics)
    public closeBehaviorStatistics(ctx: StateContext<AuditStateModel>, action: CloseBehaviorStatistics): void {

        ctx.patchState({ behaviorStatistic: null });
    }

    @Action(GetTheoreticalHistogramPerPublishingNote)
    public getTheoreticalHistogramPerPublishingNote(ctx: StateContext<AuditStateModel>,
        action: GetTheoreticalHistogramPerPublishingNote): void {
        this.theoreticalEvaluationService
            .getTheoreticalHistogramPerPublishingNote(action.publishingNoteId)
            .subscribe((response) => {
                ctx.patchState({ theoreticalHistogramPerPublishingNote: response });
            });
    }

    @Action(GetCubesEnrichedWithTheoreticalEvaluationsPerPublishingNote)
    // tslint:disable-next-line:max-line-length
    public getCubesEnrichedWithTheoreticalEvaluationsPerPublishingNote(ctx: StateContext<AuditStateModel>, action: GetCubesEnrichedWithTheoreticalEvaluationsPerPublishingNote): void {
        this.theoreticalEvaluationService
            .getEnrichedChaptersPerPublishingNote(action.publishingNoteId)
            .subscribe((response) => {
              const cubeList = [];
              cubeList.push(response);
              ctx.patchState({ cubeEnrichedWithTheoreticalEvaluationsPerPublishingNote: cubeList });
            });
    }

    @Action(GetTheoryDataPerPublishingNote)
    public getTheoreticalEvaluationsPerPublishingNote(ctx: StateContext<AuditStateModel>, action: GetTheoryDataPerPublishingNote): void {
        this.theoreticalEvaluationService
            .getAuditPerPublishingNote(action.publishingNoteId)
            .subscribe((response) => {
                ctx.patchState({ theoreticalEvaluationsPerPublishingNote: response });
            });
    }

    @Action(FilterBehaviours)
    public filterBehaviours(ctx: StateContext<AuditStateModel>, action: FilterBehaviours): void {
      const cubes: CubeWithNodeEvaluations[] = _.cloneDeep(ctx.getState().cubesWithEvaluations);
      if (cubes) {
        cubes.forEach(cube => {
          if ((action.keyword && action.keyword !== '') ||
            action.minQuestion !== 0 || action.maxQuestion !== 5 || action.minAudit !== 0 || action.maxAudit !== 5) {
            let listToApplyFiltersOn = cube.flatBehaviours;
            if (action.keyword && action.keyword !== '') {
              cube.displayableNodes = listToApplyFiltersOn.filter(c => c.name.toLowerCase().includes(action.keyword.toLowerCase()));
              listToApplyFiltersOn = cube.displayableNodes;
            }
            if (action.minQuestion !== 0 || action.maxQuestion !== 5 || action.minAudit !== 0 || action.maxAudit !== 5) {
              cube.displayableNodes = listToApplyFiltersOn.filter(x => x.theoryticalQuestionAverageScore >= action.minQuestion
                && x.theoryticalQuestionAverageScore <= action.maxQuestion
                && x.auditAverageScore >= action.minAudit && x.auditAverageScore <= action.maxAudit);
            }
          } else {
            cube.displayableNodes = cube.nodes;
          }
        });
        const cubeList = cubes.filter(cube => cube.displayableNodes?.length > 0);
        ctx.patchState({isCubesWithNodes: cubeList?.length > 0});
        ctx.patchState({ cubesWithEvaluations: cubes });
      }
    }

    @Action(EmptyCubeWithEvaluation)
    public emptyCubeWithEvaluation(ctx: StateContext<AuditStateModel>, action: EmptyCubeWithEvaluation): void {
      ctx.patchState({ cubesWithEvaluations: [] });
    }

    @Action(SetIsCubesWithNodes)
    public setCubesWithNodes(ctx: StateContext<AuditStateModel>, action: SetIsCubesWithNodes): void {
        ctx.patchState({ isCubesWithNodes: action.value });
    }

    @Action(GetAuditorHistogramData)
    public getAuditorHistogramData(ctx: StateContext<AuditStateModel>, action: GetAuditorHistogramData): void {
        this.auditService.getAuditorHistogramData(action.startDate, action.endDate, action.workerId)
            .subscribe((response) => {
                ctx.patchState({ auditorHistogramData: response });
            });
    }
}
