import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {
  public confirmTitle: string;
  public confirmMessage: string;
  public btnClass: string;
  public activeModal: NgbActiveModal;

  public constructor(activeModal: NgbActiveModal) {
    this.activeModal = activeModal;
  }
}
