import { DetailedExtendedPropertyValue } from './extended-property-value';

export class ExtendedProperty {
  public id: string;
  public technicalName: string;
  public name: string;
  public allowMultipleSelect: boolean;
}

export class DetailedExtendedProperty extends ExtendedProperty {
  public extendedPropertyValues: DetailedExtendedPropertyValue[];
}
