import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import * as _ from 'lodash';
import { ExtendedProperty, DetailedExtendedProperty } from '@models/extended-property/extended-property';
import { DetailedExtendedPropertyValue, ExtendedPropertyValue } from '@models/extended-property/extended-property-value';
// tslint:disable-next-line: max-line-length
import { GetExtendedProperties, AddExtendedProperty, UpdateExtendedProperty } from './extended-property.action';
import { ExtendedPropertyService } from '@shared/services/extended-property.service';

export interface ExtendedPropertyStateModel {
  extendedProperties: ExtendedProperty[];
  extendedProperty: DetailedExtendedProperty;
  extendedPropertyValues: DetailedExtendedPropertyValue[];
}

@State<ExtendedPropertyStateModel>({
    name: 'extendedProperty',
    defaults: {
      extendedProperties: [],
      extendedProperty: undefined,
      extendedPropertyValues: []
    }
})
export class ExtendedPropertyState {
    @Selector()
    public static extendedProperties(stateModel: ExtendedPropertyStateModel) {
        return stateModel.extendedProperties;
    }

    @Selector()
    public static extendedProperty(stateModel: ExtendedPropertyStateModel): ExtendedProperty {
        return stateModel.extendedProperty;
    }

    @Selector()
    public static extendedPropertyValues(stateModel: ExtendedPropertyStateModel): ExtendedPropertyValue[] {
        return stateModel.extendedPropertyValues;
    }


    public constructor(private extendedPropertyService: ExtendedPropertyService, private store: Store) { }

    @Action(GetExtendedProperties)
    public getExtendedProperties(ctx: StateContext<ExtendedPropertyStateModel>, action: GetExtendedProperties): void {
        this.extendedPropertyService.getExtendedProperties().subscribe((extendedProperties) => {
            ctx.patchState({ extendedProperties: extendedProperties });
        });
    }

    @Action(AddExtendedProperty)
    public addExtendedProperty(ctx: StateContext<ExtendedPropertyStateModel>, action: AddExtendedProperty): void {
        this.extendedPropertyService.addExtendedProperty(action.extendedProperty).
          subscribe(() => this.store.dispatch(new GetExtendedProperties()));
    }

    @Action(UpdateExtendedProperty)
    public updateExtendedProperty(ctx: StateContext<ExtendedPropertyStateModel>, action: UpdateExtendedProperty): void {
        this.extendedPropertyService.updateExtendedProperty(action.extendedProperty).
          subscribe(() => this.store.dispatch(new GetExtendedProperties()));
    }
}
