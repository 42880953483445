import { LoginModel } from './../../models/user';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy {
  private destroyed$ = new Subject<boolean>();
  public invalidLogin: boolean;
  public user: LoginModel;

  public constructor(private authenticationService: AuthenticationService, private store: Store, private router: Router) {
    this.user = new LoginModel();
  }

  public login(): void {
    this.invalidLogin = false;
    this.authenticationService.login(this.user).pipe(takeUntil(this.destroyed$)).subscribe();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
