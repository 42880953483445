import { Component, Input } from '@angular/core';
import { Cube } from '@shared/store/cube/models/cube.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-formation-add',
  templateUrl: './manage-formation-add.component.html'
})
export class ManageFormationAddComponent {
  @Input() public cubes: Cube[];

  public selectedCube: Cube;

  public constructor(private modal: NgbActiveModal) { }

  public selectCube(): void {
    this.modal.close(this.selectedCube);
  }

  public close(): void {
    this.modal.dismiss();
  }

  public compareCubes(c1: Cube, c2: Cube) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
}
