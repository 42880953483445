import { Directive, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { filter, first } from 'rxjs/operators';
import { TranslationService } from '@shared/services/translation.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[translate]'
})
export class TranslateDirective {
  @Input() set translate(value: string) {
    if (value) {
      this.translationKey = value;
      this.translateContent();
    }
  }

  private translationKey: string;

  constructor(private i18n: TranslationService, private elementRef: ElementRef, private cd: ChangeDetectorRef) { }

  private translateContent(): void {
    this.i18n.isInitialized$.pipe(
      filter(x => x),
      first()
    ).subscribe(x => {
      this.setContent(this.elementRef.nativeElement, this.i18n.translate(this.translationKey));
    });
  }

  private setContent(element: any, content: string): void {
    if (element.textContent != null) {
      element.textContent = content;
    } else {
      element.data = content;
    }
    this.cd.markForCheck();
  }
}
