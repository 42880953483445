import { QuestionPresentation, ResultFormationAudit } from '../store/cube/models/question-presentation.model';
import { AuditModel, EvolutionData, InlineAuditModel } from './../store/audit/audit.model';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { ContinuousData, HistogramData, EnrichedCubeModel } from '@shared/store/audit/audit.model';
import { AuditTile } from '@models/audit/audit';
import { Constants } from '@shared/utils/constants-file';
import { generateDateParam } from '@shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  private barData$: Subject<any> = new Subject();
  private tileData$: Subject<AuditTile[]> = new Subject();
  private entityUrl = 'audits/';
  private tileData: AuditTile[];
  private barData: any;

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public createAuditFromFormation(question: QuestionPresentation): Observable<ResultFormationAudit> {
    return this.sintenoHttpService.post<ResultFormationAudit>(this.entityUrl + 'CreateAuditFromFormation', question);
  }

  public refresh(workerId: string): void {
    this.barData = this.initBarData();
    this.tileData = [];

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    const currentDateMin15 = new Date(new Date().setMonth(currentDate.getMonth() - 15));
    const currentDateMin12 = new Date(new Date().setMonth(currentDate.getMonth() - 12));
    const currentDateMin9 = new Date(new Date().setMonth(currentDate.getMonth() - 9));
    const currentDateMin6 = new Date(new Date().setMonth(currentDate.getMonth() - 6));
    const currentDateMin3 = new Date(new Date().setMonth(currentDate.getMonth() - 3));

    forkJoin([
      this.getHistogramData(currentDateMin15, currentDateMin12, workerId, true),
      this.getHistogramData(currentDateMin12, currentDateMin9, workerId, true),
      this.getHistogramData(currentDateMin9, currentDateMin6, workerId, true),
      this.getHistogramData(currentDateMin6, currentDateMin3, workerId, true),
      this.getHistogramData(currentDateMin3, currentDate, workerId, true)
    ]).subscribe((result) => {
      this.mapHistogramData(result);
      this.barData$.next(this.barData);
      this.tileData$.next(this.enrichTileData(this.tileData));
    });
  }

  public getViewChart(): Observable<any> {
    return this.barData$.asObservable();
  }

  public getTileData(): Observable<AuditTile[]> {
    return this.tileData$.asObservable();
  }

  public getContinuousData(): Observable<ContinuousData> {
    return this.sintenoHttpService.get<ContinuousData>(this.entityUrl +
      'continuousdata/?startDate=01/01/2018&endDate=05/01/2019&rangeDate=Weekly');
  }

  public getHistogramData(startDate: Date, endDate: Date, workerId: string, includeSubAssignedWorker: boolean): Observable<HistogramData> {
    let url = this.entityUrl + 'histogram/';
    url = url.concat('?' + generateDateParam(startDate, endDate));
    if (workerId) {
      url = url.concat('&workerId=' + workerId);
    }
    url = url.concat('&includeSubAssignedWorker=' + includeSubAssignedWorker);


    return this.sintenoHttpService.get<HistogramData>(url);
  }

  public getEnrichedChaptersByWorker(workerId: string, startDate: Date, endDate: Date,
    includeSubAssignedWorker: boolean): Observable<EnrichedCubeModel[]> {
    let url = `${this.entityUrl}chapters/${workerId}`;
    url = url.concat('?' + generateDateParam(startDate, endDate));
    url = url.concat('&includeSubAssignedWorker=' + includeSubAssignedWorker);
    return this.sintenoHttpService.get<EnrichedCubeModel[]>(url);
  }


  public getAudit(auditId: string): Observable<AuditModel> {
    const url = `${this.entityUrl}${auditId}/`;
    return this.sintenoHttpService.get<AuditModel>(url);
  }

  public getAuditForWorker(workerId: string, startDate: Date, endDate: Date,
    includeSubAssignedWorker: boolean): Observable<InlineAuditModel[]> {
    let url = `${this.entityUrl}auditForWorker/${workerId}/`;

    url = url.concat('?' + generateDateParam(startDate, endDate));
    url = url.concat('&includeSubAssignedWorker=' + includeSubAssignedWorker);

    return this.sintenoHttpService.get<InlineAuditModel[]>(url);
  }

  public getCompanyEnrichedCubes(companyId: string, startDate: Date, endDate: Date): Observable<EnrichedCubeModel[]> {
    let url = `${this.entityUrl}EnrichedChaptersForCompany/${companyId}`;
    url = url.concat('?' + generateDateParam(startDate, endDate));
    return this.sintenoHttpService.get<EnrichedCubeModel[]>(url);
  }

  private mapHistogramData(result): void {
    result.forEach((data, index) => {
      let label = '';

      switch (index) {
        case 0: label = 'Jour J-12 mois'; break;
        case 1: label = 'Jour J-9 mois'; break;
        case 2: label = 'Jour J-6 mois'; break;
        case 3: label = 'Jour J-3 mois'; break;
        case 4: label = 'Jour J mois'; break;
      }

      this.updatePartNew(data, index);
      this.pushToTileData(index, label, data.average, 5, data.auditNumber);
    });
  }

  private updatePartNew(x: HistogramData, partIndex: number) {
    for (let index = 0; index < x.scores.length; index++) {
      this.barData.dataset.source[partIndex + 1][index + 1] = x.scores[index];
    }
  }

  private pushToTileData(order: number, label: string, average: number, maxAverage: number, auditNumber: number): any {
    const auditTile: AuditTile = {
      order: order,
      title: label,
      average: average,
      maximumAverage: maxAverage,
      auditNumber: auditNumber
    };

    this.tileData.push(auditTile);
  }

  private enrichTileData(tiles: AuditTile[]): AuditTile[] {
    const sortedTiles = tiles.sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
    for (let index = 0; index < sortedTiles.length; index++) {
      if (index === 0) {
        sortedTiles[index].delta = 0;
      } else {
        sortedTiles[index].delta = sortedTiles[index].average - sortedTiles[index - 1].average;
      }
    }
    return sortedTiles;
  }

  private initBarData(): any {
    return {
      auditTiles: {},
      legend: {},
      tooltip: {},
      dataset: {
        source: [
          ['Score', 'Score de 1', 'Score de 2', 'Score de 3', 'Score de 4', 'Score de 5'],
          ['Jour J-12 mois'],
          ['Jour J-9 mois'],
          ['Jour J-6 mois'],
          ['Jour J-3 mois'],
          ['Jour J']
        ]
      },
      xAxis: { type: 'category' },
      yAxis: {},
      series: [
        { type: 'bar', color: [Constants.COLOR_RED] },
        { type: 'bar', color: [Constants.COLOR_GREY] },
        { type: 'bar', color: [Constants.COLOR_YELLOW] },
        { type: 'bar', color: [Constants.COLOR_LIGHT_BLUE] },
        { type: 'bar', color: [Constants.COLOR_BLUE] }
      ]
    };
  }

  public getAuditorHistogramData(startDate: Date, endDate: Date, workerId: string):
   Observable<EvolutionData> {
    let url = this.entityUrl + 'ContinuousDataForAuditor/' + workerId + '/';
    url = url.concat('?' + generateDateParam(startDate, endDate));

    return this.sintenoHttpService.get<EvolutionData>(url);
  }

  public getAuditForAuditor(workerId: string, startDate: Date, endDate: Date): Observable<InlineAuditModel[]> {
    let url = `${this.entityUrl}AuditsForAuditor/${workerId}/`;

    url = url.concat('?' + generateDateParam(startDate, endDate));

    return this.sintenoHttpService.get<InlineAuditModel[]>(url);
  }

  public getEnrichedChaptersForAuditor(workerId: string, startDate: Date, endDate: Date): Observable<EnrichedCubeModel[]> {
    let url = `${this.entityUrl}EnrichedChaptersForAuditor/${workerId}`;
    url = url.concat('?' + generateDateParam(startDate, endDate));
    return this.sintenoHttpService.get<EnrichedCubeModel[]>(url);
  }
}
