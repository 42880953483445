<div class="card">
  <form #createPropositionForm="ngForm" ng-submit="addOrUpdateHierarchicalNode()">
    <div class="card-header">
      <div class="card-title">
        <h2 i18n="@@SuggestRuleUpdate">Suggest a rule update</h2>
      </div>
      <!--<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <fa-icon icon="times" size="sm" class="fa-fw"></fa-icon>
      </button>-->
    </div>
    <div class="card-body">
      <div>
        <p i18n="@@YouAreCreatingASuggestionForARuleUpdate">You are creating a suggestion for a rule update.</p>
      </div>
      <div class="form-group" *ngIf="behaviourList != null">
        <label for="behaviour" i18n="@@SelectABehaviourForWhichYouWantToSuggestARuleUpdate">Select the behavior for which you want to suggest a rule update.</label>
        <ng-select #behaviour="ngModel" name="behaviour" [items]="behaviourList" bindLabel="name" class="simple-select withPlaceholder" [multiple]="false" [clearable]="false" [searchable]="false"
          [(ngModel)]="selectedBehaviour" i18n-placeholder="@@SelectABehaviour" placeholder="Select a Behaviour" required>
        </ng-select>
        <div *ngIf="behaviour.invalid && (behaviour.dirty || behaviour.touched)" class="alert alert-danger">
          <div *ngIf="behaviour.hasError('required')" i18n="@@BehaviourRequired">Behaviour required</div>
        </div>
      </div>

      <div class="form-group" *ngIf="proposition">
        <label for="propositionText" i18n="@@EnterYourSuggestionHere">Enter your suggestion here</label>
        <textarea class="form-control" #propositionText="ngModel" name="propositionText" [(ngModel)]="proposition.text" i18n-placeholder="@@YourSuggestionHere" placeholder="Your suggestion here" required>
        </textarea>
        <div *ngIf="propositionText.invalid && (propositionText.dirty || propositionText.touched)" class="alert alert-danger">
          <div *ngIf="propositionText.hasError('required')" i18n="@@SuggestionRequired">Suggestion required</div>
        </div>
      </div>
    </div>
    <div class="card-footer text-center">
      <button type="submit" class="button saveButton" [disabled]="createPropositionForm.invalid"
        (click)="sendProposition()"><i class="fa fa-paper-plane"></i><ng-container i18n="@@Send">Send</ng-container>
      </button>
      <button type="button" class="button cancelButton" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <i class="fa fa-times"></i><ng-container i18n="@@Close">Close</ng-container>
      </button>
    </div>
  </form>
</div>
