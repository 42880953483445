import { Media } from '../../../admin/media/models/media';
import { Company } from '../company/company.model';
import { CubeAssignment } from './cube-assignment';
import { Language } from '../language/language-view-model';
import { Formation } from '@models/formation/formation.model';
import { NodeProperty } from '@models/hierarchical-line/node-property';

export class MinimalWorker {
  public id: string;
  public image: Media;
  public firstName: string;
  public lastName: string;
  public displayName: string;
}
export class Worker extends MinimalWorker {
  public birthDate: Date;
  public company?: Company;
  public parentNodeId?: string;
  public nodeProperties?: NodeProperty[];
  public isDisabled: boolean;
  public isCompanyAdministrator: boolean;
  public language: Language;
}

export function createWorker(init?: Partial<Worker>): Worker {
  const worker = {
    id: undefined,
    image: undefined,
    firstName: undefined,
    lastName: undefined,
    birthDate: undefined,
    company: undefined,
    parentNodeId: undefined,
    nodeProperties: undefined,
    displayName: undefined,
    isDisabled: undefined,
    isCompanyAdministrator: undefined,
    language: undefined
  };
  Object.assign(worker, init);
  return worker;
}

export class DetailedWorker extends Worker {
  public licenceList: Array<CubeAssignment>;
  public auditorRightList: Array<CubeAssignment>;
  public parents: Array<WorkerParent>;
}

export class WorkerFormations extends MinimalWorker {
  public formations: Array<Formation>;
}

export class SelectableWorker extends Worker {
  public selected: boolean;
}

export function toSelectableWorker(worker: Worker, selected: boolean): SelectableWorker {
  return {...worker, selected: selected};
}

export class WorkerParent {
  public parent: MinimalWorker;
  public order: number;
}
