import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditModel } from '../../store/audit/audit.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-audit-detail-modal',
  templateUrl: './audit-detail-modal.component.html',
  styleUrls: ['./audit-detail-modal.component.scss']
})
export class AuditDetailModalComponent {
  public constructor(private modal: NgbActiveModal) {}

  @Input() public audit: AuditModel;

  public close() {
    this.modal.close();
  }
}
