<div class="documentationContainer">
  <div class="card-body">

    <div class="row behaviourRow">
      <div class="col-sm-8 behaviourNameContainer">
        <h2><i class="fa fa-book"></i><span [innerHTML]="theoreticalEvaluationService.name | safehtmlpipe"></span></h2>
      </div>
      <div class="col-4">
        <p class="text-right expectedAnswer" i18n="@@score"> Score :
          {{theoreticalEvaluationService.theoryScore}} / {{theoreticalEvaluationService.theoryScoreMax}} </p>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <div class="fr-view" [innerHTML]="theoreticalEvaluationService.content | safehtmlpipe"></div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <div class="fr-view" [innerHTML]="theoreticalEvaluationService.title | safehtmlpipe"></div>
      </div>
    </div>

    <div *ngFor="let answer of theoreticalEvaluationService.answers; let i = index;">
      <div class="form-row row">
        <div class="col-sm-12 answerRow" [ngClass]="{'isCorrect': answer.originIsCorrect, 'isFalse': !answer.originIsCorrect}">
          <mat-checkbox [(ngModel)]="answer.isSelected" [disabled]="true" class="">
            <div class="fr-view" [innerHTML]="answer.content | safehtmlpipe"></div>
            <ng-container i18n="@@isSelect"></ng-container>
          </mat-checkbox>

          <div *ngIf="answer.originIsCorrect" class="iconCorrect"><i class="fa fa-check-square-o"></i></div>

          <!--isCorrect {{answer.isCorrect}}
          originIsCorrect {{answer.originIsCorrect}}-->
        </div>
      </div>
    </div>
  </div>

</div>
