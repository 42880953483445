import { TableOfContent, createTableOfContent } from './table-of-content.model';
import { AuditorAssignment, LicenceAssignment } from './assignment.model';
import { Worker } from '@shared/store/worker/worker.model';
import { Language } from '@shared/store/language/language-view-model';
import { Cover, createCover } from './cover.model';

export interface Cube {
  id?: string;
  name: string;
  description: string;
  creationDate?: Date;
  lastUpdateDate?: Date;
  primaryLanguage: Language;
  languages: Language[];
}
export function createCube(init?: Partial<Cube>): Cube {
  const cube = {
    id: undefined,
    name: undefined,
    description: undefined,
    creationDate: undefined,
    lastUpdateDate: undefined,
    primaryLanguage: undefined,
    languages: undefined
  };
  Object.assign(cube, init);
  return cube;
}

export interface CubeDetailed extends Cube {
  cover: Cover;
  tableOfContent: TableOfContent;
  auditors: AuditorAssignment[];
  licensedUsers: LicenceAssignment[];

}
export function createCubeDetailed(init?: Partial<CubeDetailed>): CubeDetailed {
  const cubeDetailed = {
    ...createCube(),
    cover: createCover(),
    tableOfContent: createTableOfContent(),
    auditors: undefined,
    licensedUsers: undefined
  };
  Object.assign(cubeDetailed, init);
  return cubeDetailed;
}

export interface CubeAuthorization extends Cube {
  editors: Worker[];
}
