import { AuditModel } from './../../store/audit/audit.model';
import { Component, OnInit, Input } from '@angular/core';
import { MediaType } from '@models/common/media-type.enum';

@Component({
  selector: 'app-audit-detail',
  templateUrl: './audit-detail.component.html',
  styleUrls: ['./audit-detail.component.scss']
})
export class AuditDetailComponent {
  @Input() public audit: AuditModel;
  public showPropositions = false;
  public MediaType = MediaType;
}
