import { Media } from './../../../admin/media/models/media';
import { Behaviour } from '@shared/store/cube/models/behaviour.model';
import { MinimalWorker, Worker } from '../worker/worker.model';
import { Cube } from '../cube/models/cube.model';
import { FlatChapter } from '../cube/models/chapter.model';
import { Criteria } from '../cube/models/criteria.model';
import { TranslatedBehaviour } from '../cube/models/translated-behaviour.model';
import { TestMCQAnswer } from '../cube/models/question.model';
import { PropositionModelDetail } from '@models/cube/proposition';
import { Cover } from '../cube/models/cover.model';
export class HistogramData {
  public label: string;
  public average: number;
  public auditNumber: number;
  public scores: number[];
}


export class HistogramWithTheoryModel extends HistogramData {
  public totalScore: number;
  public totalScoreMax: number;

}

export class ContinuousData {
  public histograms: HistogramData[];

  public getContinuousDataLabels(): string[] {
    return this.histograms.map(x => x.label);
  }

  public getBehaviourAverageList(): number[] {
    return this.histograms.map(x => x.average);
  }

  public getAuditNumber(): number[] {
    return this.histograms.map(x => x.auditNumber);
  }
}

export class EnrichedCubeModel {
  public constructor(
    public id: string,
    public name: string,
    public chapters: EnrichedChapterModel[],
    public average: number
  ) { }
}

export class EnrichedChapterModel {
  public constructor(
    public id: string,
    public name: string,
    public order: number,
    public numbering: string,
    public score: number,
    public numberOfAudits: number
  ) { }
}



export class ResultTestQuestionViewModel {
  public id: string;
  public auditionee: Worker;
  public behaviour: Behaviour;

  public score: number;
  public theoryScoreMax: number;
  public theoryScore: number;
  public date: Date;
  public content: string;
  public title: string;
  public name: string;
  public answers: TestMCQAnswer[];
}


export class AuditModel {
  public id: string;
  public auditor: Worker;
  public auditionee: Worker;
  public behaviour: Behaviour;
  public score: number;
  public date: Date;
  public validated: boolean;
  public auditioneeSignature: Media;
  public auditioneeComment: string;
  public auditorComment: string;
  public reasonRefusal: string;
  public medias: Array<Media>;
  public proposition: PropositionModel;
}

export class InlineAuditModel {
  public id: string;
  public behaviorName: string;
  public score: number;
  public date: Date;
  public validated: boolean;
  public hasMedia: boolean;
  public auditioneeDisplayName: string;
}

export class InlineTheoreticalEvaluationModel {
  public id: string;
  public behaviorName: string;
  public questionName: string;
  public score: number;
  public theoryScoreMax: number;
  public theoryScore: number;
  public date: Date;
  public auditioneeDisplayName: string;
}


// export function createInlineEvaluationModel(init?: Partial<InlineAuditModel>): InlineAuditModel {
//   const auditModel = {
//     id: undefined,
//     score: undefined,
//     date: undefined,
//     validated: undefined,
//     hasMedia: undefined,
//     auditioneeDisplayName: undefined
//   };
//   Object.assign(auditModel, init);

//   return auditModel;
// }


export class PropositionModel {
  public date: Date;
  public id: string;
  public worker: MinimalWorker;
  public status: number;
  public text: string;
  public comment: string;
}

export class SelectablePropositionModel extends PropositionModel {
  public isActive: boolean;

  constructor(proposition: PropositionModel) {
    super();
    this.id = proposition.id;
    this.comment = proposition.comment;
    this.date = proposition.date;
    this.status = proposition.status;
    this.text = proposition.text;
    this.worker = proposition.worker;
    this.isActive = false;
  }
}

export class UpdadtePropositionModel {

  public id: string;
  public status: number;

  public comment: string;


}


export class UpdateStatusPropositionModel {
  public id: string; // propositionId
  public comment: string;
  public status: number;
}

export function createPropositionModel(init?: Partial<PropositionModel>): PropositionModel {
  const proposition = {
    id: undefined,
    worker: undefined,
    date: undefined,
    text: undefined,
    status: undefined,
    comment: undefined
  };
  Object.assign(proposition, init);

  return proposition;
}
export function createAuditModel(init?: Partial<AuditModel>): AuditModel {
  const auditModel = {
    id: undefined,
    auditor: undefined,
    auditionee: undefined,
    behaviour: undefined,
    score: undefined,
    date: undefined,
    validated: undefined,
    auditioneeSignature: undefined,
    auditioneeComment: undefined,
    auditorComment: undefined,
    reasonRefusal: undefined,
    medias: undefined,
    proposition: undefined
  };
  Object.assign(auditModel, init);

  return auditModel;
}


export interface CubeDetailedWithEvaluation extends Cube {
  cover: Cover;
  tableOfContent: TableOfContentWithEvaluation;
  auditNumber: number;
  auditAverageScore: number;
  theoryticalQuestionNumber: number;
  theoryticalQuestionAverageScore: number;
}


export class TableOfContentWithEvaluation {
  public id: string;
  public chapters: ChapterWithEvaluation[];
}



export class BehaviorStatistic extends TranslatedBehaviour {
  public auditHistogram: HistogramData;
  public trainingTheoryHistogram: HistogramData;
  public trainingSessionTheoryHistogram: HistogramData;

  public auditCountEvolution: EvolutionData;
  public trainingTheoryEvolution: EvolutionData;
  public trainingSessionTheoryEvolution: EvolutionData;
  public lastUpdateDate: Date;
  public creationDate: Date;
  public nbChange: number;
  public nbPropositions: number;
  public nbPropositionsAccepted: number;
  public nbPropositionsPending: number;
  public nbPropositionsRejected: number;
  public behaviorSessionRead: number;
  public behaviorSessionTotal: number;
  public behaviorSessionUnread: number;
  public behaviorAuditSessionRead: number;
  public behaviorAuditSessionTotal: number;
  public behaviorAuditSessionUnread: number;
  public behaviorRead: number;
  public behaviorTotal: number;
  public behaviorUnread: number;
  public numberOfPublishingNotes: number;
  public lastPublishingNoteDate: Date;
  public lectureTimeEstimation: number;
}

export class EvolutionData {
  public xAxeValues: Date[];
  public yAxeValues: number[];
}



export class ChapterWithEvaluation extends FlatChapter {
  public numbering: string;
  public status: string;
  public chapters: ChapterWithEvaluation[];
  public behaviours: BehaviourWithEvaluationScore[];
  public statusLocked: boolean;
  public auditNumber: number;
  public auditAverageScore: number;
  public theoryticalQuestionNumber: number;
  public theoryticalQuestionAverageScore: number;
}

export class BehaviourWithEvaluationScore {
  public id: string;
  public title: string;
  public name: string;
  public content: string;
  public criteria: Criteria;
  public order: number;
  public auditNumber: number;
  public auditAverageScore: number;
  public theoryticalQuestionNumber: number;
  public theoryticalQuestionAverageScore: number;
  public status: number;
}



export class BehaviourDetailEvaluationScore {
  public id: string;
  public title: string;
  public name: string;
  public content: string;
  public criteria: Criteria;
  public order: number;
  public auditNumber: number;
  public auditAverageScore: number;
  public theoryticalQuestionAverageScore: number;
  //  public hasCriteria: boolean;
  // public hasQuestions: boolean;
  //  public hasDocumentation: boolean;
  // public numberOfPropositions: number;
  // public numberOfPendingPropositions: number;
  // public auditableStatus: string;
  public status: number;
  // public statusLocked: boolean;
  // public questions: Array<Question>;

}



