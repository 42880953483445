import { Component, OnInit, Input } from '@angular/core';
import { Cube } from '@shared/store/cube/models/cube.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CubeAssignment } from '@shared/store/worker/cube-assignment';

@Component({
  selector: 'app-manage-licences-add',
  templateUrl: './manage-licences-add.component.html'
})
export class ManageLicencesAddComponent implements OnInit {
  @Input() cubes: Cube[];

  public licenceForm: FormGroup;
  public minDate: Date;
  public maxDate: Date;
  public startDate: Date;
  public endDate: Date;

  public constructor(private modal: NgbActiveModal) { }

  get cube() { return this.licenceForm.get('cube'); }

  public ngOnInit(): void {
    this.licenceForm = new FormGroup({
      cube: new FormControl(null, [Validators.required])
    });

    const today = new Date();
    this.minDate = new Date(today);
    this.maxDate = new Date(today);
    this.minDate.setFullYear(today.getFullYear() - 1, 1);
    this.maxDate.setFullYear(today.getFullYear() + 10, 1);
    this.startDate = new Date(today);
    this.endDate = new Date(today);
  }

  public compareCubes(c1: Cube, c2: Cube) {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  public add(): void {
    const assignment = {
      cube: this.cube.value,
      startDate: this.startDate,
      endDate: this.endDate
    } as CubeAssignment;

    this.modal.close(assignment);
  }

  public close(): void {
    this.modal.dismiss();
  }
}
