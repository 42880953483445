import { CompanyState } from './company/company.state';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule, NoopNgxsExecutionStrategy } from '@ngxs/store';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { WorkerState } from './worker/worker.state';
import { SessionState } from './session/session.state';
import { environment } from '@environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AuditState } from './audit/audit.state';
import { CubeState } from './cube/cube.state';
import { TitleState } from './title/title.state';
import { FormationState } from './formation/formation.state';
import { FormationSessionsState } from './formation-session/formation-session.state';
import { PublishingNoteState } from './publishing-note/publishing-note.state';
import { StructuralNodeTypeState } from './structural-node-type/structural-node-type.state';
import { ExtendedPropertyState } from './extended-property/extended-property.state';
import { LanguageState } from './language/language.state';

const states = [
  WorkerState,
  SessionState,
  CompanyState,
  AuditState,
  CubeState,
  TitleState,
  FormationState,
  FormationSessionsState,
  PublishingNoteState,
  StructuralNodeTypeState,
  ExtendedPropertyState,
  LanguageState
];

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot(states, { executionStrategy: NoopNgxsExecutionStrategy, developmentMode: !environment.production }),
    NgxsDispatchPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
})
export class StoreModule { }
