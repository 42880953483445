import { Component, OnDestroy } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GetCurrentSession } from '@shared/store/session/session.action';
import { SessionState } from '@shared/store/session/session.state';
import { UserSessionModel } from '@shared/store/session/session.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { routerTransition } from '@shared/animations/router-transition.animation';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import { AddNavigationAction } from '@shared/store/title/title.action';
import { TranslationService } from '@shared/services/translation.service';
import { ContextService } from '@shared/services/context.service';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { AuthenticationService } from '@shared/services/authentication.service';
import { UserRoles } from '@shared/utils/user-roles';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routerTransition
  ]
})
export class AppComponent implements OnDestroy {
  @Select(SessionState.currentSession) public currentUser$: Observable<UserSessionModel>;
  private destroyed$ = new Subject();
  public userRoles = UserRoles;
  public menuIsVisible = false;

  public constructor(private router: Router, private store: Store, private i18nService: TranslationService,
    private ctxService: ContextService, private authenticationService: AuthenticationService) {
    this.currentUser$.pipe(
      tap(user => {
        let newLanguageRoute = '/';
        if (user && environment.production) {
          const languageRoute = user.associatedWorker?.language?.code;
          if (languageRoute && languageRoute !== 'en') {
            newLanguageRoute = '/' + languageRoute + '/';
          }
          const languageFromUrl = `${window.location.pathname.slice(0, 4)}`;
          if (languageFromUrl !== newLanguageRoute) {
            window.location.pathname = newLanguageRoute;
          }
        }
      }),
      takeUntil(this.destroyed$)
    ).subscribe();
    this.authenticationService.isAuthenticated$.pipe(
      takeUntil(this.destroyed$)
      , tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.router.navigateByUrl('/login');
        }
      })).subscribe();
    this.i18nService.initialize(this.ctxService.websiteLocale);
    this.store.dispatch(new GetCurrentSession());
    this.router.events.subscribe(async event => {
      if (event instanceof NavigationEnd) {
        event = event as NavigationEnd;
        const navAction = new AddNavigationAction(event.url);
        this.store.dispatch(navAction);
      }
    });

    combineLatest([this.authenticationService.userHasRoles([this.userRoles.ADMINISTRATOR, this.userRoles.HierarchyPartialAdministrator]),
    this.authenticationService.areTermsAccepted()])
      .pipe(tap(hasRoleAndTermsAccepted => this.menuIsVisible = hasRoleAndTermsAccepted[0] && hasRoleAndTermsAccepted[1]),
      takeUntil(this.destroyed$)).subscribe();
  }

  public getState(outlet: RouterOutlet): string {
    return outlet.activatedRouteData.state;
  }

  public toggleDropdown() {
    const dropdownContainer = document.querySelector('.profileDropdown');
    const dropdownElement = dropdownContainer.querySelector('.dropdown-menu');
    dropdownElement.classList.toggle('show');
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.unsubscribe();
  }
}

