import { StructuralNodeLabel } from './structural-node-label';
import { StructuralNodeType } from './structural-node-type';

export class SelectableStructuralNodeType extends StructuralNodeType {
  public labels: SelectableStructuralNodeLabel[];
}

export class SelectableStructuralNodeLabel extends StructuralNodeLabel {
  public labels: SelectableStructuralNodeLabel[];
  public selected: boolean;
}
