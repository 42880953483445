import { Worker } from '../../worker/worker.model';

export abstract class Assignment {
  public id: string;
  public worker: Worker;
  public startDate: Date;
  public endDate: Date;
}
export class AuditorAssignment extends Assignment { }

export class LicenceAssignment extends Assignment { }
