import { Component } from '@angular/core';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { SessionState } from '@shared/store/session/session.state';
import { Observable } from 'rxjs';
import { UserSessionModel } from '@shared/store/session/session.model';
import { UserRoles } from '@shared/utils/user-roles';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})

export class MenuComponent {
  @Select(SessionState.currentSession) public currentUser$: Observable<UserSessionModel>;
  public userRoles = UserRoles;
  public constructor(private authenticationService: AuthenticationService, private router: Router) {
  }
  public isCollapsed = true;

  public logout(): void {
    this.authenticationService.logout();
  }
}

