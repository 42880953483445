import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { CompanyWorker } from '@shared/store/company/company-worker.model';
import { EnrichedCubeModel, HistogramData } from '@shared/store/audit/audit.model';
import { Company, ContactPerson } from '@shared/store/company/company.model';
import { Media } from 'src/app/admin/media/models/media';
import { GlobalWorkerStatistics } from '@shared/store/worker/global-worker-statistics.model';
import { generateDateParam } from '@shared/utils/utils';
import { HierarchicalNode, HierarchicalSimpleNode } from '@models/hierarchical-line/hierarchical-node';
import { DetailedExtendedProperty } from '@models/extended-property/extended-property';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private entityUrl = 'companies/';

  public constructor(private sintenoHttpService: SintenoHttpService) {
  }

  public getCompanies(): Observable<Company[]> {
    return this.sintenoHttpService.get<Company[]>(this.entityUrl);
  }

  public getCompaniesWithoutHierarchicalLine(): Observable<Company[]> {
    return this.sintenoHttpService.get<Company[]>(this.entityUrl + 'noline');
  }

  public getCompany(id: string): Observable<Company> {
    return this.sintenoHttpService.get<Company>(this.entityUrl + id);
  }

  public getCompanyEmployees(id: string): Observable<CompanyWorker[]> {
    return this.sintenoHttpService.get<CompanyWorker[]>(this.entityUrl + id + '/workers');
  }

  public getHistogramData(startDate: Date, endDate: Date, companyId: string): Observable<HistogramData> {
    let url = this.entityUrl + companyId + '/histogram/';
    url = url.concat('?' + generateDateParam(startDate, endDate));

    return this.sintenoHttpService.get<HistogramData>(url);
  }

  public addCompany(company: Company): Observable<Company> {
    return this.sintenoHttpService.post<Company>(this.entityUrl, company);
  }

  public updateCompany(company: Company): Observable<Company> {
    return this.sintenoHttpService.put<Company>(this.entityUrl, company);
  }

  public deleteCompany(id: string): Observable<Company> {
    return this.sintenoHttpService.delete<Company>(this.entityUrl + id);
  }

  public addImage(file: File, companyId: string): Observable<Media> {
    const formData = new FormData();
    formData.append(file.name, file);
    return this.sintenoHttpService.post<Media>(this.entityUrl + companyId + '/logo', formData, { reportProgress: true });
  }


  public addContactPersonToCompany(company: Company, contactPerson: ContactPerson): Observable<Company> {
    return this.sintenoHttpService.post<Company>(this.entityUrl + company.id + '/contactPersons', contactPerson);
  }

  public updateContactPersonToCompany(company: Company, contactPerson: ContactPerson): Observable<Company> {
    return this.sintenoHttpService.put<Company>(this.entityUrl + company.id + '/contactPersons', contactPerson);
  }

  public deleteContactPersonToCompany(company: Company, contactPerson: ContactPerson): Observable<Company> {
    return this.sintenoHttpService.delete<Company>(this.entityUrl + company.id + '/contactPersons/' + contactPerson.id);
  }

  public getCompanyWorkerStatistics(id: string): Observable<GlobalWorkerStatistics> {
    return this.sintenoHttpService.get<GlobalWorkerStatistics>(`${this.entityUrl}${id}/statistics`);
  }

  public getCompanyNodes(workerId: string): Observable<HierarchicalSimpleNode[]> {
    return this.sintenoHttpService.get<HierarchicalSimpleNode[]>(`${this.entityUrl}nodes/${workerId}`);
  }

  public removeAdministrator(companyId: string, workerId: string): Observable<Company> {
    return this.sintenoHttpService.delete<Company>(`${this.entityUrl + companyId} / ${workerId}`);
  }

  public getTheoryHistogramData(companyId: string, startDate: Date, endDate: Date): Observable<HistogramData> {
    let url = `${this.entityUrl}${companyId}/evaluations/histogram/`;
    url = url.concat('?' + generateDateParam(startDate, endDate));

    return this.sintenoHttpService.get<HistogramData>(url);
  }

  public getCompanyEnrichedCubesWithTheory(companyId: string, startDate: Date, endDate: Date): Observable<EnrichedCubeModel[]> {
    let url = `${this.entityUrl}${companyId}/evaluations/enrichedchapters/`;
    url = url.concat('?' + generateDateParam(startDate, endDate));
    return this.sintenoHttpService.get<EnrichedCubeModel[]>(url);
  }
}
