<div class="allContainer">
  <ng-container *ngIf="currentUser$ | async">
    <app-menu class="sidebarContainer" *ngIf="menuIsVisible"></app-menu>
  </ng-container>
  <div class="page-container">
    <div class="page-inner">
      <div *ngIf="currentUser$ | async as user" class="profileDropdown">
        <div ngbDropdown container="body">
          <button class="user-tile-container btn btn-sm" ngbDropdownToggle>
            <div class="user-tile" *ngIf="user?.associatedWorker?.image">
              <img class="sm-card-img" [src]="user.associatedWorker.image.path" />
            </div>
            <div class="user-tile user-tile-noImage"
              *ngIf="!user || !user.associatedWorker || !user.associatedWorker.image">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"
                class="icon-noPicture">
                <g>
                  <g>
                    <path d="M256,0C116.968,0,0,114.108,0,256c0,140.61,115.39,256,256,256c139.79,0,256-115.51,256-256C512,115.39,396.61,0,256,0z
                          M256,482C131.928,482,30,380.072,30,256c0-62.946,27.05-119.859,68.85-160.734l293.828,339.053
                        C354.74,463.468,307.434,482,256,482z M415.357,414.665l-293.61-338.8C159.275,47.822,205.651,30,256,30
                        c124.072,0,226,101.928,226,226C482,317.86,455.894,373.951,415.357,414.665z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path d="M376,151h-38.789l-40.605-40.605C293.793,107.582,289.984,106,286,106h-60c-3.984,0-7.793,1.582-10.605,4.395
                        l-12.876,12.876l50.31,58.05c1.075-0.046,2.086-0.32,3.171-0.32c41.353,0,75,33.647,75,75c0,4.759-0.555,9.382-1.406,13.898
                        l85.265,98.384C418.658,361.685,421,354.145,421,346V196C421,171.186,400.814,151,376,151z" />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="M262.844,330.308c-2.288,0.212-4.5,0.692-6.844,0.692c-41.353,0-75-33.647-75-75c0-6.053,0.916-11.862,2.276-17.505
                        l-70.201-81.004C99.919,165.354,91,179.59,91,196v150c0,24.814,20.186,45,45,45h179.443L262.844,330.308z" />
                  </g>
                </g>
              </svg>
            </div>
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem routerLink="/profile">
              <i class="fa fa-user"></i>
              <ng-container i18n="@@Profile">Profile</ng-container>
            </button>
            <button ngbDropdownItem routerLink="/terms">
              <i class="fa fa-file-text"></i>
              <ng-container i18n="@@TermsOfAgreement">Terms of agreement</ng-container>
            </button>
            <div class="dropdown-divider"></div>
            <button ngbDropdownItem (click)="logout()">
              <i class="fa fa-power-off signout"></i>
              <ng-container i18n="@@SignOut">Sign out</ng-container>
            </button>
          </div>
        </div>
      </div>
      <app-title></app-title>
      <main [@routerTransition]="getState(o)">
        <router-outlet #o="outlet"></router-outlet>
      </main>
    </div>
  </div>
</div>


<ngx-spinner class="sinteno-spinner"></ngx-spinner>
<div id="modal-container" class="hidden"></div>
