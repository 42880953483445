import { PublishingNoteDetailed } from '@models/publishing-note/publishing-note-detailed.model';
import { TranslatedPublishingNote } from '@models/publishing-note/translated-publishing-note.model';

export class AddPublishingNoteAction {
  public static readonly type = '[PublishingNote] Add Publishing Note';
  public constructor(public publishingNote: PublishingNoteDetailed) { }
}

export class UpdatePublishingNoteAction {
  public static readonly type = '[PublishingNote] Update Publishing Note';
  public constructor(public publishingNote: PublishingNoteDetailed) { }
}

export class GetChapterPublishingNotesAction {
  public static readonly type = '[PublishingNote] Get Chapter Publishing Notes';
  public constructor(public cubeId: string, public chapterId: string) { }
}

export class GetBehaviourPublishingNotesAction {
  public static readonly type = '[PublishingNote] Get Behaviour Publishing Notes';
  public constructor(public cubeId: string, public chapterId: string, public behaviourId: string) { }
}

export class ResetPublishingNotes {
  public static readonly type = '[PublishingNote] Reset Publishing Notes';
  public constructor() { }
}

export class GetPublishingNoteTranslationAction {
  public static readonly type = '[PublishingNote] Get Publishing Note translation';
  public constructor(public publishingNoteId: string, public languageId: string) { }
}

export class UpdatePublishingNoteTranslationAction {
  public static readonly type = '[PublishingNote] update Publishing Note translation';
  public constructor(public publishingNoteId: string,
    public translatedPublishingNote: TranslatedPublishingNote) { }
}


export class GetPublishingNoteAction {
  public static readonly type = '[PublishingNote] Get Publishing Note By Id';
  public constructor(public publishingNoteId: string) { }
}

export class GetAllPublishingNotesAction {
  public static readonly type = '[PublishingNote] Get all publishing notes';
  public constructor(public cubeId: string) { }
}
