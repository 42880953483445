import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AddNavigationAction, BackNavigationAction } from '@shared/store/title/title.action';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
  public constructor(private router: Router, private store: Store) {

  }

  public back(): void {
    this.store.dispatch(new BackNavigationAction());
  }
}
