import { AssignedWorker } from './assigned-worker';
import { StructuralNodeLabel } from '@models/structural-node-type/structural-node-label';
import { StructuralNodeType } from '@models/structural-node-type/structural-node-type';
import { HierarchicalLine } from './hierarchical-line';
import { Cube } from '@shared/store/cube/models/cube.model';
import { NodeProperty } from './node-property';

export class HierarchicalNode {
  public id: string;
  public assignedWorkers: AssignedWorker[];
  public structuralNodeLabel: StructuralNodeLabel;
  public structuralNodeType: StructuralNodeType;
  public cubesAttribution: Cube[];
  public nodeProperties: NodeProperty[];
}

export class DetailedHierarchicalNode extends HierarchicalNode {
  public subNodes: DetailedHierarchicalNode[];
  public subHierarchicalLines: HierarchicalLine[];
  public canUpdate: boolean;
  public canAddSubNode: boolean;
  public canAddParentNode: boolean;
  public canAddSiblingNode: boolean;
}

export class MoveHierarchicalNodeModel {
  public lineId: string;
  public nodeId: string;
  public newParentNodeId: string;
}

export class HierarchicalSimpleNode {
  public id: string;
  public nodeLabel: string;
  public type: NodeType;
}

export enum NodeType {
  Company = 'Company',
  StructuralNode = 'StructuralNode',
  Worker = 'Worker',
  System = 'System'
}

export const NodeTypeMapping: Record<NodeType, string> = {
  [NodeType.Company]: 'Company',
  [NodeType.StructuralNode]: 'StructuralNode',
  [NodeType.Worker]: 'Worker',
  [NodeType.System]: 'System'
};
