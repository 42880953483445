import { NgModule } from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faCopyright, faPlusCircle, faTrashAlt, faTimes, faUser, faHome,
  faCalendar, faSquare, faUserCircle, faPencilAlt, faQuestionCircle, faCheckCircle,
  faThList, faArrowsAlt, faInfo, faSearch, faSave, faQuestion, faUserCheck, faTruck,
  faCube, faArrowAltCircleRight, faPlusSquare, faMinusSquare, faEye, faChartArea,
  faChartBar, faCircle, faAddressCard, faCogs, faShareAlt, faBuilding, faMinusCircle,
  faArrowCircleDown, faArrowCircleUp, faArrowCircleLeft, faArrowCircleRight, faMagic, faExchangeAlt, faGlobe
} from '@fortawesome/free-solid-svg-icons';

@NgModule({
  imports: [
    FontAwesomeModule
  ],
  exports: [
    FontAwesomeModule
  ]
})
export class FontAwesomeIconsModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(faCopyright, faPlusCircle, faTrashAlt, faTimes, faUser, faHome,
      faCalendar, faSquare, faUserCircle, faPencilAlt, faQuestionCircle, faCheckCircle,
      faThList, faArrowsAlt, faInfo, faSearch, faSave, faQuestion, faUserCheck, faTruck,
      faCube, faArrowAltCircleRight, faPlusSquare, faMinusSquare, faEye, faChartArea, faChartBar, faCircle, faAddressCard,
      faCogs, faShareAlt, faBuilding, faMinusCircle, faArrowCircleDown, faArrowCircleUp, faArrowCircleLeft, faArrowCircleRight,
      faMagic, faExchangeAlt, faGlobe);
  }
}
