import { HistogramWithTheoryModel, InlineTheoreticalEvaluationModel, ResultTestQuestionViewModel } from '../store/audit/audit.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { EnrichedCubeModel } from '@shared/store/audit/audit.model';
import { generateDateParam } from '@shared/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class TheoreticalEvaluationService {
  private entityUrl = 'theoreticalEvaluations/';

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public getHistogramData(startDate: Date, endDate: Date, workerId: string, includeSubAssignedWorker: boolean)
    : Observable<HistogramWithTheoryModel> {
    let url = this.entityUrl + 'histogram/';
    url = url.concat('?' + generateDateParam(startDate, endDate));
    if (workerId) {
      url = url.concat('&workerId=' + workerId);
    }
    url = url.concat('&includeSubAssignedWorker=' + includeSubAssignedWorker);


    return this.sintenoHttpService.get<HistogramWithTheoryModel>(url);
  }

  public getHistogramPerFormation(workerId: string, formationId: string, showSessionData: boolean)
    : Observable<HistogramWithTheoryModel> {
    const url = `${this.entityUrl}histogram/workers/${workerId}/formations/${formationId}?showSessionData=${showSessionData}`;

    return this.sintenoHttpService.get<HistogramWithTheoryModel>(url);
  }

  public getEnrichedChaptersByWorker(workerId: string, startDate: Date, endDate: Date,
    includeSubAssignedWorker: boolean): Observable<EnrichedCubeModel[]> {
    let url = `${this.entityUrl}chapters/${workerId}`;
    url = url.concat('?' + generateDateParam(startDate, endDate));
    url = url.concat('&includeSubAssignedWorker=' + includeSubAssignedWorker);
    return this.sintenoHttpService.get<EnrichedCubeModel[]>(url);
  }

  public getEnrichedChaptersByWorkerPerFormation(workerId: string, formationId: string, showSessionData: boolean):
    Observable<EnrichedCubeModel[]> {
    const url = `${this.entityUrl}chapters/${workerId}/formations/${formationId}?showSessionData=${showSessionData}`;
    return this.sintenoHttpService.get<EnrichedCubeModel[]>(url);
  }

  public getAudit(auditId: string): Observable<ResultTestQuestionViewModel> {
    const url = `${this.entityUrl}${auditId}/`;
    return this.sintenoHttpService.get<ResultTestQuestionViewModel>(url);
  }

  public getAuditForWorkerPerFormation(workerId: string, formationId: string, showSessionData: boolean):
    Observable<InlineTheoreticalEvaluationModel[]> {
    const url = `${this.entityUrl}auditForWorker/${workerId}/formations/${formationId}?showSessionData=${showSessionData}`;
    return this.sintenoHttpService.get<InlineTheoreticalEvaluationModel[]>(url);
  }

  public getTheoreticalHistogramPerPublishingNote(publishingNoteId: string)
    : Observable<HistogramWithTheoryModel> {
    const url = `${this.entityUrl}histogram/publishingnotes/${publishingNoteId}`;

    return this.sintenoHttpService.get<HistogramWithTheoryModel>(url);
  }

  public getEnrichedChaptersPerPublishingNote(publishingNoteId: string): Observable<EnrichedCubeModel[]> {
    const url = `${this.entityUrl}chapters/publishingnotes/${publishingNoteId}`;
    return this.sintenoHttpService.get<EnrichedCubeModel[]>(url);
  }

  public getAuditPerPublishingNote(publishingNoteId: string): Observable<InlineTheoreticalEvaluationModel[]> {
    const url = `${this.entityUrl}audits/publishingnotes/${publishingNoteId}`;
    return this.sintenoHttpService.get<InlineTheoreticalEvaluationModel[]>(url);
  }
}
