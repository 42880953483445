import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HasRoleGuard implements CanActivate, CanActivateChild {

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canNavigateToRoute(route);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canNavigateToRoute(route);
  }

  private canNavigateToRoute(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const allowedRoles = this.getAuthorizedRolesForRoute(route);
    return this.authenticationService.userHasRoles(allowedRoles).pipe(map(x => {
      if (!x) {
        if (route.data.redirectIfUnauthorized && route.data.redirectIfUnauthorized != '') {
          return this.router.parseUrl(route.data.redirectIfUnauthorized);
        }
      }
      return x;
    }));
  }

  private getAuthorizedRolesForRoute(route: ActivatedRouteSnapshot): string[] {
    if (route.data.roles == null) {
      return [];
    }
    return route.data.roles as string[];
  }
}
