import { UserSessionModel } from './session.model';
import { State, Action, Selector, StateContext, Store } from '@ngxs/store';
import { AcceptTermsAction, GetAreTermsAcceptedAction, GetCurrentSession, GetCurrentSessionAndGoToHome } from './session.action';
import { AuthenticationService } from '@shared/services/authentication.service';
import { ProfileModel } from '@core/authentication/models/user';
import { Router } from '@angular/router';

export interface SessionStateModel {
  currentSession: UserSessionModel;
  areTermsAccepted: boolean;
}

@State<SessionStateModel>({
  name: 'session',
  defaults: {
    currentSession: null, // used in app.component
    areTermsAccepted: null
  }
})
export class SessionState {
  @Selector()
  public static currentSession(sessionStateModel: SessionStateModel) {
    return sessionStateModel.currentSession;
  }

  @Selector()
  public static currentProfile(sessionStateModel: SessionStateModel): ProfileModel {
    return {
      workerId: sessionStateModel.currentSession.associatedWorker.id,
      image: sessionStateModel.currentSession.associatedWorker.image,
      firstName: sessionStateModel.currentSession.associatedWorker.firstName,
      lastName: sessionStateModel.currentSession.associatedWorker.lastName,
      displayName: sessionStateModel.currentSession.associatedWorker.displayName,
      birthDate: sessionStateModel.currentSession.associatedWorker.birthDate,
      companyName: sessionStateModel.currentSession.associatedWorker.company.name,
      language: sessionStateModel.currentSession.associatedWorker.language,
      userName: sessionStateModel.currentSession.userName,
      email: sessionStateModel.currentSession.email,
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    };
  }

  @Selector()
  public static areTermsAccepted(sessionStateModel: SessionStateModel) {
    return sessionStateModel.areTermsAccepted;
  }

  public constructor(private authenticationService: AuthenticationService, private router: Router, private store: Store) { }

  @Action(GetCurrentSession)
  public GetCurrentSession(ctx: StateContext<SessionStateModel>): void {
    this.authenticationService.getCurrentSession()
      .subscribe((response) => ctx.patchState({ currentSession: response }));
  }

  @Action(GetCurrentSessionAndGoToHome)
  public GetCurrentSessionAndGoToHome(ctx: StateContext<SessionStateModel>): void {
    document.location.href = '/';
  }

  @Action(GetAreTermsAcceptedAction)
  public GetAreTermsAccepted(ctx: StateContext<SessionStateModel>): void {
    this.authenticationService.areTermsAccepted()
      .subscribe((response) => ctx.patchState({ areTermsAccepted: response }));
  }

  @Action(AcceptTermsAction)
  public updateWorkerProfile(
    ctx: StateContext<SessionStateModel>,
    action: AcceptTermsAction
  ): void {
    this.authenticationService
      .acceptTerms()
      .subscribe(() => this.store.dispatch(new GetCurrentSessionAndGoToHome()));
  }
}
