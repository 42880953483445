import { UpdateStatusPropositionModel } from './../audit/audit.model';
import { CubeSearchModel } from '@models/cube/cube-search-model';
import { Cube, CubeAuthorization } from './models/cube.model';
import { Chapter } from '@shared/store/cube/models/chapter.model';
import { Behaviour } from './models/behaviour.model';
import { CreatePropositionModel } from '@models/cube/proposition';
import { TranslatedChapter } from './models/translated-chapter.model';
import { TranslatedBehaviour } from './models/translated-behaviour.model';
import { Question } from './models/question.model';
import { Cover } from './models/cover.model';
import { TranslatedCover } from './models/translated-cover.model';

export class GetCubesAction {
    public static readonly type = '[Cube] Get Cubes';
    public constructor(public cubeSearchModel?: CubeSearchModel) { }
}

export class GetCubesAuthorizationAction {
    public static readonly type = '[Cube] Get Cubes Authorization';
    public constructor(public cubeSearchModel?: CubeSearchModel) { }
}



export class ClearCubeStateAction {
    public static readonly type = '[Cube] clear state';

}


export class GetCubeAuthorizationAction {
    public static readonly type = '[Cube] Get Cube Authorization';
    public constructor(public id: string) { }
}

export class GetDetailedCubeAction {
    public static readonly type = '[Cube] Get Detailed Cube';
    public constructor(public cubeId: string, public titlePrefixe?: string) { }
}

export class GetDetailedCubeAndLoadBehaviorAction {
    public static readonly type = '[Cube] Get Detailed Cube And Load Behaviour';
    public constructor(public cubeId: string, public chapterId: string, public behaviorId: string) { }
}

export class AddCubeAction {
    public static readonly type = '[Cube] Add Cube';
    public constructor(public cube: CubeAuthorization) { }
}

export class EditCubeAction {
    public static readonly type = '[Cube] Edit Cube';
    public constructor(public cube: CubeAuthorization) { }
}

export class AddChapterAction {
    public static readonly type = '[Cube] Add Chapter';

    public constructor(public cubeId: string, public chapter: Chapter, public parentId?: string) { }
}

export class DeleteChapterAction {
    public static readonly type = '[Cube] Delete Chapter';
    public constructor(public cubeId: string, public chapterId: string) { }
}

export class MoveChapterAction {
    public static readonly type = '[Cube] Move Chapter';
    public constructor(public cubeId: string, public chapterId: string, public order: number, public parentId?: string) { }
}

export class MoveBehaviourAction {
  public static readonly type = '[Cube] Move Behaviour';
  public constructor(public cubeId: string, public oldParentChapterId: string,
    public behaviourId: string, public newParentChapterId: string, public order: number) { }
}

export class UpdateChapterAction {
    public static readonly type = '[Cube] Update Chapter';
    public constructor(public cubeId: string, public chapter: Chapter) { }
}

export class AddBehaviourAction {
    public static readonly type = '[Cube] Add Behaviour';
    public constructor(public cubeId: string, public chapterId: string, public behaviour: Behaviour) { }
}

export class UpdateBehaviourAction {
    public static readonly type = '[Cube] Update Behaviour';
    public constructor(public cubeId: string, public chapterId: string, public behaviour: Behaviour) { }
}

export class DeleteBehaviourAction {
    public static readonly type = '[Cube] Delete Behaviour';
    public constructor(public cubeId: string, public chapterId: string, public behaviour: Behaviour) { }
}

export class SelectChapterAction {
    public static readonly type = '[Cube] Select Chapter';
    public constructor(public chapter: Chapter) { }
}

export class SelectBehaviourAction {
    public static readonly type = '[Cube] Select Behaviour';

    public constructor(public behaviour: Behaviour) { }
}


export class EditQuestionAction {
    public static readonly type = '[Cube] edit Question';
    public constructor(public question: Question) { }
}


export class UpdateQuestionAction {
    public static readonly type = '[Cube] update Question';
    public constructor(public question: Question) { }
}

export class CloseQuestionAction {
    public static readonly type = '[Cube] close Question';

}


export class ClosePreviewQuestionAction {
    public static readonly type = '[Cube] close QuestionPreview';

}


export class OpenPreviewQuestionAction {
    public static readonly type = '[Cube] preview Question';
    public constructor(public question: Question) { }
}


export class AddQuestionToBehaviorAction {
    public static readonly type = '[Cube] add Question';
    public constructor(public behaviour: Behaviour) { }
}



export class DeleteQuestionFromBehaviorAction {

    public static readonly type = '[Cube] Delete Question';
    public constructor(public question: Question) { }
}




export class ShowBehaviourPropositionsAction {
    public static readonly type = '[Cube] Show Propositions for Behaviour ';
    public constructor(public cubeId: string, public behaviorId: string) { }
}

export class DismissBehaviourPropositionsAction {
    public static readonly type = '[Cube] Dismiss Propositions for Behaviour ';
}


export class UpdatePropositionAndRefreshBehaviourPropositionsAction {
    public static readonly type = '[Cube] Update Proposition for Behaviour';
    public constructor(public cubeId: string, public behaviorId: string, public model: UpdateStatusPropositionModel) { }

}

export class UpdatePropositionAndRefreshPropositionsDetailedAction {
  public static readonly type = '[Cube] Update Proposition and refresh all';
  public constructor(public cubeId: string, public behaviorId: string, public model: UpdateStatusPropositionModel) { }

}

export class AddPropositionAction {
    public static readonly type = '[Cube] Add proposition';

    public constructor(public proposition: CreatePropositionModel) { }
}

export class GetChapterTranslationAction {
    public static readonly type = '[Cube] Get Chapter translation';
    public constructor(public cubeId: string, public chapterId: string, public languageId: string) { }
}

export class UpdateChapterTranslationAction {
    public static readonly type = '[Cube] update Chapter translation';
    public constructor(public cubeId: string, public chapterId: string, public translatedChapter: TranslatedChapter) { }
}

export class GetBehaviourTranslationAction {
    public static readonly type = '[Cube] Get Behaviour translation';
    public constructor(public cubeId: string, public chapterId: string, public behaviourId: string, public languageId: string) { }
}

export class UpdateBehaviourTranslationAction {
    public static readonly type = '[Cube] update Behaviour translation';
    public constructor(public cubeId: string, public chapterId: string, public behaviourId: string,
        public translatedBehaviour: TranslatedBehaviour) { }
}

export class UpdateCoverAction {
  public static readonly type = '[Cube] Update Cover';
  public constructor(public cubeId: string, public cover: Cover, public file: File) { }
}

export class GetCoverTranslationAction {
  public static readonly type = '[Cube] Get Cover translation';
  public constructor(public cubeId: string, public languageId: string) { }
}

export class UpdateCoverTranslationAction {
  public static readonly type = '[Cube] update Cover translation';
  public constructor(public cubeId: string, public translatedCover: TranslatedCover) { }
}

export class AddImageToCover {
  public static readonly type = '[Cube] Add Image to Cover';

  public constructor(public cubeId: string, public file: File) { }
}

export class GetPropositionsAction {
  public static readonly type = '[Cube] Get Propositions';
  public constructor() { }
}

export class GetPublishingNoteToUpdateAction {
  public static readonly type = '[Cube] Get Publishing Note To Update';
  public constructor(public publishingNoteId: string) { }
}

export class GetCubeWorkersAction {
  public static readonly type = '[Cube] Get Workers';
  public constructor(public cubeId: string) { }
}

export class GetSelectableCompaniesByCube {
  public static readonly type = '[Cube] Get Selectable Companies';
  public constructor(public cubeId: string) { }
}

// export class SelectCubesAction {
//   public static readonly type = '[Cube] Select Cubes';
//   public constructor(public cubes: CubeAuthorization[]) { }
// }
