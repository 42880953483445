import { createCube, Cube } from '../cube/models/cube.model';
export class CubeAssignment {
  public referenceId: string;
  public cube: Cube;
  public startDate: Date;
  public endDate: Date;
}

export function createCubeAssignmentModel(init?: Partial<CubeAssignment>): CubeAssignment {
  const assignment = {
    referenceId: undefined,
    cube: createCube(),
    startDate: undefined,
    endDate: undefined
  };
  Object.assign(assignment, init);

  return assignment;
}
