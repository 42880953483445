import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { FormationStatistics } from '@models/formation/formation.model';
import { FormationSessionStatistics } from '@models/formation/formation-session-statistics.model';

@Injectable({
  providedIn: 'root'
})
export class FormationSessionService {
  private entityUrl = 'workers/';

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public getCubeFormationSessionStatistics(workerId: string): Observable<FormationSessionStatistics[]> {
    return this.sintenoHttpService.get<FormationSessionStatistics[]>(
      `${this.entityUrl}${workerId}/formationsessions/statistics`
    );
  }

  public getCubeFormationSessionStatisticsDetails(workerId: string): Observable<FormationStatistics[]> {
    return this.sintenoHttpService.get<FormationStatistics[]>(
      `${this.entityUrl}${workerId}/formationsessions/statistics/details`
    );
  }
}
