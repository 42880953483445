import { HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap, finalize } from 'rxjs/operators';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    private count = 0;
    public constructor(private spinnerService: NgxSpinnerService) {
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerService.show();
        this.count++;
        return next.handle(request).pipe(
            tap(),
            finalize(() => {
                this.count--;
                if (this.count === 0) {
                    this.spinnerService.hide();
                }
            })
        );
    }
}

