import { BehaviourWithEvaluationScore, ChapterWithEvaluation, CubeDetailedWithEvaluation } from './../../audit/audit.model';
import { TreeNode } from 'primeng/api';
import { Behaviour } from './behaviour.model';
import { Chapter } from './chapter.model';
import { AuditableStatus } from '@models/cube/auditable-status.enum';
import { BehaviourStatus } from './behaviour-status';
import { Cover } from './cover.model';

export enum TableOfContentTreeNodeTypes {
  Chapter = 'chapter',
  Behaviour = 'behaviour'
}

export class TableOfContentNode implements TreeNode {
  public id: string;
  public name?: string;
  public nodeType: TableOfContentTreeNodeTypes;
  public data?: any;
  public children: Array<TableOfContentNode>;
  public order: number;
  public numbering: string;
  public hasCriteria: boolean;
  public numberOfQuestions: number;
  public numberOfQuestionToComplete: number;
  public hasDocumentation: boolean;
  public numberOfPropositions: number;
  public numberOfPendingPropositions: number;
  public hidden: boolean;

  public static generateChapter(model: Chapter): TableOfContentNode {
    const treeNode = new TableOfContentNode();
    const chapterStatus = Object.values(AuditableStatus);
    treeNode.id = model.id;
    if (model.name != null) {
      treeNode.name = model.name;
    }
    treeNode.nodeType = TableOfContentTreeNodeTypes.Chapter;
    treeNode.data = model;
    treeNode.order = model.order;
    treeNode.children = new Array<TableOfContentNode>();
    if (chapterStatus[model.status] === AuditableStatus.Hidden) {
      treeNode.hidden = true;
    }

    model.behaviours.forEach(behaviour => {
      treeNode.children.push(TableOfContentNode.generateBehavior(behaviour));
    });

    treeNode.numbering = model.numbering;
    model.chapters.forEach(chapter => {
      treeNode.children.push(TableOfContentNode.generateChapter(chapter));
    });
    treeNode.hasCriteria = model.hasCriteria;
    treeNode.numberOfQuestions = model.numberOfQuestions;
    treeNode.numberOfQuestionToComplete = model.numberOfQuestionToComplete;
    treeNode.hasDocumentation = model.hasDocumentation;
    treeNode.numberOfPropositions = model.numberOfPropositions;
    treeNode.numberOfPendingPropositions = model.numberOfPendingPropositions;
    return treeNode;
  }

  public static generateBehavior(model: Behaviour): TableOfContentNode {
    const treeNode = new TableOfContentNode();
    const behaviourStatus = Object.values(BehaviourStatus);
    treeNode.id = model.id;
    treeNode.nodeType = TableOfContentTreeNodeTypes.Behaviour;
    treeNode.name = model.name;
    treeNode.data = model;
    treeNode.hasCriteria = model.hasCriteria;
    treeNode.numberOfQuestions = model.numberOfQuestions;
    treeNode.hasDocumentation = model.hasDocumentation;
    treeNode.numberOfPropositions = model.numberOfPropositions;
    treeNode.numberOfPendingPropositions = model.numberOfPendingPropositions;
    if (behaviourStatus[model.status] === BehaviourStatus.Hidden) {
      treeNode.hidden = true;
    }
    return treeNode;
  }
}

export class NodeWithEvaluations implements TreeNode {
  public id: string;
  public parentId: string;
  public name?: string;
  public nodeType: TableOfContentTreeNodeTypes;
  public data?: any;
  public children: Array<NodeWithEvaluations>;
  public order: number;
  public numbering: string;
  public auditNumber: number;
  public auditAverageScore: number;
  public theoryticalQuestionNumber: number;
  public theoryticalQuestionAverageScore: number;
  public hidden: boolean;

  public static generateChapter(model: ChapterWithEvaluation): NodeWithEvaluations {
    const treeNode = new NodeWithEvaluations();
    const chapterStatus = Object.values(AuditableStatus);
    treeNode.id = model.id;
    if (model.name != null) {
      treeNode.name = model.name;
    }
    treeNode.nodeType = TableOfContentTreeNodeTypes.Chapter;
    treeNode.data = model;
    treeNode.order = model.order;
    treeNode.children = new Array<NodeWithEvaluations>();
    treeNode.auditNumber = model.auditNumber;
    treeNode.auditAverageScore = model.auditAverageScore;
    treeNode.theoryticalQuestionNumber = model.theoryticalQuestionNumber;
    treeNode.theoryticalQuestionAverageScore = model.theoryticalQuestionAverageScore;
    if (chapterStatus[model.status] === AuditableStatus.Hidden) {
      treeNode.hidden = true;
    }

    model.behaviours.forEach(behaviour => {
      treeNode.children.push(NodeWithEvaluations.generateBehavior(behaviour, model.id));
    });

    treeNode.numbering = model.numbering;
    model.chapters.forEach(chapter => {
      treeNode.children.push(NodeWithEvaluations.generateChapter(chapter));
    });
    return treeNode;
  }

  public static generateBehavior(model: BehaviourWithEvaluationScore, chapterId: string): NodeWithEvaluations {
    const treeNode = new NodeWithEvaluations();
    const behaviourStatus = Object.values(BehaviourStatus);
    treeNode.id = model.id;
    treeNode.parentId = chapterId;
    treeNode.nodeType = TableOfContentTreeNodeTypes.Behaviour;
    treeNode.name = model.name;
    treeNode.data = model;
    treeNode.auditNumber = model.auditNumber;
    treeNode.auditAverageScore = model.auditAverageScore;
    treeNode.theoryticalQuestionNumber = model.theoryticalQuestionNumber;
    treeNode.theoryticalQuestionAverageScore = model.theoryticalQuestionAverageScore;
    if (behaviourStatus[model.status] === BehaviourStatus.Hidden) {
      treeNode.hidden = true;
    }
    return treeNode;
  }

  public static getFlatBehaviours(nodes: NodeWithEvaluations[]): NodeWithEvaluations[] {
    let treeNodeTable: NodeWithEvaluations[] = [];
    nodes.forEach(node => {
      if (node.nodeType === TableOfContentTreeNodeTypes.Behaviour) {
        treeNodeTable.push(node);
      } else {
        treeNodeTable = [...treeNodeTable, ...this.getFlatBehaviours(node.children)];
      }
    });
    return treeNodeTable;
  }
}

export class CubeWithNodeEvaluations {
  id?: string;
  name: string;
  cover: Cover;
  nodes: NodeWithEvaluations[];
  displayableNodes: NodeWithEvaluations[];
  flatBehaviours: NodeWithEvaluations[];
  auditNumber: number;
  auditAverageScore: number;
  theoryticalQuestionNumber: number;
  theoryticalQuestionAverageScore: number;
  public static generateCubeWithEvaluations(model: CubeDetailedWithEvaluation): CubeWithNodeEvaluations {
    const cube = new CubeWithNodeEvaluations();
    cube.id = model.id;
    cube.name = model.name;
    cube.cover = model.cover;
    cube.auditNumber = model.auditNumber;
    cube.auditAverageScore = model.auditAverageScore;
    cube.theoryticalQuestionNumber = model.theoryticalQuestionNumber;
    cube.theoryticalQuestionAverageScore = model.theoryticalQuestionAverageScore;
    cube.nodes = model.tableOfContent.chapters
                    .map(c => NodeWithEvaluations.generateChapter(c));
    cube.flatBehaviours = NodeWithEvaluations.getFlatBehaviours(cube.nodes);
    cube.displayableNodes = cube.nodes;
    return cube;
  }
}
