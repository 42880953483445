import { TheoreticalEvaluationService } from './../../services/theoreticalEvaluation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditModel, ResultTestQuestionViewModel } from '../../store/audit/audit.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-theoretical-evaluation-detail-modal',
  templateUrl: './theoretical-evaluation-detail-modal.component.html',
  styleUrls: ['./theoretical-evaluation-detail-modal.component.scss']
})
export class TheoreticalDetailModalComponent {
  public constructor(private modal: NgbActiveModal) { }

  @Input() public theoreticalEvaluation: ResultTestQuestionViewModel;

  public close() {
    this.modal.close();
  }
}
