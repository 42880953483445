import { UpdateStatusPropositionModel } from './../../store/audit/audit.model';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { PropositionModel } from '@shared/store/audit/audit.model';

@Component({
  selector: 'app-rule-change-proposition',
  templateUrl: './rule-change-proposition.component.html',
  styleUrls: ['./rule-change-proposition.component.scss']
})
export class RuleChangePropositionComponent implements OnChanges {
  @Input() public proposition: PropositionModel;
  @Output() public updateStatus = new EventEmitter<UpdateStatusPropositionModel>();
  @Output() public close = new EventEmitter();


  public listStatus = [0, 1, 2];
  public updateForm: FormGroup;

  public ngOnChanges(changes: SimpleChanges): void {
    const proposition = changes.proposition.currentValue as PropositionModel;
    this.updateForm = new FormGroup({
      status: new FormControl(proposition.status),
      comment: new FormControl(proposition.comment)
    });
  }

  public update(): void {
    const updateModel = new UpdateStatusPropositionModel();
    updateModel.id = this.proposition.id;
    updateModel.comment = this.updateForm.controls['comment'].value;
    updateModel.status = this.updateForm.controls['status'].value;
    this.updateStatus.emit(updateModel);
  }
}
