import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GetLanguagesAction, GetSystemLanguagesAction } from './language.action';
import * as _ from 'lodash';
import { LanguageService } from '@shared/services/language.service';
import { Language } from './language-view-model';


export interface LanguageStateModel {
  systemLanguages: Language[];
  languages: Language[];
}

@State<LanguageStateModel>({
  name: 'language',
  defaults: {
  systemLanguages: null,
  languages: null
}
})
export class LanguageState {
  public constructor(
    private languageService: LanguageService  ) { }

  @Selector()
  public static systemLanguages(stateModel: LanguageStateModel) {
    return stateModel.systemLanguages;
  }

  @Selector()
  public static languages(stateModel: LanguageStateModel) {
    return stateModel.languages;
  }

  @Action(GetSystemLanguagesAction)
  public getSystemLanguages(ctx: StateContext<LanguageStateModel>): void {
    this.languageService.getLanguages(true)
      .subscribe(systemLanguages => {
        ctx.patchState({ systemLanguages: systemLanguages });
      });
  }

  @Action(GetLanguagesAction)
  public getLanguages(ctx: StateContext<LanguageStateModel>): void {
    this.languageService.getLanguages(false)
      .subscribe(languages => {
        ctx.patchState({ languages: languages });
      });
  }
}
