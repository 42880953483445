import { Route, RouterModule } from '@angular/router';
import { HomeComponent } from '@core/components/home/home.component';
import { NgModule } from '@angular/core';
import { HasRoleGuard } from '@shared/guards/has-role.guard';
import { UserRoles } from '@shared/utils/user-roles';
import { IsNotAuthenticatedGuard } from '@shared/guards/is-not-authenticated.guard';
import { IsAuthenticatedGuard } from '@shared/guards/is-authenticated.guard';
import { AreTermsAcceptedGuard } from '@shared/guards/are-terms-accepted.guard';

export const routes: Route[] = [
  {
    path: 'login',
    component: HomeComponent,
    data: {
      state: 'login',
    },
    canActivate: [IsNotAuthenticatedGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule),
    data: {
      title: 'Administration',
      state: 'admin',
      roles: [UserRoles.ADMINISTRATOR, UserRoles.HierarchyPartialAdministrator]
    },
    canActivate: [IsAuthenticatedGuard, AreTermsAcceptedGuard, HasRoleGuard]
  },
  {
    path: 'reporting',
    loadChildren: () => import('../reporting/reporting.module').then(m => m.ReportingModule),
    data: {
      state: 'reporting',
      roles: [UserRoles.ADMINISTRATOR]
    },
    canActivate: [IsAuthenticatedGuard, AreTermsAcceptedGuard, HasRoleGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      title: 'Dashboard',
      state: 'dashboard',
      roles: [UserRoles.ADMINISTRATOR]
    },
    canActivate: [IsAuthenticatedGuard, AreTermsAcceptedGuard, HasRoleGuard]
  },
  {
    path: 'personal',
    loadChildren: () => import('../personal/personal.module').then(m => m.PersonalModule),
    data: {
      title: 'Personal',
      state: 'personal'
    },
    canActivate: [IsAuthenticatedGuard, AreTermsAcceptedGuard]
  },
  {
    path: 'company',
    loadChildren: () => import('../company-dashboard/company-dashboard.module').then(m => m.CompanyDashboardModule),
    data: {
      title: 'Company',
      state: 'company'
    },
    canActivate: [IsAuthenticatedGuard, AreTermsAcceptedGuard]
  },
  {
    path: 'formation',
    loadChildren: () => import('../formation/formation.module').then(m => m.FormationModule),
    data: {
      title: 'Formation',
      state: 'formation'
    },
    canActivate: [IsAuthenticatedGuard, AreTermsAcceptedGuard]
  },
  {
    path: 'seeder',
    loadChildren: () => import('../seeder/seeder.module').then(m => m.SeederModule),
    data: {
      title: 'Seeder',
      state: 'seeder',
      roles: [UserRoles.ADMINISTRATOR]
    },
    canActivate: [IsAuthenticatedGuard, AreTermsAcceptedGuard, HasRoleGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule),
    data: {
      title: 'Profile',
      state: 'profile'
    },
    canActivate: [IsAuthenticatedGuard, AreTermsAcceptedGuard]
  },
  {
    path: 'terms',
    loadChildren: () => import('../terms-visualization/terms-visualization.module').then(m => m.TermsVisualizationModule),
    data: {
      title: 'Terms',
      state: 'terms'
    },
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: '',
    loadChildren: () => import('../learning/learning.module').then(m => m.LearningModule),
    data: {
      title: 'MySinteno',
      state: ''
    },
    canActivate: [IsAuthenticatedGuard, AreTermsAcceptedGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
