import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { first, filter, map } from 'rxjs/operators';
import { UserLocale } from '@models/user/user-locale';

/**
 * Service handling translation for string in TypeScript since the current version of Angular i18n isn't able to do it.
 *
 * Please at least update the file app/src/locale/typescript/typescript.json when creating a new translation.
 */
@Injectable()
export class TranslationService {
  public language: UserLocale | '';
  public isInitialized$: Observable<boolean>;

  private translations: { [id: string]: string };
  private isInitializedSource = new BehaviorSubject<boolean>(false);
  private get isInitialized(): boolean {
    return this.isInitializedSource.value;
  }

  constructor(private http: HttpClient) {
    this.isInitialized$ = this.isInitializedSource.asObservable();
  }

  /**
   * Initializes `TranslationService` for a given language.
   */
  public initialize(languageCode: UserLocale | ''): void {
    this.language = languageCode;
    this.isInitializedSource.next(false);
    this.getLocalizedJson(this.language).pipe(first()).subscribe(x => {
      this.translations = x;
      this.isInitializedSource.next(true);
    });
  }

  /**
   * Gets the translation by ID as defined in the currently loaded typescript translation file.
   *
   * @param id ID defined in the translation file
   * @returns the translation or the ID in case of error
   */
  public translate(id: string): string {
    if (!this.isInitialized) {
      console.error(`i18n - Not initialized.`);
      return id;
    }
    const translation = this.translations[id];
    if (translation != null && translation !== '') {
      return translation;
    }
    console.warn(`i18n - Missing translation for id ${id}.`);
    return id;
  }

  public translateWhenReady(id: string): Observable<string> {
    return this.isInitialized$.pipe(
      filter(x => x),
      map(_ => this.translate(id))
    );
  }

  public getTranslationSection(sectionId: string): { [id: string]: any } {
    return (this.translate(sectionId) as any);
  }

  public getTranslationSectionWhenReady(sectionId: string): Observable<{ [id: string]: any }> {
    return this.translateWhenReady(sectionId) as Observable<any>;
  }

  /**
   * Fetch the local translation file for the current user's language.
   */
  private getLocalizedJson(languageCode: string): Observable<any> {
    let fileName = '';
    if (languageCode !== '') {
      fileName = `typescript.${languageCode}.json`;
    } else {
      // english (default) language
      fileName = `typescript.json`;
    }
    return this.http.get(`./locale/typescript/${fileName}`);
  }
}
