<div class="card">
  <div class="card-header">
    <h2 [translate]="confirmTitle">{{confirmTitle}}</h2>
    <!--<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <fa-icon icon="times" size="sm" class="fa-fw"></fa-icon>
    </button>-->
  </div>
  <div class="card-body">
    <p class="text-center" [translate]="confirmMessage">{{confirmMessage}}</p>
  </div>
  <div class="card-footer">
    <div class="text-center">
      <button type="button" class="button editButton" (click)="activeModal.dismiss()" i18n="@@Cancel">Cancel</button>
      <button type="button" class="button editButton {{btnClass}}" (click)="activeModal.close(true)" i18n="@@OK">OK</button>
    </div>
  </div>
</div>
