import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { PublishingNoteDetailed } from '@models/publishing-note/publishing-note-detailed.model';
import { PublishingNote } from '@models/publishing-note/publishing-note.model';
import { TranslatedPublishingNote } from '@models/publishing-note/translated-publishing-note.model';

@Injectable({
  providedIn: 'root'
})
export class PublishingNoteService {
  private entityUrl = 'publishingnotes/';
  private languagesEntityName = 'languages';

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public addPublishingNote(publishingNote: PublishingNoteDetailed): Observable<PublishingNote> {
    return this.sintenoHttpService.post<PublishingNote>(this.entityUrl, publishingNote);
  }

  public updatePublishingNote(publishingNote: PublishingNoteDetailed): Observable<PublishingNoteDetailed> {
    return this.sintenoHttpService.put<PublishingNoteDetailed>(this.entityUrl, publishingNote);
  }

  public getPublishingNoteTranslation(publishingNoteId: string, languageId: string)
    : Observable<TranslatedPublishingNote> {
    return this.sintenoHttpService.get<TranslatedPublishingNote>
      (`${this.entityUrl}${publishingNoteId}/${this.languagesEntityName}/${languageId}`);
  }

  public getPublishingNote(publishingNoteId: string)
    : Observable<PublishingNote> {
    return this.sintenoHttpService.get<PublishingNote>
      (`${this.entityUrl}${publishingNoteId}`);
  }

  // tslint:disable-next-line: max-line-length
  public updatePublishingNoteTranslation(publishingNoteId: string, translatedPublishingNote: TranslatedPublishingNote)
    : Observable<any> {
    return this.sintenoHttpService.put<any>(
      `${this.entityUrl}${publishingNoteId}/${this.languagesEntityName}/${translatedPublishingNote.language.id}`
      , translatedPublishingNote);
  }

  public getPublishingNoteDetailed(publishingNoteId: string)
    : Observable<PublishingNoteDetailed> {
    return this.sintenoHttpService.get<PublishingNoteDetailed>
      (`${this.entityUrl}${publishingNoteId}/details`);
  }
}
