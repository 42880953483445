import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreatePropositionModel } from '@models/cube/proposition';
import { Behaviour } from '@shared/store/cube/models/behaviour.model';

@Component({
  selector: 'app-create-proposition',
  templateUrl: './create-proposition.component.html',
  styleUrls: ['./create-proposition.component.scss']
})
export class CreatePropositionComponent {
  proposition: CreatePropositionModel;
  behaviourList: Behaviour[];
  selectedBehaviour: Behaviour;

  constructor(public activeModal: NgbActiveModal) { }

  public sendProposition(): void {
    this.proposition.behaviourId = this.selectedBehaviour.id;
    this.activeModal.close(this.proposition);
  }
}
