export class StructuralNodeLabel {
  public id: string;
  public name: string;
}

export class DetailedStructuralNodeLabel extends StructuralNodeLabel {
  public labels: DetailedStructuralNodeLabel[];
}

export function createDetailedStructuralNodeLabel(init?: Partial<DetailedStructuralNodeLabel>): DetailedStructuralNodeLabel {
  const detailedStructuralNodeLabel = {
    id: undefined,
    name: undefined,
    labels: undefined
  };
  Object.assign(detailedStructuralNodeLabel, init);
  return detailedStructuralNodeLabel;
}
