export class SetTitleAction {
    public static readonly type = '[Title] Set Title';
    public constructor(public title: string) { }
}

export class AddNavigationAction {
    public static readonly type = '[Title] Add Navigation';
    public constructor(public url: string) { }
}

export class BackNavigationAction {
    public static readonly type = '[Title] Back Navigation';
}
