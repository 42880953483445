import { Media } from 'src/app/admin/media/models/media';

export class Cover {
  public id: string;
  public title: string;
  public subTitle: string;
  public logo: Media;
  public lastUpdateDate: Date;
}

export function createCover(init?: Partial<Cover>): Cover {
  const cover = {
    id: undefined,
    title: undefined,
    subTitle: undefined,
    logo: undefined,
    lastUpdateDate: undefined
  };
  Object.assign(cover, init);
  return cover;
}
