
import { createQuestion, Question } from './models/question.model';
import { PropositionModel } from '@shared/store/audit/audit.model';
import {
  State,
  Action,
  StateContext,
  Selector,
  Store
} from '@ngxs/store';
import { Cube, CubeDetailed, CubeAuthorization } from './models/cube.model';
import { TableOfContentService } from '@shared/services/table-of-content.service';
import {
  GetCubesAction,
  AddCubeAction,
  EditCubeAction,
  GetDetailedCubeAction,
  AddChapterAction,
  DeleteBehaviourAction,
  DeleteChapterAction,
  MoveChapterAction,
  UpdateChapterAction,
  AddBehaviourAction,
  UpdateBehaviourAction,
  SelectChapterAction,
  SelectBehaviourAction,
  ShowBehaviourPropositionsAction,
  DismissBehaviourPropositionsAction,
  UpdatePropositionAndRefreshBehaviourPropositionsAction,
  AddPropositionAction,
  GetCubesAuthorizationAction,
  GetCubeAuthorizationAction,
  GetChapterTranslationAction,
  UpdateChapterTranslationAction,
  GetBehaviourTranslationAction,
  UpdateBehaviourTranslationAction,
  EditQuestionAction,
  AddQuestionToBehaviorAction,
  UpdateQuestionAction,
  DeleteQuestionFromBehaviorAction,
  CloseQuestionAction,
  GetDetailedCubeAndLoadBehaviorAction,
  OpenPreviewQuestionAction,
  ClosePreviewQuestionAction,
  ClearCubeStateAction,
  UpdateCoverAction,
  GetCoverTranslationAction,
  UpdateCoverTranslationAction,
  AddImageToCover,
  MoveBehaviourAction,
  GetPropositionsAction,
  UpdatePropositionAndRefreshPropositionsDetailedAction,
  GetPublishingNoteToUpdateAction,
  GetCubeWorkersAction,
  GetSelectableCompaniesByCube
} from './cube.action';
import * as _ from 'lodash';
import {
  TableOfContentNode
} from '@shared/store/cube/models/table-of-content-node.model';
import { switchMap, tap } from 'rxjs/operators';
import { Chapter, SelectableChapter } from './models/chapter.model';
import { Behaviour } from './models/behaviour.model';
import { ToastrService } from 'ngx-toastr';
import { CubeSearchModel } from '@models/cube/cube-search-model';
import { TranslatedChapter } from './models/translated-chapter.model';
import { TranslatedBehaviour } from './models/translated-behaviour.model';
import { TranslationService } from '@shared/services/translation.service';
import { MCQAnswerPresentation, QuestionToAnswer } from './models/question-presentation.model';
import { SetTitleAction } from '../title/title.action';
import { TranslatedCover } from './models/translated-cover.model';
import { PropositionModelDetail } from '@models/cube/proposition';
import { PublishingNoteService } from '@shared/services/publishing-note.service';
import { PublishingNoteDetailed } from '@models/publishing-note/publishing-note-detailed.model';
import { toSelectableWorker, Worker } from '../worker/worker.model';
import { toSelectableChapter } from '@shared/utils/utils';
import { CompanyState } from '../company/company.state';
import { SelectableCompany } from '../company/selectable-company.model';
import { SetIsCubesWithNodes } from '../audit/audit.action';

export interface CubeStateModel {
  cubes: Cube[];
  cubesAuthorization: CubeAuthorization[];
  cubeAuthorization: CubeAuthorization;
  selectedCubesAuthorization: CubeAuthorization[];
  detailedCube: CubeDetailed;
  detailedCubeSimple: CubeDetailed;
  tableOfContent: TableOfContentNode[];
  selectedChapter: Chapter;
  selectedBehaviour: Behaviour;
  flattenedCubeChapters: Chapter[];
  propositions: PropositionModel[];
  openedChapterTranslation: TranslatedChapter;
  openedBehaviourTranslation: TranslatedBehaviour;
  openedQuestionEdition: Question;
  openedQuestionPreview: QuestionToAnswer;
  openedCoverTranslation: TranslatedCover;
  propositionsDetailed: PropositionModelDetail[];
  publishingNoteToUpdate: PublishingNoteDetailed;
  workers: Worker[];
  selectableChapters: SelectableChapter[];
  selectableCompanies: SelectableCompany[];
}

@State<CubeStateModel>({
  name: 'cube',
  defaults: {
    cubes: [],
    cubesAuthorization: [],
    cubeAuthorization: null,
    selectedCubesAuthorization: [],
    detailedCube: null,
    detailedCubeSimple: null,
    tableOfContent: [],
    selectedChapter: null,
    selectedBehaviour: null,
    flattenedCubeChapters: [],
    propositions: [],
    openedChapterTranslation: null,
    openedBehaviourTranslation: null,
    openedQuestionEdition: null,
    openedQuestionPreview: null,
    openedCoverTranslation: null,
    propositionsDetailed: [],
    publishingNoteToUpdate: null,
    workers: [],
    selectableChapters: [],
    selectableCompanies: []
  }
})
export class CubeState {
  @Selector()
  public static cubes(stateModel: CubeStateModel) {
    return stateModel.cubes;
  }

  @Selector()
  public static cubesAuthorization(stateModel: CubeStateModel) {
    return stateModel.cubesAuthorization;
  }


  @Selector()
  public static cubeAuthorization(stateModel: CubeStateModel) {
    return stateModel.cubeAuthorization;
  }

  @Selector()
  public static selectedCubesAuthorization(stateModel: CubeStateModel) {
    return stateModel.selectedCubesAuthorization;
  }

  @Selector()
  public static propositions(stateModel: CubeStateModel) {
    return stateModel.propositions;
  }


  @Selector()
  public static detailedCube(stateModel: CubeStateModel) {
    return stateModel.detailedCube;
  }

  @Selector()
  public static detailedCubeSimple(stateModel: CubeStateModel) {
    return stateModel.detailedCubeSimple;
  }


  @Selector()
  public static tableOfContent(stateModel: CubeStateModel) {
    return stateModel.tableOfContent;
  }

  @Selector()
  public static selectedChapter(stateModel: CubeStateModel) {
    return stateModel.selectedChapter;
  }

  @Selector()
  public static selectedBehaviour(stateModel: CubeStateModel) {
    return stateModel.selectedBehaviour;
  }

  @Selector()
  static filteredCubes(stateModel: CubeStateModel) {
    return (searchString: string) => {
      return stateModel.cubesAuthorization.filter(c => c.name.toLowerCase().includes(searchString.toLowerCase()));
    };
  }

  @Selector()
  public static flattenedCubeChapters(stateModel: CubeStateModel) {
    return stateModel.flattenedCubeChapters;
  }

  @Selector()
  public static openedQuestionEdition(stateModel: CubeStateModel) {
    return stateModel.openedQuestionEdition;
  }



  @Selector()
  public static openedQuestionPreview(stateModel: CubeStateModel) {
    return stateModel.openedQuestionPreview;
  }

  @Selector()
  public static openedChapterTranslation(stateModel: CubeStateModel) {
    return stateModel.openedChapterTranslation;
  }

  @Selector()
  public static openedBehaviourTranslation(stateModel: CubeStateModel) {
    return stateModel.openedBehaviourTranslation;
  }

  @Selector()
  public static openedCoverTranslation(stateModel: CubeStateModel) {
    return stateModel.openedCoverTranslation;
  }

  @Selector()
  public static propositionsDetailed(stateModel: CubeStateModel) {
    return stateModel.propositionsDetailed;
  }

  @Selector()
  public static publishingNoteToUpdate(stateModel: CubeStateModel) {
    return stateModel.publishingNoteToUpdate;
  }

  @Selector()
  public static cubeWorkers(stateModel: CubeStateModel) {
    return stateModel.workers;
  }

  @Selector()
  public static selectableChapters(stateModel: CubeStateModel) {
    return stateModel.selectableChapters;
  }

  @Selector()
  public static selectableCompanies(stateModel: CubeStateModel) {
    return stateModel.selectableCompanies;
  }


  public constructor(
    private tableOfContentService: TableOfContentService,
    private publishingNoteService: PublishingNoteService,
    private store: Store,
    private toastr: ToastrService,
    private translationService: TranslationService
  ) { }

  @Action(ClearCubeStateAction)
  public clearCubeStateAction(
    ctx: StateContext<CubeStateModel>,
    action: ClearCubeStateAction
  ): void {

    ctx.patchState({
      // cubes: [],
      cubesAuthorization: [],
      cubeAuthorization: null,
      detailedCube: null,
      detailedCubeSimple: null,
      tableOfContent: [],
      selectedChapter: null,
      selectedBehaviour: null,
      flattenedCubeChapters: [],
      propositions: [],
      openedChapterTranslation: null,
      openedBehaviourTranslation: null,
      openedQuestionEdition: null,
      openedQuestionPreview: null,
      publishingNoteToUpdate: null
    });
  }


  @Action(GetCubesAction)
  public getCubesAction(
    ctx: StateContext<CubeStateModel>,
    action: GetCubesAction
  ): void {
    this.tableOfContentService
      .getCubes(action.cubeSearchModel)
      .subscribe(cubes => {
        ctx.patchState({ cubes: cubes });
      });
  }

  @Action(GetCubesAuthorizationAction)
  public getCubesAuthorizationAction(
    ctx: StateContext<CubeStateModel>,
    action: GetCubesAuthorizationAction
  ): void {
    this.tableOfContentService
      .getCubesAuthorization(action.cubeSearchModel)
      .subscribe(cubes => {
        ctx.patchState({ cubesAuthorization: cubes });
      });
  }

  @Action(GetCubeAuthorizationAction)
  public getCubeAuthorizationAction(
    ctx: StateContext<CubeStateModel>,
    action: GetCubeAuthorizationAction
  ): void {
    this.tableOfContentService
      .getCubesAuthorization(new CubeSearchModel())
      .subscribe(cubes => {
        ctx.patchState({ cubeAuthorization: cubes.filter(c => c.id === action.id)[0] });
      });
  }

  @Action(GetDetailedCubeAndLoadBehaviorAction)
  public getDetailedCubeAndLoadBehaviorAction(
    ctx: StateContext<CubeStateModel>,
    action: GetDetailedCubeAndLoadBehaviorAction
  ): void {
    this.tableOfContentService
      .getCubeDetails(action.cubeId)
      .pipe(
        switchMap(cube => {
          const tableOfContentNodes = this.tableOfContentService.getTableOfContentNodes(cube.tableOfContent);
          const flattenedCubeChapters = this.tableOfContentService.flattenCubeChapters(cube.tableOfContent.chapters);
          const selectedChapter = flattenedCubeChapters.find(c => c.id === action.chapterId);
          const selectedBehavior = selectedChapter?.behaviours.find(b => b.id === action.behaviorId) ?? null;

          ctx.patchState({
            detailedCube: cube,
            tableOfContent: tableOfContentNodes,
            flattenedCubeChapters: flattenedCubeChapters,
            selectedChapter: selectedChapter,
            selectedBehaviour: selectedBehavior
          });
          return this.store.dispatch(new SetTitleAction(this.store.selectSnapshot(CubeState.detailedCube).name));
        })
      )
      .subscribe();
  }

  @Action(GetDetailedCubeAction)
  public getDetailedCubeAction(
    ctx: StateContext<CubeStateModel>,
    action: GetDetailedCubeAction
  ): void {
    this.tableOfContentService
      .getCubeDetails(action.cubeId)
      .pipe(
        switchMap(cube => {
          const tableOfContentNodes = this.tableOfContentService.getTableOfContentNodes(cube.tableOfContent);
          const flattenedCubeChapters = this.tableOfContentService.flattenCubeChapters(cube.tableOfContent.chapters);
          const selectableChapters = cube.tableOfContent.chapters.map(chapter => toSelectableChapter(chapter));
          ctx.patchState({
            detailedCube: cube,
            tableOfContent: tableOfContentNodes,
            flattenedCubeChapters: flattenedCubeChapters,
            selectableChapters: selectableChapters
          });
          if (action.titlePrefixe) {
            return this.store.dispatch(new SetTitleAction(action.titlePrefixe + this.store.selectSnapshot(CubeState.detailedCube).name));
          } else {
            return this.store.dispatch(new SetTitleAction(this.store.selectSnapshot(CubeState.detailedCube).name));
          }
        })
      )
      .subscribe();
  }

  @Action(AddCubeAction)
  public addCubeAction(
    ctx: StateContext<CubeStateModel>,
    action: AddCubeAction
  ): void {
    this.tableOfContentService
      .addCube(action.cube)
      .subscribe(cube => {
        const cubeState = ctx.getState();
        ctx.patchState({ cubesAuthorization: [...cubeState.cubesAuthorization, cube] });
      });
  }


  @Action(EditCubeAction)
  public editCubeAction(
    ctx: StateContext<CubeStateModel>,
    action: EditCubeAction
  ): void {
    this.tableOfContentService.updateCube(action.cube).subscribe(cube => {
      const cubes = _.cloneDeep(ctx.getState().cubesAuthorization);
      const cubeIndex = cubes.findIndex(x => x.id === cube.id);
      cubes[cubeIndex] = cube;
      ctx.patchState({ cubesAuthorization: cubes });
    });
  }

  @Action(AddChapterAction)
  public addChapterAction(
    ctx: StateContext<CubeStateModel>,
    action: AddChapterAction
  ): void {
    this.tableOfContentService
      .addChapter(action.cubeId, action.chapter, action.parentId)
      .pipe(
        tap(chapter => {
          const detailedAction = new GetDetailedCubeAction(action.cubeId);
          this.store.dispatch(detailedAction);
          ctx.patchState({ selectedChapter: chapter });
        })
      )
      .subscribe();
  }

  @Action(DeleteChapterAction)
  public deleteChapterAction(
    ctx: StateContext<CubeStateModel>,
    action: DeleteChapterAction
  ): void {
    this.tableOfContentService
      .deleteChapter(action.cubeId, action.chapterId)
      .pipe(
        tap(() => {
          const detailedAction = new GetDetailedCubeAction(action.cubeId);
          this.store.dispatch(detailedAction);
          ctx.patchState({ selectedChapter: null });
        })
      )
      .subscribe();
  }

  @Action(MoveChapterAction)
  public moveChapterAction(
    ctx: StateContext<CubeStateModel>,
    action: MoveChapterAction
  ): void {
    this.tableOfContentService
      .moveChapter(
        action.cubeId,
        action.chapterId,
        action.order,
        action.parentId
      )
      .pipe(
        tap(() => {
          const detailedAction = new GetDetailedCubeAction(action.cubeId);
          this.store.dispatch(detailedAction);
        })
      )
      .subscribe();
  }

  @Action(MoveBehaviourAction)
  public moveBehaviourAction(
    ctx: StateContext<CubeStateModel>,
    action: MoveBehaviourAction
  ): void {
    this.tableOfContentService
      .moveBehaviour(
        action.cubeId,
        action.oldParentChapterId,
        action.behaviourId,
        action.newParentChapterId,
        action.order
      )
      .pipe(
        tap(() => {
          const detailedAction = new GetDetailedCubeAction(action.cubeId);
          this.store.dispatch(detailedAction);
        })
      )
      .subscribe();
  }


  @Action(UpdateChapterAction)
  public updateChapterAction(
    ctx: StateContext<CubeStateModel>,
    action: UpdateChapterAction
  ): void {
    this.tableOfContentService
      .updateChapter(action.cubeId, action.chapter)
      .pipe(
        tap(chapter => {
          const detailedAction = new GetDetailedCubeAction(action.cubeId);
          this.store.dispatch(detailedAction);
          ctx.patchState({ selectedChapter: chapter });
        })
      )
      .subscribe();
  }

  @Action(AddBehaviourAction)
  public addBehaviourAction(
    ctx: StateContext<CubeStateModel>,
    action: AddBehaviourAction
  ): void {
    this.tableOfContentService
      .addBehaviour(action.cubeId, action.chapterId, action.behaviour)
      .pipe(
        tap(behaviour => {
          const detailedAction = new GetDetailedCubeAction(action.cubeId);
          this.store.dispatch(detailedAction);
          ctx.patchState({ selectedBehaviour: behaviour });
        })
      )
      .subscribe();
  }

  @Action(UpdateBehaviourAction)
  public updateBehaviourAction(
    ctx: StateContext<CubeStateModel>,
    action: UpdateBehaviourAction
  ): void {
    this.tableOfContentService
      .updateBehaviour(action.cubeId, action.chapterId, action.behaviour)
      .pipe(
        tap(behaviour => {
          // TODO : Renaud : modification faite pour régler les problèmes de performance #1706
          // est-il nécessaire de recharger le cube à chaque modification ?
          // const detailedAction = new GetDetailedCubeAction(action.cubeId);
          // this.store.dispatch(detailedAction);
           ctx.patchState({ selectedBehaviour: behaviour });
        })
      )
      .subscribe();
  }

  @Action(DeleteBehaviourAction)
  public deleteBehaviourAction(
    ctx: StateContext<CubeStateModel>,
    action: DeleteBehaviourAction
  ): void {
    this.tableOfContentService
      .deleteBehaviour(action.cubeId, action.chapterId, action.behaviour)
      .pipe(
        tap(() => {
          const detailedAction = new GetDetailedCubeAction(action.cubeId);
          this.store.dispatch(detailedAction);
          ctx.patchState({ selectedBehaviour: null });
        })
      )
      .subscribe();
  }

  @Action(SelectChapterAction)
  public selectChapterAction(
    ctx: StateContext<CubeStateModel>,
    action: SelectChapterAction
  ): void {
    ctx.patchState({ selectedChapter: action.chapter });
  }

  @Action(SelectBehaviourAction)
  public selectBehaviourAction(
    ctx: StateContext<CubeStateModel>,
    action: SelectBehaviourAction
  ): void {
    ctx.patchState({ selectedBehaviour: action.behaviour });
  }


  @Action(EditQuestionAction)
  public selectQuestionAction(
    ctx: StateContext<CubeStateModel>,
    action: EditQuestionAction
  ): void {
    ctx.patchState({ openedQuestionEdition: action.question });
  }



  @Action(OpenPreviewQuestionAction)
  public openPreviewQuestionAction(
    ctx: StateContext<CubeStateModel>,
    action: OpenPreviewQuestionAction
  ): void {


    const behavior = ctx.getState().selectedBehaviour;

    const originQuestion = action.question;

    const previewQuestion: QuestionToAnswer = {
      behaviorName: behavior.name,
      content: originQuestion.content,
      title: originQuestion.title,
      name: originQuestion.name,
      answers: originQuestion.answers.map(a => {
        const previewAnswer: MCQAnswerPresentation = {
          id: '',
          content: a.content,
          isSelected: false
        };
        return previewAnswer;
      }),
      expectedCorrectAnswers: originQuestion.answers.reduce((acc, a) => acc + ((a.isCorrect) ? 1 : 0), 0)
    };



    ctx.patchState({ openedQuestionPreview: previewQuestion });
  }


  @Action(ClosePreviewQuestionAction)
  public closePreviewQuestionAction(
    ctx: StateContext<CubeStateModel>,
    action: ClosePreviewQuestionAction
  ): void {
    ctx.patchState({ openedQuestionPreview: null });
  }


  @Action(CloseQuestionAction)
  public closeQuestionAction(
    ctx: StateContext<CubeStateModel>,
    action: CloseQuestionAction
  ): void {
    ctx.patchState({ openedQuestionEdition: null, openedQuestionPreview: null });
  }


  @Action(DeleteQuestionFromBehaviorAction)
  public deleteQuestionFromBehaviorAction(
    ctx: StateContext<CubeStateModel>,
    action: DeleteQuestionFromBehaviorAction
  ): void {
    const detailedCube = ctx.getState().detailedCube;
    const chapter = ctx.getState().selectedChapter;
    const behavior = ctx.getState().selectedBehaviour;
    const indexQuestion = behavior.questions.indexOf(action.question);
    const cloneBehavior = _.cloneDeep(behavior) as Behaviour;
    cloneBehavior.questions.splice(indexQuestion, 1);
    this.store.dispatch(new UpdateBehaviourAction(detailedCube.id, chapter.id, cloneBehavior));
  }

  @Action(AddQuestionToBehaviorAction)
  public addQuestionAction(
    ctx: StateContext<CubeStateModel>,
    action: AddQuestionToBehaviorAction
  ): void {

    // const cloneBehavior = _.cloneDeep(ctx.getState().selectedBehaviour) as Behaviour;
    const newQuestion = createQuestion({ order: ctx.getState().selectedBehaviour.questions.length });
    // cloneBehavior.questions.push(newQuestion);
    //    ctx.patchState({ openedQuestionEdition: newQuestion, selectedBehaviour: cloneBehavior });
    ctx.patchState({ openedQuestionEdition: newQuestion });
  }


  @Action(UpdateQuestionAction)
  public updateQuestionAction(
    ctx: StateContext<CubeStateModel>,
    action: UpdateQuestionAction
  ): void {

    const cubeId = ctx.getState().detailedCube.id;
    const chapterId = ctx.getState().selectedChapter.id;
    const behaviorId = ctx.getState().selectedBehaviour.id;


    this.tableOfContentService
      .updateOrCreateQuestion(cubeId, chapterId, behaviorId, action.question)
      .subscribe(a => {
        // const detailedAction = new GetDetailedCubeAction(cubeId);
        // this.store.dispatch(detailedAction);
        const reloadAction = new GetDetailedCubeAndLoadBehaviorAction(cubeId, chapterId, behaviorId);
        this.store.dispatch(reloadAction);
      });

    // clear question selection
    ctx.patchState({ openedQuestionEdition: null, openedQuestionPreview: null });
  }




  @Action(ShowBehaviourPropositionsAction)
  public showBehaviourPropositionsAction(
    ctx: StateContext<CubeStateModel>,
    action: ShowBehaviourPropositionsAction
  ): void {

    this.tableOfContentService.getPropositionFromBehaviour(action.cubeId, action.behaviorId)
      .subscribe(propositions => {
        const cubeState = ctx.getState();
        ctx.patchState({ propositions: propositions });
      });
  }
  @Action(UpdatePropositionAndRefreshBehaviourPropositionsAction)
  public updateStatusBehaviourPropositionsAction(
    ctx: StateContext<CubeStateModel>,
    action: UpdatePropositionAndRefreshBehaviourPropositionsAction
  ): void {

    this.tableOfContentService.updatePropositionStatus(action.cubeId, action.behaviorId, action.model)
      .subscribe(proposition => {
        const showBehaviourPropositionsAction
          = new ShowBehaviourPropositionsAction(action.cubeId, action.behaviorId);

        this.store.dispatch(showBehaviourPropositionsAction);
      });
  }

  @Action(UpdatePropositionAndRefreshPropositionsDetailedAction)
  public updatePropositionAndRefreshAll(
    ctx: StateContext<CubeStateModel>,
    action: UpdatePropositionAndRefreshPropositionsDetailedAction
  ): void {

    this.tableOfContentService.updatePropositionStatus(action.cubeId, action.behaviorId, action.model)
      .subscribe(proposition => {
        const propositions: PropositionModelDetail[] = _.cloneDeep(ctx.getState().propositionsDetailed);
        const propositionIndex = propositions.findIndex(x => x.id === action.model.id);
        propositions[propositionIndex].status = action.model.status;
        propositions[propositionIndex].comment = action.model.comment;
        ctx.patchState({ propositionsDetailed: propositions });
      });
  }

  @Action(DismissBehaviourPropositionsAction)
  public dismissBehaviourPropositionsAction(
    ctx: StateContext<CubeStateModel>,
    action: DismissBehaviourPropositionsAction
  ): void {
    ctx.patchState({ propositions: [] });
  }


  @Action(AddPropositionAction)
  public addPropositionAction(
    ctx: StateContext<CubeStateModel>,
    action: AddPropositionAction
  ): void {
    this.tableOfContentService
      .addProposition(action.proposition)
      .subscribe(() => this.toastr.success('RuleSuggestionSent'));
  }

  @Action(GetChapterTranslationAction)
  public getChapterTranslationAction(
    ctx: StateContext<CubeStateModel>,
    action: GetChapterTranslationAction
  ): void {

    this.tableOfContentService
      .getChapterTranslation(action.cubeId, action.chapterId, action.languageId)
      .subscribe(chapterTranslation =>
        ctx.patchState({ openedChapterTranslation: chapterTranslation })
      );
  }

  @Action(UpdateChapterTranslationAction)
  public updateChapterTranslationAction(
    ctx: StateContext<CubeStateModel>,
    action: UpdateChapterTranslationAction
  ): void {

    this.tableOfContentService
      .updateChapterTranslation(action.cubeId, action.chapterId, action.translatedChapter)
      .subscribe(() => this.toastr.success(this.translationService.translate('ChapterTranslationUpdated')));
  }

  @Action(GetBehaviourTranslationAction)
  public getBehaviourTranslationAction(
    ctx: StateContext<CubeStateModel>,
    action: GetBehaviourTranslationAction
  ): void {

    this.tableOfContentService
      .getBehaviourTranslation(action.cubeId, action.chapterId, action.behaviourId, action.languageId)
      .subscribe(behaviourTranslation =>
        ctx.patchState({ openedBehaviourTranslation: behaviourTranslation })
      );
  }

  @Action(UpdateBehaviourTranslationAction)
  public updateBehaviourTranslationAction(
    ctx: StateContext<CubeStateModel>,
    action: UpdateBehaviourTranslationAction
  ): void {
    this.tableOfContentService
      .updateBehaviourTranslation(action.cubeId, action.chapterId, action.behaviourId, action.translatedBehaviour)
      .subscribe(() => this.toastr.success(this.translationService.translate('BehaviourTranslationUpdated')));
  }

  @Action(UpdateCoverAction)
  public updateCoverAction(
    ctx: StateContext<CubeStateModel>,
    action: UpdateCoverAction
  ): void {
    this.tableOfContentService
      .updateCover(action.cubeId, action.cover)
      .pipe(
        tap(cover => {
          const cube = _.cloneDeep(ctx.getState().detailedCube);
          ctx.patchState({ detailedCube: {
            ...cube,
            cover: cover
          }});
        }),
        tap(cover => {
          if (action.file) {
            this.store.dispatch(new AddImageToCover(action.cubeId, action.file));
          }
        })
      )
      .subscribe();
  }

  @Action(GetCoverTranslationAction)
  public getCoverTranslationAction(
    ctx: StateContext<CubeStateModel>,
    action: GetCoverTranslationAction
  ): void {

    this.tableOfContentService
      .getCoverTranslation(action.cubeId, action.languageId)
      .subscribe(coverTranslation =>
        ctx.patchState({ openedCoverTranslation: coverTranslation })
      );
  }

  @Action(UpdateCoverTranslationAction)
  public updateCoverTranslationAction(
    ctx: StateContext<CubeStateModel>,
    action: UpdateCoverTranslationAction
  ): void {

    this.tableOfContentService
      .updateCoverTranslation(action.cubeId, action.translatedCover)
      .subscribe(() => this.toastr.success(this.translationService.translate('CoverTranslationUpdated')));
  }

  @Action(AddImageToCover)
  public addImageToCover(
    ctx: StateContext<CubeStateModel>,
    action: AddImageToCover
  ): void {
    this.tableOfContentService
      .addImageToCover(action.file, action.cubeId)
      .subscribe(logo => {
        const cube: CubeDetailed = _.cloneDeep(ctx.getState().detailedCube);
        const cover = cube.cover;
        cover.logo = logo;
        ctx.patchState({ detailedCube: {
          ...cube,
          cover: cover
        }});
      });
  }

  @Action(GetPropositionsAction)
  public getPropositions(
    ctx: StateContext<CubeStateModel>,
    action: GetPropositionsAction
  ): void {

    this.tableOfContentService
      .getPropositions()
      .subscribe(propositions =>
        ctx.patchState({ propositionsDetailed: propositions })
      );
  }

  @Action(GetPublishingNoteToUpdateAction)
  public getPublishingNoteToUpdateAction(
    ctx: StateContext<CubeStateModel>,
    action: GetPublishingNoteToUpdateAction
  ): void {
    this.publishingNoteService.getPublishingNoteDetailed(action.publishingNoteId)
      .subscribe(publishingNote => {
        const selectableWorkers =
          ctx.getState().workers.map(worker => toSelectableWorker(worker, publishingNote.selectedWorkerIds.some(x => x === worker.id)));
          const selectableCompanies = ctx.getState().selectableCompanies.map(company => {
            return {...company, selected: publishingNote.selectedCompanyIds.some(x => x === company.id)};
          });
        ctx.patchState({ publishingNoteToUpdate:
          {...publishingNote, selectableWorkers: selectableWorkers, selectableCompanies: selectableCompanies} });
      });
  }

  @Action(GetCubeWorkersAction)
  public getCubeWorkersAction(
    ctx: StateContext<CubeStateModel>,
    action: GetCubeWorkersAction
  ): void {

    this.tableOfContentService
      .getCubeWorkers(action.cubeId)
      .subscribe(workers =>
        ctx.patchState({ workers: workers })
      );
  }

  @Action(GetSelectableCompaniesByCube)
  public getSelectableCompanies(ctx: StateContext<CubeStateModel>, action: GetSelectableCompaniesByCube): void {
    this.tableOfContentService.getSelectableCompanies(action.cubeId)
      .subscribe((x) => ctx.patchState({ selectableCompanies: x }));
  }

  // @Action(SelectCubesAction)
  // public selectCubeAction(
  //   ctx: StateContext<CubeStateModel>,
  //   action: SelectCubesAction
  // ): void {
  //   ctx.patchState({ selectedCubesAuthorization: action.cubes });
  // }

}
