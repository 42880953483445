import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { StructuralNodeType, DetailedStructuralNodeType } from '@models/structural-node-type/structural-node-type';
import { StructuralNodeLabel, DetailedStructuralNodeLabel } from '@models/structural-node-type/structural-node-label';
import { generateSpace } from '@shared/utils/utils';
import { SelectableStructuralNodeType, SelectableStructuralNodeLabel } from '@models/structural-node-type/selectable-structural-node-type';

@Injectable({
  providedIn: 'root'
})
export class StructuralNodeTypeService {
  private entityUrl = 'structuralnodetypes/';
  private labelUrl = '/labels/';
  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public getStructuralNodeTypes(): Observable<DetailedStructuralNodeType[]> {
    return this.sintenoHttpService.get<DetailedStructuralNodeType[]>(this.entityUrl);
  }

  public getStructuralNodeType(id: string): Observable<DetailedStructuralNodeType> {
    return this.sintenoHttpService.get<DetailedStructuralNodeType>(this.entityUrl + id);
  }

  public addStructuralNodeType(structuralNodeType: StructuralNodeType): Observable<StructuralNodeType> {
    return this.sintenoHttpService.post<StructuralNodeType>(this.entityUrl, structuralNodeType);
  }

  public updateStructuralNodeType(structuralNodeType: StructuralNodeType): Observable<StructuralNodeType> {
    return this.sintenoHttpService.put<StructuralNodeType>(this.entityUrl + structuralNodeType.id, structuralNodeType);
  }

  public getStructuralNodeLabel(id: string, labelId: string): Observable<StructuralNodeLabel> {
    return this.sintenoHttpService.get<StructuralNodeLabel>(`this.entityUrl${id}${this.labelUrl}${labelId}`);
  }

  public getFlatLabels(labels: Array<DetailedStructuralNodeLabel>, depth: number): DetailedStructuralNodeLabel[] {
    let labelList = new Array<DetailedStructuralNodeLabel>();

    labels.forEach(label => {
      const newLabel = new DetailedStructuralNodeLabel();
      newLabel.id = label.id;
      newLabel.name = generateSpace(depth) + label.name;
      labelList.push(newLabel);

      if (label.labels != null && label.labels.length > 0) {
        labelList = labelList.concat(this.getFlatLabels(label.labels, depth + 1));
      }
    });

    return labelList;
  }

  public generateSelectableStructuralNode(structuralNodes: DetailedStructuralNodeType[]): SelectableStructuralNodeType[] {
    const selectableProperties = [];
    structuralNodes.forEach(structuralNode => {
      const selectableStructuralNode = new SelectableStructuralNodeType();
      selectableStructuralNode.id = structuralNode.id;
      selectableStructuralNode.name = structuralNode.name;
      selectableStructuralNode.labels = [];
      structuralNode.labels.forEach(structuralNodeLabel => {
        selectableStructuralNode.labels.push(this.generateSelectableStructuralNodeLabel(structuralNodeLabel));
      });
      selectableProperties.push(selectableStructuralNode);
    });
    return selectableProperties;
  }

  public generateSelectableStructuralNodeLabel(structuralNodeLabel: DetailedStructuralNodeLabel): SelectableStructuralNodeLabel {
    const selectableStructuralNodeLabel = new SelectableStructuralNodeLabel();
    selectableStructuralNodeLabel.id = structuralNodeLabel.id;
    selectableStructuralNodeLabel.name = structuralNodeLabel.name;
    selectableStructuralNodeLabel.selected = false;
    selectableStructuralNodeLabel.labels = [];
    structuralNodeLabel.labels.forEach(x => {
      selectableStructuralNodeLabel.labels.push(this.generateSelectableStructuralNodeLabel(x));
    });
    return selectableStructuralNodeLabel;
  }
}
