import { Injectable } from '@angular/core';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { Observable } from 'rxjs';
import { Language } from '@shared/store/language/language-view-model';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private entityUrl = 'languages/';

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public getLanguages(isSystem: boolean): Observable<Language[]> {
    let params = new HttpParams();
    params = params.set('isSystem', isSystem.toString());
    return this.sintenoHttpService.get<Array<Language>>(this.entityUrl, {params});
  }
}
