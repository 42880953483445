<div class="card">
  <div class="card-header">
    <div class="card-title">
      <h2>
        <ng-container i18n="Text followed by a date@@InformationAboutTheAuditOf">Informations about the audit made on
        </ng-container> {{theoreticalEvaluation.date | dateFormat}}
      </h2>
    </div>
  </div>

    <app-theoretical-evaluation-detail [theoreticalEvaluationService]="theoreticalEvaluation">
    </app-theoretical-evaluation-detail>

  <div class="card-footer">
    <div class="form-group text-center">
      <button type="button" class="button cancelButton" (click)="close()" i18n="@@Close">Close</button>
    </div>
  </div>
</div>
