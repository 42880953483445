import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import * as _ from 'lodash';
import { StructuralNodeType, DetailedStructuralNodeType } from '@models/structural-node-type/structural-node-type';
import { AddStructuralNodeType, UpdateStructuralNodeType, GetStructuralNodeTypes, GetStructuralNodeTypeAndLabels,
  GetStructuralNodeLabel } from './structural-node-type.action';
import { StructuralNodeTypeService } from '@shared/services/structural-node-type.service';
import { StructuralNodeLabel, DetailedStructuralNodeLabel } from '@models/structural-node-type/structural-node-label';

export interface StructuralNodeTypeStateModel {
  structuralNodeTypes: DetailedStructuralNodeType[];
  structuralNodeType: DetailedStructuralNodeType;
  structuralNodeLabels: DetailedStructuralNodeLabel[];
  structuralNodeLabel: StructuralNodeLabel;
}

@State<StructuralNodeTypeStateModel>({
    name: 'structuralNodeType',
    defaults: {
        structuralNodeTypes: [],
        structuralNodeType: undefined,
        structuralNodeLabels: [],
        structuralNodeLabel: undefined
    }
})
export class StructuralNodeTypeState {
    @Selector()
    public static structuralNodeTypes(stateModel: StructuralNodeTypeStateModel): DetailedStructuralNodeType[] {
        return stateModel.structuralNodeTypes;
    }

    @Selector()
    public static structuralNodeType(stateModel: StructuralNodeTypeStateModel): DetailedStructuralNodeType {
        return stateModel.structuralNodeType;
    }

    @Selector()
    public static structuralNodeLabels(stateModel: StructuralNodeTypeStateModel): StructuralNodeLabel[] {
        return stateModel.structuralNodeLabels;
    }


    public constructor(private structuralNodeTypeService: StructuralNodeTypeService, private store: Store) { }

    @Action(GetStructuralNodeTypes)
    public getStructuralNodeTypes(ctx: StateContext<StructuralNodeTypeStateModel>, action: GetStructuralNodeTypes): void {
        this.structuralNodeTypeService.getStructuralNodeTypes().subscribe((structuralNodeTypes) => {
            ctx.patchState({ structuralNodeTypes: structuralNodeTypes });
        });
    }

    @Action(GetStructuralNodeTypeAndLabels)
    public getStructuralNodeTypeAndLabels(ctx: StateContext<StructuralNodeTypeStateModel>, action: GetStructuralNodeTypeAndLabels): void {
        this.structuralNodeTypeService.getStructuralNodeType(action.id).subscribe((structuralNodeType) => {
            ctx.patchState({ structuralNodeType: structuralNodeType });
            ctx.patchState({ structuralNodeLabels: structuralNodeType.labels });
        });
    }

    @Action(AddStructuralNodeType)
    public addStructuralNodeType(ctx: StateContext<StructuralNodeTypeStateModel>, action: AddStructuralNodeType): void {
        this.structuralNodeTypeService.addStructuralNodeType(action.structuralNodeType).
          subscribe(() => this.store.dispatch(new GetStructuralNodeTypes()));
    }

    @Action(UpdateStructuralNodeType)
    public updateStructuralNodeType(ctx: StateContext<StructuralNodeTypeStateModel>, action: UpdateStructuralNodeType): void {
        this.structuralNodeTypeService.updateStructuralNodeType(action.structuralNodeType).
          subscribe(() => this.store.dispatch(new GetStructuralNodeTypes()));
    }

    @Action(GetStructuralNodeLabel)
    public getStructuralNodeLabel(ctx: StateContext<StructuralNodeTypeStateModel>, action: GetStructuralNodeLabel): void {
        this.structuralNodeTypeService.getStructuralNodeLabel(action.id, action.labelId).subscribe((structuralNodeLabel) => {
            ctx.patchState({ structuralNodeLabel: structuralNodeLabel });
        });
    }
}
