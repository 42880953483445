import { QuestionPresentation } from '../cube/models/question-presentation.model';

export class GetWorkerFormations {
  public static readonly type = '[Formation] Get worker formations';

  public constructor(public workerId: string) { }
}

export class GetWorkerFormationsStatistics {
  public static readonly type = '[Formation] Get worker formations statistics';

  public constructor(public workerId: string) { }
}

export class GetFormationDetail {
  public static readonly type = '[Formation] Get formation Detail';
  public constructor(public workerId: string, public formationId: string, public languageCode: string) { }
}

export class CloseFormationDetail {
  public static readonly type = '[Formation] Close formation Detail';
}

export class GetQuestionForSelectedChapter {
  public static readonly type = '[Formation] Get queston';

}

export class AddWorkerFormation {
  public static readonly type = '[Formation] Add formation to worker';

  public constructor(public workerId: string, public cubeId: string) { }
}


export class AnswerQuestion {
  public static readonly type = '[Formation] answer Question';

  public constructor(public questionAnswered: QuestionPresentation) { }
}

export class ChangeFormationChapterLecture {
  public static readonly type = '[Formation] Change chapter lecture';

  public constructor(public workerId: string, public formationId: string, public languageCode: string, public previousChapterId: string,
    public currentChapterId: string, public isFinished: boolean, public selectCover: boolean) { }
}

export class DeleteWorkerFormation {
  public static readonly type = '[Formation] Delete formation of worker';

  public constructor(public workerId: string, public formationId: string) { }
}

export class SetFormationLanguage {
  public static readonly type = '[Formation] Change Language of this cube formations';

  public constructor(public workerId: string, public formationId: string, public languageId: string) { }
}


export class GoNextQuestion {
  public static readonly type = '[Formation] Change question';
}

export class GoBackToFormationChapter {

  public static readonly type = '[Formation] read chapter';
}


export class GetAllWorkersFormationStatistics {
  public static readonly type = '[Formation] All worker';
}

export class SelectCover {
  public static readonly type = '[Formation] Select Cover';
  public constructor() {}
}

export class CloseCover {
  public static readonly type = '[Formation] Close Cover';
  public constructor() {}
}

export class SeedFormation {
  public static readonly type = '[Formation] Seed Formation';
  public constructor(public id: string, public percentage: number) {}
}

export class GetCurrentUserTrainingStatistics {
  public static readonly type = '[Statistics] Get current user training statistics';
}

export class GetFormationStatistics {
  public static readonly type = '[Formation] Get formation Statistics';
  public constructor(public workerId: string, public formationId: string) { }
}

