import { ExtendedPropertyValue } from './extended-property-value';
import { ExtendedProperty } from './extended-property';

export class SelectableExtendedProperty extends ExtendedProperty {
  public extendedPropertyValues: SelectableExtendedPropertyValue[];
}

export class SelectableExtendedPropertyValue extends ExtendedPropertyValue {
  public values: SelectableExtendedPropertyValue[];
  public selected: boolean;
}
