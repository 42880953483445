import { DetailedStructuralNodeLabel } from './structural-node-label';

export class StructuralNodeType {
  public id: string;
  public name: string;
}

export class DetailedStructuralNodeType extends StructuralNodeType {
  public labels: DetailedStructuralNodeLabel[];
}
