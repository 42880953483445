import { PropositionModel, UpdateStatusPropositionModel } from './../store/audit/audit.model';
import { Injectable } from '@angular/core';
import { SintenoHttpService } from '@services/sinteno-http.service';
import { TableOfContent } from '../store/cube/models/table-of-content.model';
import { Observable } from 'rxjs';
import { HttpOptionsCustom } from '@models/http/http-options.model';
import { HttpParams } from '@angular/common/http';
import { Chapter } from '../store/cube/models/chapter.model';
import { MoveChapterModel } from '@models/cube/move-chapter.model';
import { Behaviour } from '../store/cube/models/behaviour.model';
import { TableOfContentNode } from '../store/cube/models/table-of-content-node.model';
import { Cube, CubeDetailed, CubeAuthorization } from '../store/cube/models/cube.model';
import { CubeSearchModel } from '@models/cube/cube-search-model';
import { CreatePropositionModel, PropositionModelDetail } from '@models/cube/proposition';
import { PublishingNote } from '@models/publishing-note/publishing-note.model';
import { TranslatedChapter } from '@shared/store/cube/models/translated-chapter.model';
import { TranslatedBehaviour } from '@shared/store/cube/models/translated-behaviour.model';
import { Question } from '@shared/store/cube/models/question.model';
import { Cover } from '@shared/store/cube/models/cover.model';
import { TranslatedCover } from '@shared/store/cube/models/translated-cover.model';
import { Media } from 'src/app/admin/media/models/media';
import { MoveBehaviourModel } from '@models/cube/move-behaviour.model';
import { Worker } from '@shared/store/worker/worker.model';
import { SelectableCompany } from '@shared/store/company/selectable-company.model';

@Injectable({
  providedIn: 'root'
})
export class TableOfContentService {
  private entityUrl = 'cubes/';
  private chaptersEntityName = 'chapters';
  private behavioursEntityName = 'behaviours';
  private questionsEntityName = 'questions';
  private languagesEntityName = 'languages';
  private publishingNotesEntityName = 'publishingnotes';
  private coverEntityName = 'cover';
  private workerEntityName = 'workers';

  public constructor(private sintenoHttpService: SintenoHttpService) { }

  public getCubes(searchModel?: CubeSearchModel): Observable<Cube[]> {
    if (searchModel !== undefined) {
      const options = new HttpOptionsCustom();
      options.params = new HttpParams();
      if (searchModel.name !== undefined) {
        options.params = options.params.append('name', searchModel.name);
      }
      if (searchModel.creationDate !== undefined) {
        options.params = options.params.append('creationdate', `${searchModel.creationDate.getMonth() + 1}/
                         ${searchModel.creationDate.getDate()}/${searchModel.creationDate.getFullYear()}`);
      }
      return this.sintenoHttpService.get<Array<Cube>>(this.entityUrl, options);
    } else {
      return this.sintenoHttpService.get<Array<Cube>>(this.entityUrl);
    }
  }

  public getCubesAuthorization(searchModel?: CubeSearchModel): Observable<CubeAuthorization[]> {
    if (searchModel !== undefined) {
      const options = new HttpOptionsCustom();
      options.params = new HttpParams();
      if (searchModel.name !== undefined) {
        options.params = options.params.append('name', searchModel.name);
      }
      if (searchModel.creationDate !== undefined) {
        options.params = options.params.append('creationdate', `${searchModel.creationDate.getMonth() + 1}/
                         ${searchModel.creationDate.getDate()}/${searchModel.creationDate.getFullYear()}`);
      }
      return this.sintenoHttpService.get<Array<CubeAuthorization>>(`${this.entityUrl}authorizations`, options);
    } else {
      return this.sintenoHttpService.get<Array<CubeAuthorization>>(`${this.entityUrl}authorizations`);
    }
  }

  public getCubeDetails(id: string): Observable<CubeDetailed> {
    return this.sintenoHttpService.get<CubeDetailed>(`${this.entityUrl}${id}/details`);
  }

  public getTableOfContentNodes(tableOfContent: TableOfContent): TableOfContentNode[] {
    const tableOfContentNodes = [];
    tableOfContent.chapters.forEach(chapter => {
      tableOfContentNodes.push(TableOfContentNode.generateChapter(chapter));
    });
    return tableOfContentNodes;
  }

  public addCube(cube: CubeAuthorization) {
    return this.sintenoHttpService.post<CubeAuthorization>(this.entityUrl, cube);
  }

  public updateCube(model: Cube) {
    return this.sintenoHttpService.put<CubeAuthorization>(this.entityUrl + model.id, model);
  }

  public addChapter(cubeId: string, model: Chapter, parentId?: string): Observable<Chapter> {
    let url = this.entityUrl + cubeId + '/' + this.chaptersEntityName;
    if (parentId !== undefined) {
      url += `/${parentId}/${this.chaptersEntityName}`;
    }
    return this.sintenoHttpService.post<Chapter>(url, model);
  }

  public updateChapter(cubeId: string, model: Chapter): Observable<Chapter> {
    const url = this.entityUrl + cubeId + '/' + this.chaptersEntityName;
    return this.sintenoHttpService.put<Chapter>(url + '/' + model.id, model);
  }

  public addBehaviour(cubeId: string, chapterId: string, model: Behaviour): Observable<Behaviour> {
    const url = this.entityUrl + cubeId + '/' + this.chaptersEntityName
      + '/' + chapterId + '/' + this.behavioursEntityName;
    return this.sintenoHttpService.post<Behaviour>(url, model);
  }

  public updateBehaviour(cubeId: string, chapterId: string, model: Behaviour): Observable<Behaviour> {
    const url = this.entityUrl + cubeId + '/' + this.chaptersEntityName
      + '/' + chapterId + '/' + this.behavioursEntityName + '/' + model.id;
    return this.sintenoHttpService.put<Behaviour>(url, model);
  }

  public deleteBehaviour(cubeId: string, chapterId: string, model: Behaviour): Observable<Behaviour> {
    const url = this.entityUrl + cubeId + '/' + this.chaptersEntityName
      + '/' + chapterId + '/' + this.behavioursEntityName + '/' + model.id;
    return this.sintenoHttpService.delete<Behaviour>(url);
  }


  public updateOrCreateQuestion(cubeId: string, chapterId: string, behaviourId: string, model: Question): Observable<string> {

    const url = this.entityUrl + cubeId + '/' + this.chaptersEntityName
      + '/' + chapterId + '/' + this.behavioursEntityName + '/' + behaviourId + '/' + this.questionsEntityName;
    return this.sintenoHttpService.put<string>(url, model);
  }

  public deleteChapter(cubeId: string, chapterId: string): Observable<any> {
    const url = this.entityUrl + cubeId + '/' + this.chaptersEntityName;
    return this.sintenoHttpService.delete<Chapter>(url + '/' + chapterId);
  }

  public moveChapter(cubeId: string, chapterId: string, order: number, newParentId?: string): Observable<Chapter> {
    const model: MoveChapterModel = {
      cubeId: cubeId,
      chapterId: chapterId,
      order: order
    };

    if (newParentId) {
      model.newParentChapterId = newParentId;
    }

    const url = this.entityUrl + cubeId + '/' + this.chaptersEntityName;
    return this.sintenoHttpService.put<Chapter>(url + '/' + chapterId + '/move', model);
  }

  public moveBehaviour(cubeId: string, oldParentChapterId: string,
    behaviourId: string, newParentChapterId: string, order: number): Observable<Behaviour> {
    const model: MoveBehaviourModel = {
      cubeId: cubeId,
      oldParentChapterId: oldParentChapterId,
      behaviourId: behaviourId,
      newParentChapterId: newParentChapterId,
      order: order
    };

    const url = this.entityUrl + cubeId + '/' + this.chaptersEntityName;
    return this.sintenoHttpService.put<Behaviour>(url + '/' + oldParentChapterId + '/behaviours/' + behaviourId + '/move', model);
  }

  public getPropositionFromBehaviour(cubeId: string, behaviorId: string): Observable<PropositionModel[]> {
    return this.sintenoHttpService.get<PropositionModel[]>(`${this.entityUrl}${cubeId}/propositions/${behaviorId}`);
  }

  public updatePropositionStatus(cubeId: string, behaviorId: string, updateStatusPropositionModel: UpdateStatusPropositionModel)
    : Observable<PropositionModel> {
    // tslint:disable-next-line:max-line-length
    return this.sintenoHttpService.post<PropositionModel>(`${this.entityUrl}${cubeId}/propositions/${behaviorId}`, updateStatusPropositionModel);
  }

  public flattenCubeChapters(chapters: Chapter[]): Chapter[] {
    let result: Chapter[] = [];
    chapters.forEach((chapter: Chapter) => {
      result.push(chapter);
      if (chapter.chapters && chapter.chapters.length > 0) {
        result = [...result, ...this.flattenCubeChapters(chapter.chapters)];
      }
    });
    return result;
  }

  public addProposition(proposition: CreatePropositionModel) {
    let url = `${this.entityUrl}${proposition.cubeId}/${this.chaptersEntityName}/`;
    url += `${proposition.chapterId}/${this.behavioursEntityName}/${proposition.behaviourId}/propositions`;
    return this.sintenoHttpService.post<CreatePropositionModel>(url, proposition);
  }

  public getChapterPublishingNotes(cubeId: string, chapterId: string): Observable<PublishingNote[]> {
    return this.sintenoHttpService.get<PublishingNote[]>(`${this.entityUrl}${cubeId}/${this.chaptersEntityName}/${chapterId}/${this.publishingNotesEntityName}`);
  }

  public getBehaviourPublishingNotes(cubeId: string, chapterId: string, behaviourId: string): Observable<PublishingNote[]> {
    return this.sintenoHttpService.get<PublishingNote[]>
      (`${this.entityUrl}${cubeId}/${this.chaptersEntityName}/${chapterId}/${this.behavioursEntityName}/${behaviourId}/${this.publishingNotesEntityName}`);
  }

  public getPublishingNotes(cubeId: string): Observable<PublishingNote[]> {
    return this.sintenoHttpService.get<PublishingNote[]>(`${this.entityUrl}${cubeId}/${this.publishingNotesEntityName}`);
  }

  public getChapterTranslation(cubeId: string, chapterId: string, languageId: string): Observable<TranslatedChapter> {
    return this.sintenoHttpService.get<TranslatedChapter>
      (`${this.entityUrl}${cubeId}/${this.chaptersEntityName}/${chapterId}/${this.languagesEntityName}/${languageId}`);
  }

  public updateChapterTranslation(cubeId: string, chapterId: string, translatedChapter: TranslatedChapter): Observable<any> {
    return this.sintenoHttpService.put<any>(
      `${this.entityUrl}${cubeId}/${this.chaptersEntityName}/${chapterId}/${this.languagesEntityName}/${translatedChapter.language.id}`
      , translatedChapter);
  }

  public getBehaviourTranslation(cubeId: string, chapterId: string, behaviourId: string, languageId: string)
    : Observable<TranslatedBehaviour> {
    return this.sintenoHttpService.get<TranslatedBehaviour>
      (`${this.entityUrl}${cubeId}/${this.chaptersEntityName}/${chapterId}/${this.behavioursEntityName}/${behaviourId}/${this.languagesEntityName}/${languageId}`);
  }

  public updateBehaviourTranslation(cubeId: string, chapterId: string, behaviourId: string, translatedBehaviour: TranslatedBehaviour)
    : Observable<any> {
    return this.sintenoHttpService.put<any>(
      `${this.entityUrl}${cubeId}/${this.chaptersEntityName}/${chapterId}/${this.behavioursEntityName}/${behaviourId}/${this.languagesEntityName}/${translatedBehaviour.language.id}`
      , translatedBehaviour);
  }

  public updateCover(cubeId: string, model: Cover): Observable<Cover> {
    const url = this.entityUrl + cubeId + '/' + this.coverEntityName;
    return this.sintenoHttpService.put<Cover>(url , model);
  }

  public getCoverTranslation(cubeId: string, languageId: string): Observable<TranslatedCover> {
    return this.sintenoHttpService.get<TranslatedCover>
      (`${this.entityUrl}${cubeId}/${this.coverEntityName}/${this.languagesEntityName}/${languageId}`);
  }

  public updateCoverTranslation(cubeId: string, translatedCover: TranslatedCover): Observable<any> {
    return this.sintenoHttpService.put<any>(
      `${this.entityUrl}${cubeId}/${this.coverEntityName}/${this.languagesEntityName}/${translatedCover.language.id}`
      , translatedCover);
  }

  public addImageToCover(file: File, cubeId: string): Observable<Media> {
    if (file) {
      const url = this.entityUrl + cubeId + '/' + this.coverEntityName + '/image';
      const formData = new FormData();
      formData.append(file.name, file);
      return this.sintenoHttpService.post<Media>(url, formData, { reportProgress: true });
    } else {
      return new Observable();
    }
  }

  public getPropositions(): Observable<PropositionModelDetail[]> {
    return this.sintenoHttpService.get<PropositionModelDetail[]>
      (`${this.entityUrl}propositions`);
  }

  public getCubeWorkers(cubeId: string): Observable<Worker[]> {
    return this.sintenoHttpService.get<Worker[]>
      (`${this.entityUrl}${cubeId}/${this.workerEntityName}`);
  }

  public getSelectableCompanies(cubeId: string): Observable<SelectableCompany[]> {
    return this.sintenoHttpService.get<SelectableCompany[]>(`${this.entityUrl}${cubeId}/selectablecompanies`);
  }
}
