import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { GetCurrentSession } from '@shared/store/session/session.action';
import { TranslationService } from '@shared/services/translation.service';

@Injectable()
export class SintenoHttpInterceptor implements HttpInterceptor {

  public constructor(private toastr: ToastrService, private router: Router, private store: Store,
    private translationService: TranslationService) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event) => { },
        (err) => {
          let errorMessage = '';
          if (err instanceof HttpErrorResponse) {
            const error = err.error;

            switch (err.status) {
              case 0:
                errorMessage = `There was an error while contacting the server. Please contact an administrator if this persists.`;
                this.toastr.error(errorMessage, 'Connectivity issue');
                return throwError(error);
              case 400:
                errorMessage = error;
                break;
              case 401:
                this.store.dispatch(new GetCurrentSession());
                this.navigateToBase(error);
                errorMessage = 'Session lost, please reconnect.';
                break;
              case 403:
                errorMessage = 'You are not authorized to access this part of the application.';
                break;
              case 404:
                this.navigateToBase(error);
                errorMessage = 'This page doesn\'t exist';
                break;
              case 405:
                errorMessage = error;
                break;
              case 409:
                errorMessage = error.message;
                break;
              case 500:
                break;
              default:
            }

            if (errorMessage === '') {
              if (error) {
                if (error.exceptionMessage === undefined) {
                  errorMessage = 'Unhandled error';
                } else {
                  errorMessage = error.exceptionMessage;
                }
              } else {
                errorMessage = err.message;
              }
            }

            this.toastr.error(this.translationService.translate(errorMessage), null);
            return throwError(error || 'Unhandled error');
          }
        })
    );
  }

  private navigateToBase(message: string): void {
    this.router.navigate([''], { queryParams: { message: message } } as NavigationExtras);
  }
}

