import { Component, Input, OnInit } from '@angular/core';
import { ResultTestQuestionViewModel } from '@shared/store/audit/audit.model';

@Component({
  selector: 'app-theoretical-evaluation-detail',
  templateUrl: './theoretical-evaluation-detail.component.html',
  styleUrls: ['./theoretical-evaluation-detail.component.scss']
})
export class TheoreticalEvaluationDetailComponent {

  @Input() public theoreticalEvaluationService: ResultTestQuestionViewModel;

}
