<div *ngIf="title$ | async as title">
  <div class="row titleContainer">
    <div class="col-md-9">
      <div class="titleFlexContainer">
        <img *ngIf="!userIsAdmin" class="logo" src="/assets/images/logo-small.png">
        <h1 [translate]="title"></h1>
      </div>
    </div>
    <div class="action-btn-container col-md-3">
      <div class="btnGoBackContainer">
        <div *ngIf="canGoBack$ | async as canGoBack">
          <app-back-button *ngIf="canGoBack===true"></app-back-button>
        </div>
        <div>
          <button class="btn btn-outline-light printBtn" *ngIf="this.printingAllowed" (click)="print()">
            <i class="fa fa-print"></i>
            <ng-container i18n="@@Print">Print</ng-container>
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-outline-light printBtnFixed no-printed-btn" *ngIf="this.printingAllowed" (click)="print()">
    <i class="fa fa-print"></i>
    <ng-container i18n="@@Print">Print</ng-container>
  </button>
</div>
