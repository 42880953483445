import { Injectable } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree
} from '@angular/router';
import { AuthenticationService } from '@shared/services/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AreTermsAcceptedGuard implements CanActivate, CanActivateChild {

  private areTermsAcceptedOrRedirect$ = this.authenticationService.areTermsAccepted().pipe(
    map(accepted => {
      if (accepted) {
        return true;
      }
      return this.router.parseUrl('/terms');
    }));

  constructor(private authenticationService: AuthenticationService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.areTermsAcceptedOrRedirect$;
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.areTermsAcceptedOrRedirect$;
  }
}
