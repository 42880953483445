import { Company } from '@shared/store/company/company.model';
import { Language } from '@shared/store/language/language-view-model';
import { DetailedWorker, Worker } from '@shared/store/worker/worker.model';
import { Media } from 'src/app/admin/media/models/media';

export class LoginModel {
  public email: string;
  public password: string;
}

export class RegisterModel {
  public workerId?: string;
  public userName: string;
  public email: string;
  public password: string;
  public confirmPassword: string;
  public isAdmin: boolean;
  public isHierarchyPartialAdministrator: boolean;
  public worker: DetailedWorker;
}

export class ProfileModel {
  public workerId: string;
  public image: Media;
  public firstName: string;
  public lastName: string;
  public displayName: string;
  public birthDate: Date;
  public companyName: string;
  public language: Language;
  public userName: string;
  public email: string;
  public oldPassword: string;
  public newPassword: string;
  public confirmPassword: string;
}
