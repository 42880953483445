<div class="card">
  <div class="card-header">
    <div class="card-title">
      <h2 i18n=@@AddAuditRight>Add audit right</h2>
    </div>
  </div>
  <div class="card-body">
    <form [formGroup]="auditForm">
      <div class="form-group">
        <label for="cubes" i18n="@@Cubes">Cubes</label>
        <ng-select id="cubes" formControlName="cube" class="simple-select" name="cubes" [compareWith]="compareCubes"
            [items]="cubes" bindLabel="name" [multiple]="false" [clearable]="false" [searchable]="false">
        </ng-select>
        
        <div *ngIf="cube.invalid && (cube.dirty)" class="alert alert-danger">
          <div *ngIf="cube.hasError('required')" i18n="@@CubeRequired">Cube is required</div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="startDate" i18n="@@StartDate">Start Date</label>
            <app-date-picker id="startDate" [date]="startDate" [minDate]="minDate" [maxDate]="maxDate"
              (dateChange)="startDate = $event"></app-date-picker>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="endDate" i18n="@@EndDate">End Date</label>
            <app-date-picker id="endDate" [date]="endDate" [minDate]="minDate" [maxDate]="maxDate"
              (dateChange)="endDate = $event"></app-date-picker>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer text-center">
    <button class="button saveButton" (click)="add()" ><i class="fa fa-plus"></i><ng-container i18n="@@Add">Add</ng-container></button>
    <button class="button cancelButton" (click)="close()"><i class="fa fa-times"></i><ng-container i18n="@@Cancel">Cancel</ng-container></button>
  </div>
</div>
