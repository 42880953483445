import { Behaviour } from '@shared/store/cube/models/behaviour.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-behaviour-report',
  templateUrl: './behaviour-report.component.html',
  styleUrls: ['./behaviour-report.component.scss']
})

export class BehaviourReportComponent {
  @Input() behaviour: Behaviour;
  @Input() score: number;
}
