import { DetailedExtendedProperty } from '@models/extended-property/extended-property';

export class GetExtendedProperties {
    public static readonly type = '[ExtendedProperty] Get ExtendedProperties';
}

export class GetExtendedPropertyAndValues {
  public static readonly type = '[ExtendedProperty] Get ExtendedProperty';
  public constructor(public id: string) { }
}

export class AddExtendedProperty {
    public static readonly type = '[ExtendedProperty] Add ExtendedProperty';
    public constructor(public extendedProperty: DetailedExtendedProperty) { }
}


export class UpdateExtendedProperty {
    public static readonly type = '[ExtendedProperty] Update ExtendedProperty';
    public constructor(public extendedProperty: DetailedExtendedProperty) { }
}
