import { Company, ContactPerson } from './company.model';

export class GetCompany {
  public static readonly type = '[Company] Get Company';
  public constructor(public companyId: string) { }
}

export class GetCompanyEmployees {
  public static readonly type = '[Company] Get Company Employees';
  public constructor(public companyId: string) { }
}

export class GetAllCompanies {
  public static readonly type = '[Company] Get All Companies';
}

export class GetCompanyHistogramData {
  public static readonly type = '[Company] Get company histogram data';
  public constructor(public startDate: Date, public endDate: Date, public companyId: string) { }
}

export class GetCompanyTheoryHistogramData {
  public static readonly type = '[Company] Get company theory histogram data';
  public constructor(public companyId: string, public startDate: Date, public endDate: Date) { }
}

export class GetCompanyEnrichedCubes {
  public static readonly type = '[Company] Get company enriched cubes';
  public constructor(public companyId: string, public startDate: Date, public endDate: Date) { }
}

export class GetCompanyWorkerStatistics {
  public static readonly type = '[Company] Get Company Statistics';
  public constructor(public companyId: string) { }
}

export class GetCompanyNodes {
  public static readonly type = '[Company] Get Company Nodes';
  public constructor(public workerId: string) { }
}

export class GetExtendedProperties {
  public static readonly type = '[Company] Get Extended Properties';
  public constructor() { }
}

export class AddCompany {
  public static readonly type = '[Company] Add Company';
  public constructor(public company: Company, public file?: File) { }
}

export class UpdateCompany {
  public static readonly type = '[Company] Update Company';
  public constructor(public company: Company) { }
}

export class DeleteCompany {
  public static readonly type = '[Company] Delete Company';
  public constructor(public company: Company) { }
}

export class AddContactPerson {
  public static readonly type = '[Company] Add ContactPerson to Company';
  public constructor(public company: Company, public contactPerson: ContactPerson) { }
}

export class UpdateContactPerson {
  public static readonly type = '[Company] Update ContactPerson from Company';
  public constructor(public company: Company, public contactPerson: ContactPerson) { }
}

export class DeleteContactPerson {
  public static readonly type = '[Company] Delete ContactPerson from Company';
  public constructor(public company: Company, public contactPerson: ContactPerson) { }
}

export class AddCompanyLogo {
  public static readonly type = '[Company] Add Company Logo';
  public constructor(public companyId: string, public file: File) { }
}

export class RemoveCompanyAdministrator {
  public static readonly type = '[Company] Remove Company Administrator';
  public constructor(public companyId: string, public workerId: string) { }
}

export class GetCompanyEnrichedCubesWithTheory {
  public static readonly type = '[Company] Get company enriched cubes with theory';
  public constructor(public companyId: string, public startDate: Date, public endDate: Date) { }
}
