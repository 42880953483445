export class ExtendedPropertyValue {
  public id: string;
  public name: string;
}

export class DetailedExtendedPropertyValue extends ExtendedPropertyValue {
  public values: DetailedExtendedPropertyValue[];
}

export class FlatExtendedPropertyValue {
  public id: string;
  public name: string;
  public depth: number;
}

export function createDetailedExtendedPropertyValue(init?: Partial<DetailedExtendedPropertyValue>): DetailedExtendedPropertyValue {
  const detailedExtendedPropertyValue = {
    id: undefined,
    name: undefined,
    values: undefined
  };
  Object.assign(detailedExtendedPropertyValue, init);
  return detailedExtendedPropertyValue;
}
